import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import CategoryItem from "../../components/CatalogPage/CategoryItem";
import {useSearchParams} from "react-router-dom";
import {isNumeric} from "../../Utility/Utility";
import ApiRequest from "../../Utility/ApiRequest";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import AdsCard from "../../components/AdsCard/AdsCard";
import $ from 'jquery';

const CatalogPage = (props) => {

    const [searchParams] = useSearchParams();
    const [category, setCategory] = useState(false);
    const [notAds, setNotAds] = useState(false);
    const [notAdsSearch, setNotAdsSearch] = useState(false);
    const [title, setTitle] = useState("");
    const [getAds, setGetAds] = useState(false);
    const [allAds, setAllAds] = useState(false);
    const [viewAds, setviewAds] = useState(false);
    const [searchText, setSearchText] = useState(false)
    const [userData, setUserData] = useState(false);

    useEffect(() => {
        setCategory(props.appData['category']);
        setNotAds(false);
        setTitle("");
        setGetAds(false);
        setviewAds(false);
        setNotAdsSearch(false);
        setAllAds(false);
        const AdsId = searchParams.get("id");
        const search = (searchParams.get("search")) ? searchParams.get("search") : false;
        const user_id = (searchParams.get("user_id")) ? searchParams.get("user_id") : false;

        if (user_id && isNumeric(user_id)){
            ApiRequest("GetAlluserAds?", "user_id=" + user_id).then((data) => {
                if (data['success']) {
                    console.log(data);
                    setAllAds(data['adsUser']);
                    setviewAds(data['adsUser']);
                    setUserData({fio: data['userData']['fio'], organization: (data['OrganizationData']) ? data['OrganizationData']['name'] : ""});
                } else
                    setNotAdsSearch(true);

                setGetAds(true);
            });
        } else if(search){
            setSearchText(search);
            ApiRequest("searchAds?", "search=" + search).then((data) => {

                if (data['success']) {
                    setAllAds(data['adsSearch']);
                    setviewAds(data['adsSearch']);
                } else
                    setNotAdsSearch(true);

                setGetAds(true);
                setTitle("Результаты поиска");
            });
        } else if (isNumeric(AdsId)){
            ApiRequest("getAdsFromCategory?", "category_id=" + AdsId).then((data) => {

                if (data['success']){
                    setAllAds(data['adsCategory']);
                    setviewAds(data['adsCategory']);
                } else
                    setNotAds(true);

                setGetAds(true);

                if (data['category_info'])
                    setTitle(data['category_info']['name']);
            });
        }
        else {
            ApiRequest("getAdsFromCategory?", "category_id=false").then((data) => {
                if (data['success']) {
                    setAllAds(data['adsCategory']);
                    setviewAds(data['adsCategory']);
                } else
                    setNotAds(true);

                setGetAds(true);
            });
        }

    }, [searchParams, props.appData]);
    const filterAds = () => {
        let minPrice = $("#minPrice").val();
        let maxPrice = $("#maxPrice").val();
        let typeAds = $("#typeAds").val();

        if (minPrice === "" || !isNumeric(minPrice))
            minPrice = 0;
        else
            minPrice = parseInt(minPrice);

        if (maxPrice === "" || !isNumeric(maxPrice))
            maxPrice = 1000000000;
        else
            maxPrice = parseInt(maxPrice);

        if (typeAds === "")
            typeAds = false;

        let NewArrayAds = []

        Object.keys(allAds).map((keyName, i) => {
            const price = parseInt(allAds[keyName]['price_filter']);
            if (price >= minPrice && price <= maxPrice)
               if (typeAds) {
                   if (allAds[keyName]['typeAds'] === typeAds)
                       NewArrayAds.push(allAds[keyName]);
               } else
                   NewArrayAds.push(allAds[keyName]);
           return true;
        });

        setviewAds(NewArrayAds);
    }

    return (
        <div>
            <PageHeader title="Каталог" />
            <div className="row">
                <div className="col-md-3">
                    <h5>Категории</h5>
                    <div className="mt-3">
                        {
                            (category)
                                ?   Object.keys(category).map((keyName, i) => {
                                    return <div>
                                        <CategoryItem cat={category[keyName]} key={category[keyName]['id']} />
                                        <div className="p-3"></div>
                                    </div>
                                })
                                : false
                        }
                    </div>
                    <h5>Фильтры</h5>
                    <div className="mt-3">
                        <input id="minPrice" type="number" className="form-control" placeholder="Мин. цена" />
                        <input id="maxPrice" type="number" className="form-control mt-2" placeholder="Макс. цена" />
                        <select id="typeAds" className="form-control mt-2">
                            <option value="">Тип обьявления</option>
                            <option value="Car">Машины</option>
                            <option value="Part">Запчасти</option>
                            <option value="Service">Услуги</option>
                        </select>
                        <button className="btn btn-success mt-2 w-100" onClick={(event) => filterAds()}>Фильтровать</button>
                        <button className="btn btn-danger mt-2 w-100" onClick={(event) => setviewAds(allAds)}>Сбросить</button>
                    </div>
                </div>
                <div className="col-md-9">
                    {
                        (getAds)
                            ? <div>
                                <h4 id="TitlePag">
                                    {title}
                                </h4>
                                {
                                    (searchText)
                                        ? <div>Поиск по запросу: {searchText}</div>
                                        : false
                                }
                                {
                                    (userData)
                                        ? <div>
                                        <h5>Продавец: {userData['fio']}</h5>
                                        {
                                            (userData['organization'])
                                                ? <h6>Организация: {userData['organization']}</h6>
                                                : false
                                        }
                                        </div>
                                        : false
                                }
                                {
                                    (notAds || notAdsSearch)
                                        ? <div className="alert alert-danger text-center">
                                            <b>
                                                {
                                                    (notAdsSearch)
                                                        ? "По данному запросу обьявлений не найдено."
                                                        : false
                                                }
                                                {
                                                    (notAds)
                                                        ? "Извините, в данной категории нет обьявлений."
                                                        : false
                                                }
                                            </b>
                                        </div>
                                        : <div className="text-center">
                                            {
                                                Object.keys(viewAds).map((keyName, i) => {
                                                    return <AdsCard Ads={viewAds[keyName]} key={viewAds[keyName]['id']} />
                                                })
                                            }
                                        </div>
                                }
                            </div>
                            : <LoadingIcon />
                    }
                </div>
            </div>
        </div>
    );
};

export default CatalogPage;
