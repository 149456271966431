const FormTypeService = (props) => {

    return (
        <div>
            <div className="mt-3">
                <b>Описание</b>
                <hr />
                <div className="form-group">
                    <textarea name="description" className="form-control" rows="9" required>
                        {
                            (props.adsData)
                                ? props.adsData['description']
                                : ""
                        }
                    </textarea>
                    <div className="valid-feedback">
                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                    </div>
                    <div className="invalid-feedback">
                        Поле обязательно к заполнению
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <b>Прайс лист</b>
                <hr />
                <div className="form-group">
                    <textarea name="priceDescription" className="form-control" rows="9" required>
                        {
                            (props.adsData)
                                ? props.adsData['priceDescription']
                                : ""
                        }
                    </textarea>
                    <div className="valid-feedback">
                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                    </div>
                    <div className="invalid-feedback">
                        Поле обязательно к заполнению
                    </div>
                    <div className="custom-control custom-checkbox mr-sm-2 mt-1">
                        <input defaultChecked={(props.adsData && props.adsData['isNotFixedPrice']) ? true : false} value="1" name="isNotFixedPrice" type="checkbox" className="custom-control-input" id="isNotFixedPrice" />
                        <label className="custom-control-label" htmlFor="isNotFixedPrice"> Цена является плавающей</label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormTypeService;
