import React, {useEffect} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import {convertStringToHTML} from "../../Utility/Utility";
import $ from 'jquery';

const AboutPage = (props) => {

    useEffect(() => {
        $(".main_info").html(props.settings['about_text']);
    },[props.settings]);

    return (
        <div>
            <PageHeader title="О нас" />
            <div className="sound__content f1 main_info">

            </div>

            <div className="row">
                <div className="col text-center">
                    <a className="sound__tel f1  _bold" href={"tel:" + props.settings['number_phone_aboutpage_number']}>
                        <i className="bi bi-telephone-outbound-fill" style={{fontSize: 46,}}></i><br/>
                        {props.settings['number_phone_aboutpage']} </a>
                </div>
                <div className="col text-center">
                    <a className="sound__mail f1  _bold" href={"mailto:" + props.settings['email_organization']}>
                        <i className="bi bi-envelope-at-fill" style={{fontSize: 46,}}></i><br/>
                        {props.settings['email_organization']} </a>
                </div>
                <div className="col text-center">
                    <a className="sound__address f1  _bold" href="#map">
                        <i className="bi bi-geo-alt-fill" style={{fontSize: 46,}}></i><br/>
                        г. Белореченск ул. Майкопская&nbsp;67 </a>
                </div>
                <div className="col text-center">
                    <i className="bi bi-person-fill" style={{fontSize: 46,}}></i><br/>
                    Иванников Дмитрий Николаевич
                    <br />ИНН: 230309139901
                </div>
            </div>
            <div className="sound__map mt-4" id="map">
                <iframe src="https://yandex.ru/map-widget/v1/?lang=ru_RU&amp;scroll=true&amp;um=constructor%3A51cd01f16336c164b08784ae83d69d97deb854ccbe4edc5f3c8f6b4663303f88"
                    allowFullScreen="true" width="100%" height="500" title="Карта"></iframe>
            </div>
        </div>
    );
};

export default AboutPage;
