import React, {useEffect} from 'react';
import $ from 'jquery';

const InputCategory = ({category ,...props}) => {

    const padding = (category.podcategory) ? "ml-5" : "";
    const checked_category = (props.checked_category) ? props.checked_category : false;
    const clickOption = (event, typeForm) => {
        props.click(typeForm, event);
    }

    useEffect(() => {
        if (props.checked_category){
            const valueCategory = "/" + category.id + "/";
            if(props.checked_category.search(valueCategory) >= 0)
                $("#category_" + category.id).prop("checked", true);
        }
    }, [props.checked_category]);


    return (
        <div className={padding}>
            <div className="form-check">
                <input defaultChecked={false} parent_id={props.parent_id} id={"category_" + category.id} onChange={(event) => clickOption(event, category.type_form)} className="input_option_category form-check-input" value={category.id} type="checkbox" />
                <label className="form-check-label">
                        {category.name}
                    </label>
            </div>
            {
                (category.podcategory)
                    ?   Object.keys(category.podcategory).map((keyName, i) => {
                        return <InputCategory checked_category={checked_category} parent_id={category.id} click={props.click} category={category.podcategory[keyName]} key={category.podcategory[keyName]['id']} />
                    })
                    : false
            }
        </div>
    );
};

export default InputCategory;
