import React, {useEffect, useState} from 'react';
import ImgAds from "./ImgAds";
import InputCategory from "./InputCategory";
import $ from "jquery";
import FormTypeCar from "./FormTypeCar";
import FormTypePart from "./FormTypePart";
import FormTypeService from "./FormTypeService";
import sendForm from "../../Utility/SendForm";
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import Select from "react-select";
import GlobalParam from "../../Utility/AppGlobalParam";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';

let indexLastImage = 0;

const FormAddAds = (props) => {

    const [category, setCategory] = useState(false);
    const [carMarka, setCarMarka] = useState(false);
    const [displayImgAds, setdisplayImgAds] = useState([]);
    const [checkedFormType, setFormType] = useState(false);
    const [geolocation, setGeolocation] = useState([]);
    const [adsAvtoMarka, setAdsAvtoMarka] = useState("");
    const [addressName, setAddressName] = useState((props.dataForm['organizationData']) ? props.dataForm['organizationData']['address'] : "");
    const [defaultGeolocation, setdefaultGeolocation] = useState((props.dataForm['organizationData']) ? props.dataForm['organizationData']['geolocation'] : "null, null");
    const [typePartWheel, setTypePartWheel] = useState(false);
    const [sendNewAdsRequest, setSendNewAdsRequest] = useState(false);

    useEffect(() => {
        setCategory(props.dataForm['category']);
        setCarMarka(props.dataForm['SPCars']);
        setGeolocation(defaultGeolocation.split(","));
    }, [props.dataForm]);

    const uncheckCategoryAllChildren = (id) => {
        $("input[type='checkbox']:checked").each(function () {
            if($(this).attr("parent_id") === id){
                $(this).prop("checked", false);
                uncheckCategoryAllChildren($(this).attr("value"))
            }
        });
    }
    const addImgAds = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImage++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAds([...displayImgAds, ...arrImg]);
        event.target.value = null;
    }
    const deleteImgAds = (idImg) => {
        setdisplayImgAds(displayImgAds.filter(obj => {
            return obj['index'] !== idImg;
        }));
    }
    const rotateImage = (idImg) => {
        let newQuaternion = 0;
        displayImgAds.map((obj, i) => {
            if(obj['index'] === idImg){
                if (displayImgAds[i]['file']['rotate'] < 270){
                    displayImgAds[i]['file']['rotate'] = displayImgAds[i]['file']['rotate'] + 90;
                    newQuaternion = displayImgAds[i]['file']['rotate'];
                } else {
                    displayImgAds[i]['file']['rotate'] = 0;
                    newQuaternion = displayImgAds[i]['file']['rotate'];
                }
            }
        });

        $("#ads_image_id_" + idImg).css({
            "-webkit-transform": "rotate(" + newQuaternion + "deg)",
            "-moz-transform": "rotate(" + newQuaternion + "deg)",
            "transform": "rotate(" + newQuaternion + "deg)" /* For modern browsers(CSS3)  */
        });

        setdisplayImgAds(displayImgAds);
    }
    const setFormAddAdsTrue = (type, event) => {
        if (event.target.checked){
            if (type !== checkedFormType) {
                $("input:checked").prop('checked', false);
                $("#" + event.target.id).prop('checked', true);
                $("#FormAddNewAds .alert-danger").empty();
                $("#FormAddNewAds .alert-danger").addClass("d-none");
            }
            $("#inputTypeAds").prop("value", type);
            setFormType(type);

            if (event.target.getAttribute("parent_id")){
                let last_parent_id = event.target.getAttribute("parent_id");
                for (let i = 0; i < 10; i++){
                    $("#category_" + last_parent_id).prop("checked", true);
                    if ($("#category_" + last_parent_id).attr("parent_id")){
                        const parent_parent_id = $("#category_" + last_parent_id).attr("parent_id");
                        if(parent_parent_id)
                            last_parent_id = parent_parent_id;
                    }
                }
            }

        } else {

            if (event.target.getAttribute("value")){
                let last_parent_id = event.target.getAttribute("value");
                uncheckCategoryAllChildren(last_parent_id);
            }

            if (event.target.getAttribute("parent_id")){
                let last_parent_id = event.target.getAttribute("parent_id");
                for (let i = 0; i < 10; i++){
                    let countCheckedCatParent = $("input[type='checkbox'][parent_id='" + last_parent_id + "']:checked").length;
                    if(countCheckedCatParent <= 0){
                        $("#category_" + last_parent_id).prop("checked", false);
                        if ($("#category_" + last_parent_id).attr("parent_id")){
                            const parent_parent_id = $("#category_" + last_parent_id).attr("parent_id");
                            if(parent_parent_id)
                                last_parent_id = parent_parent_id;
                        }
                    }
                }
            }

            if ($('input:checkbox:checked').length <= 6)
                setFormType(false);
        }

        let categoryValue = "";
        $("input[type='checkbox']:checked").each(function () {
            if ($(this).hasClass("input_option_category"))
                categoryValue += "/" + $(this).val() + "/";
        });

        $("#categoryInput").attr("value", categoryValue);

        if (categoryValue.indexOf("/9/") >= 0)
            setTypePartWheel(true);
        else
            setTypePartWheel(false);
    }

    const getGeolocation = (event) => {
        setGeolocation([event.data.geo_lat,event.data.geo_lon]);
        const coords = event.data.geo_lat + "," + event.data.geo_lon;
        setAddressName(event['value']);
    }
    const setMapCords = (cords) => {
        setGeolocation([cords[0], cords[1]]);
        const coords = cords[0] + "," + cords[1];
        setdefaultGeolocation(coords);

        var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";
        var token = "6cc4b26d810fcf2537bcd2ba90f37b926acf6558";
        var query = { lat: cords[0], lon: cords[1] };

        var options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify(query)
        }

        fetch(url, options)
            .then(response => response.json())
            .then(result => setAddressName(result['suggestions'][0]['value']))
            .catch(error => alert("error", error));
    }
    const addNewAdsResult = (data) => {
        setSendNewAdsRequest(false)

        if(data['ads_id'])
            document.location.href = GlobalParam.urlViewAds + data['ads_id'];
    }
    const notAddNewAdsResult = () => {
        setSendNewAdsRequest(false);
    }
    const SendFormAddAds = (event) => {

        let files = [];
        for (let i = 0; i < displayImgAds.length; i++)
            files.push(displayImgAds[i]['file']);

        setSendNewAdsRequest(true);

        sendForm(event, addNewAdsResult, true, false, true, files, notAddNewAdsResult);

    }

    return (
        <form action="addNewAds" id="FormAddNewAds" method="POST" className="needs-validation" noValidate onSubmit={(event) => SendFormAddAds(event)}>
            <div className="row step_container pt-5">
                <div className="col-md-3">
                    Шаг 1. Выберите категории
                    <input name="categories" type="text" className="form-control d-none" id="categoryInput" required />
                    <div className="valid-feedback">
                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                    </div>
                    <div className="invalid-feedback">
                        Поле обязательно к заполнению
                    </div>
                </div>
                <input name="typeAds" type="text" className="form-control d-none" id="inputTypeAds" />
                <div className="col-md-9" style={styles.div_category}>
                    {
                        (category)
                        ?   Object.keys(category).map((keyName, i) => {
                                return <InputCategory click={setFormAddAdsTrue} category={category[keyName]} key={category[keyName]['id']} />
                            })
                            : false
                    }
                </div>
            </div>
            {
                (checkedFormType !== "Car")
                    ? <div className="row step_container pt-3">
                        <div className="col-md-3">
                            Шаг 2. Введите название обьявления
                        </div>
                        <div className="col-md-9">
                            <input name="name" type="text" className="form-control" placeholder="Введите название" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                    : <div>
                        <div className="row step_container pt-3">
                            <div className="col-md-3">
                                Шаг 2. Выберите марку и модель
                            </div>
                            <div className="col-md-9 row">
                                <div className="col-md-6">
                                    <Select
                                        options={carMarka}
                                        placeholder="Марка автомобиля"
                                        isSearchable={true}
                                        onChange={(event) => setAdsAvtoMarka(event.value)}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <input name="avtoModel" placeholder="Введите модель автомобиля" type="text" className="form-control" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            }
            <div className="row step_container pt-5">
                <input name={"avtoMarka"} type={"text"} value={adsAvtoMarka} className={"d-none"} />
                <div className="col-md-3">
                    Шаг 3. Введите номер телефона
                </div>
                <div className="col-md-9">
                    <input name="phone" type="text" defaultValue={props.dataForm['userData']['phone']} className="form-control" placeholder="Введите номер телефона" required />
                    <div className="valid-feedback">
                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                    </div>
                    <div className="invalid-feedback">
                        Поле обязательно к заполнению
                    </div>
                </div>
            </div>
            <div className="row step_container pt-5">
                <div className="col-md-3">
                    Шаг 4. Укажите местоположение
                </div>
                <div className="col-md-9">
                    <ul class="nav nav-tabs mb-3" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-0" aria-selected="true"> Ввести адрес </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="simple-tab-2" data-bs-toggle="tab" href="#simple-tabpanel-2" role="tab" aria-controls="simple-tabpanel-1" aria-selected="false"> Указать адрес на карте </a>
                        </li>
                    </ul>
                    <div class="tab-content" id="tab-content">
                        <div class="tab-pane active" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">
                            <div className="mt-1">
                                <small>Начните вводить адрес</small>
                                <AddressSuggestions token="6cc4b26d810fcf2537bcd2ba90f37b926acf6558" defaultQuery={addressName} onChange={(event) => getGeolocation(event)} />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="simple-tabpanel-2" role="tabpanel" aria-labelledby="simple-tab-2">
                            <div className="mt-1">
                                <small>Укажите адрес на карте</small>
                                <YMaps>
                                    <Map width="100%"
                                         defaultState={{ center: [geolocation[0], geolocation[1]], zoom: 20 }}
                                         onClick={(event) => setMapCords(event.get("coords"))}>
                                        <Placemark geometry={[geolocation[0], geolocation[1]]} />
                                    </Map>
                                </YMaps>
                            </div>
                        </div>
                    </div>
                    <input name="geocontrol" value={defaultGeolocation} type="text" className="form-control mt-1 d-none" id="geocontrolInput" />
                    <input name="geolocationValue" value={addressName} type="text" className="form-control mt-1 d-none" id="geolocationValue" />
                </div>
            </div>
            <div className="row step_container pt-5">
                <div className="col-md-3">
                    Шаг 5. Детальная информация
                </div>
                <div className="col-md-9">
                    <div className="">
                        <b>Изображения</b>
                        <hr />
                        <div>
                            <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                                {
                                    (displayImgAds.length)
                                    ? displayImgAds.map((image, key) => {
                                            return (
                                                <ImgAds rotate_img={true} rotateImage={rotateImage} src={image['url']} id_img={image.index} deleteImage={deleteImgAds} key={key} />
                                            )
                                        })
                                        : false
                                }
                            </div>
                            <label className="btn btn-default d-inline">
                                <div style={styles.imagebutton}>
                                    <i className="bi bi-camera"></i>
                                </div>
                                <input type="file" multiple="multiple" onChange={(event) => addImgAds(event)} hidden />
                            </label>
                        </div>
                    </div>
                    {
                        (checkedFormType === "Car")
                        ? <FormTypeCar />
                            : false

                    }
                    {
                        (checkedFormType === "Part")
                            ? <FormTypePart typePartWheel={typePartWheel} car_marka={carMarka} setAdsMarka={setAdsAvtoMarka}/>
                            : false
                    }
                    {
                        (checkedFormType === "Service")
                            ? <FormTypeService />
                            : false
                    }
                </div>
            </div>
            {
                (checkedFormType)
                ? <div className="row step_container pt-3">
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-9">
                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-3 d-none">

                            </div>
                            {
                                !sendNewAdsRequest
                                    ? <button id="addNewAdsFormBtnSubmit" className="btn btn-success w-100 mt-3" type="submit">
                                        <i className="bi bi-patch-plus-fill"></i> Разместить обьявление
                                    </button>
                                    : <LoadingIcon />
                            }
                        </div>
                    </div>
                    : false
            }

        </form>
    );
};

const styles = {
    div_category: {
        maxHeight: 400,
        overflow: "hidden",
        overflowY: "scroll",
        backgroundColor: "#F5FDF3FF",
        padding: 3
    },
    imagebutton: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 20,
        paddingBottom: 20,
        fontSize: 40,
        color: "#abd6fc",
        borderColor: "#abd6fc",
        borderStyle: "solid",
        borderWidth: 1,
    }
}

export default FormAddAds;
