import React from 'react';
import PopularAdsCard from "../AdsCard/PopularAdsCard";

const PopularAdsTable = (props) => {
    return (
        <div className="card mt-3">
            <div className="card-header bg-success text-white">
                Популярные обьявления
            </div>
            <ul className="list-group list-group-flush">
                {
                    (props.ads)
                        ? Object.keys(props.ads).map((keyName, i) => {
                            return <PopularAdsCard Ads={props.ads[keyName]} key={props.ads[keyName]['id']} />
                        })
                        : <li className="list-group-item">Популярных обьявлений нет.</li>
                }
            </ul>
        </div>
    );
};

export default PopularAdsTable;
