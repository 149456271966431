import React from 'react';

const OrganizationTablePersonal = (props) => {
    return (
        <table className="table table-striped mt-3">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">ФИО</th>
                <th scope="col">Логин</th>
                <th scope="col">Адрес электронной почты</th>
                <th scope="col">Номер телефона</th>
                <th scope="col">Статус</th>
                <th scope="col">Действия</th>
            </tr>
            </thead>
            <tbody>
                {
                    Object.keys(props.personal).map((keyName, i) => {
                        return (
                            <tr key={keyName}>
                                <th scope="row">{i+1}</th>
                                <td><b>{props.personal[keyName]['fio']}</b></td>
                                <td><b>{props.personal[keyName]['login']}</b></td>
                                <td>{props.personal[keyName]['mail']}</td>
                                <td>{props.personal[keyName]['phone']}</td>
                                <td>
                                    {
                                        (props.admin_id === props.personal[keyName]['id'])
                                        ? <b>Администратор</b>
                                            : "Сотрудник"
                                    }
                                </td>
                                <td>
                                    {
                                        (props.admin_id !== props.personal[keyName]['id'])
                                            ? <div>
                                                <button className="dropdown-toggle btn btn-primary" role="button" data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                    <span className="p-1">Действия</span>
                                                </button>

                                                <ul className="dropdown-menu p-2">
                                                    <li>
                                                        <button onClick={(event) => props.actionEditProfile(props.personal[keyName]['id'], keyName)} className="btn btn-sm btn-primary ml-1 mt-1"><i className="bi bi-pencil-fill"></i> Редактировать данные</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={(event) => props.actionChangePassword(props.personal[keyName]['id'], keyName)} className="btn btn-sm btn-warning ml-1 mt-1"><i className="bi bi-alipay"></i> Сменить пароль</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={(event) => props.actionDeletePersonal(props.personal[keyName]['id'], keyName)} className="btn btn-sm btn-danger ml-1 mt-1"><i className="bi bi-person-x-fill"></i> Удалить</button>
                                                    </li>
                                                </ul>
                                            </div>
                                            : "Действий нет."
                                    }
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    );
};

export default OrganizationTablePersonal;
