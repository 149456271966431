import React from 'react';
import {slicetext} from "../../Utility/Utility";
import GlobalParam from "../../Utility/AppGlobalParam";
import {Link} from "react-router-dom";

const PopularAdsCard = (props) => {

    let img_name = "";
    if (props.Ads['images'].length)
        img_name = props.Ads['images'].split(".");

    let name = ""

    if (props.Ads['typeAds'] === "Car")
        name = props.Ads['avtoMarka']['name'] + " " + props.Ads['avtoModel'];
    else
        name = props.Ads['name'];

    if (name.length > 15)
        name = slicetext(name, 15);

    return (
        <Link to={GlobalParam.urlViewAds + props.Ads['id']} className="link-underline link-underline-opacity-0">
            <li className="list-group-item">
                <div className="row">
                    <div className="col-md-4">
                        {
                            (props.Ads['images'].length)
                                ? <img src={GlobalParam.AdsImagePatch + props.Ads['id'] + "/" + img_name[0] + "_100x100." + img_name[1]} width="80" alt="" />
                                : <img alt="" className="card-img-top" src={GlobalParam.notImgAdsImage} style={{maxHeight: 100, maxWidth: 100}}/>
                        }
                    </div>
                    <div className="col-md-8">
                        <b>
                            {name}
                        </b>
                        <br/><b className="text-success">{props.Ads['price']} руб.</b>
                        <br /><i className="bi bi-eye-fill"> </i> {props.Ads['views']}
                    </div>
                </div>
            </li>
        </Link>
    );
};

export default PopularAdsCard;
