import PageHeader from "../../components/PageHeader/PageHeader";
import SendForm from "../../Utility/SendForm";

const ForgotPasswodPage = () => {

    return (
        <div>
            <PageHeader title="Забыли пароль" />

            <form action="forgotPassword" method="GET" id="FormForgotPassword" noValidate onSubmit={(event) => SendForm(event,()=>{}, true, true)}>
                <div className="form-group">
                    <input name="mail" type="text" className="form-control" id="exampleInputEmail1"
                           aria-describedby="emailHelp" placeholder="Адрес электронной почты" required />
                    <div className="invalid-feedback">
                        Поле обязательно к заполнению
                    </div>
                </div>
                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <button type="submit" className="btn btn-primary w-100 mt-3">
                    <i className="bi bi-person-exclamation"></i> Восстановить пароль
                </button>
            </form>
        </div>
    );
};

export default ForgotPasswodPage;
