import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import TemplateAdminPanel from "../../components/AdminPanel/TemplatePage/TemplateAdminPanel";
import SendForm from "../../Utility/SendForm";
import {toast} from "react-toastify";
import apiRequest from "../../Utility/ApiRequest";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import TablePromoItem from "../../components/AdminPanel/PromoPage/TablePromoItem";

const AdminPromoPage = (props) => {

    const [promocode, setPromocode] = useState(false);

    useEffect(() => {
        apiRequest("getPromocode?", "").then((data) => {
           if(data['success']) {
               setPromocode(data['promocode']);
           }
        });
    }, [[]]);

    const deletePromocode = (promo_id) => {
        apiRequest("deletePromo?" , "promo_id=" + promo_id).then((data) => {
            if(data['success']){
                toast.success(data['success']);
                setPromocode(data['promocode']);
            } else if(data['error'])
                toast.error(data['error']);
        });
    }
    const editActive = (promo_id, active) => {
        apiRequest("changeActivePromo?" , "promo_id=" + promo_id + "&active=" + active).then((data) => {
            if(data['success']){
                toast.success(data['success']);
                setPromocode(data['promocode']);
            } else if(data['error'])
                toast.error(data['error']);
        });
    }

    return (
        <div>
            <PageHeader title="Административная панель" />
            <TemplateAdminPanel appData={props.appData} id_activ={5}>
                <PageHeader title="Промокоды" noHr={true} />
                <div className="mt-3">
                    <h6>Добавить промокод</h6>
                    <hr />
                    <form action="addNewPromocode" id="addNewPromocodeForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, (data) => {
                        if(data['success']){
                            toast.success(data['success']);
                            setPromocode(data['promocode']);
                        } else if (data['error'])
                            toast.error(data['error']);
                    }, false, true)}>
                        <div>
                            <label htmlFor="validationCustom01" className="form-label">Название</label>
                            <input name="name" id="validationCustom01" type="text" className="form-control" placeholder="Введите название" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3">
                                <label htmlFor="validationCustom02" className="form-label">Скидка  в процентах</label>
                                <input name="discount" id="validationCustom02" type="text" className="form-control" placeholder="Введите проценты" />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustom06" className="form-label">Скидка в рублях</label>
                                <input name="discount_money" id="validationCustom06" type="text" className="form-control" placeholder="Введите сумму" />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustom03" className="form-label">Макс. кол. приминений</label>
                                <input name="count" id="validationCustom03" type="text" className="form-control" placeholder="Введите максимальное количество приминений" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label htmlFor="validationCustom04" className="form-label">Дата окончания действия</label>
                                <input name="date_end" id="validationCustom04" type="date" className="form-control" placeholder="Введите дату окончания действия" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="mt-2">
                                <select name="tarif" id="TypeTarifUserSelect" className="form-select mt-3">
                                    <option>Тарифный план</option>
                                    <option value="Start_50">Старт 50 просмотров</option>
                                    <option value="Start_80">Старт 80 просмотров</option>
                                    <option value="Start_130">Старт 130 просмотров</option>
                                    <option value="Start_170">Старт 170 просмотров</option>
                                    <option value="Start_210">Старт 210 просмотров</option>
                                    <option value="Start_250">Старт 250 просмотров</option>
                                    <option value="Pro_1d">Бизнес 1 день</option>
                                    <option value="Pro_7d">Бизнес 7 дней</option>
                                    <option value="Pro_14d">Бизнес 14 дней</option>
                                    <option value="Pro_1m">Бизнес 1 месяц</option>
                                    <option value="Pro_3m">Бизнес 3 месяца</option>
                                    <option value="Pro_6m">Бизнес 6 месяцев</option>
                                    <option value="Pro_9m">Бизнес 9 месяцев</option>
                                    <option value="Pro_12m">Бизнес 12 месяцев</option>
                                </select>
                            </div>
                            <div className="row ml-2 mt-2">
                                <div className="col-md-12">
                                    <input name="akcia" id="akcia" className="form-check-input" type="checkbox" />
                                    <label className="form-check-label" htmlFor="">Этот промокод является акцией</label>
                                </div>
                            </div>
                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-2 d-none">

                            </div>
                            <button className="btn btn-success mt-2" type="submit">Добавить</button>
                        </div>
                    </form>
                    {
                        (promocode)
                            ?<div className="mt-3">
                                <h6>Промокоды</h6>
                                <table className="table table-striped mt-3">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Название</th>
                                        <th scope="col">Скидка</th>
                                        <th scope="col">Макс. кол. приминений</th>
                                        <th scope="col">Кол. применений</th>
                                        <th scope="col">Окончание действия</th>
                                        <th scope="col">Тариф</th>
                                        <th scope="col">Статус</th>
                                        <th scope="col">Действия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        Object.keys(promocode).map((keyName, i) => {
                                            return <TablePromoItem editActive={editActive} deletePromocode={deletePromocode} index={i} key={keyName} promo={promocode[keyName]} />
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            : <LoadingIcon />
                    }
                </div>
            </TemplateAdminPanel>
        </div>
    );
};

export default AdminPromoPage;
