import React, {useState} from 'react';
import {Link} from "react-router-dom";
import PolicyAuth from "../../Utility/Policy";
import SendForm from "../../Utility/SendForm";
import $ from 'jquery';

const AgregatorPage = (props) => {

    const [showAutorize, setShowAutorize] = useState(false);

    const goPremiumSearch = () => {
        if (PolicyAuth())
            window.location.href = "/account/premiumSearch";
        else
            $('#btn_enter').trigger('click');
    }

    return (
        <div>
            <div>
                <h3>Возможности сервиса</h3>
                <div className="row mt-3">
                    <div className="col-md-3 bg-success text-center text-white p-5 mx-auto">
                        <b>Самые свежие объявления с главных автомобильных порталов России в одной ленте.</b>
                    </div>
                    <div className="col-md-3 bg-primary text-center text-white p-5 mx-auto">
                        <b>Время поиска объявления - несколько секунд. Первые просмотры всегда ваши.</b>
                    </div>
                    <div className="col-md-3 bg-danger text-center text-white p-5 mx-auto">
                        <b>Вы узнаете, когда продавец снизил цену.</b>
                    </div>
                    <div className="col-md-3 bg-info text-center text-white p-5 mx-auto">
                        <b>100% опубликованых объявлений сохраняются в базе данных сервиса.</b>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <div className="row">
                    <div className="col-md-3">
                        <h3>Тарифные планы</h3>
                    </div>
                    <div className="col-md-9">
                        <Link onClick={(event) => goPremiumSearch()} className="btn btn-success text-white w-100 text-center">
                            <b>ПЕРЕЙТИ К ПРЕМИУМ ПОИСКУ</b>
                        </Link>
                    </div>
                </div>
                <br />
                {
                    (!PolicyAuth())
                        ?<div className="alert alert-info mt-3">
                            <i className="bi bi-exclamation-circle-fill"> </i><b>Внимание: Для оформления подписок вам нужно <Link to="/registration">зарегистрироваться</Link>.</b>
                        </div>
                    : false
                }

                {
                    (PolicyAuth())
                        ?<div className="alert alert-info text-center">
                            <b>Внимание: После оплаты тарифного плана, доступ к нему открывается в течении 15 минут.</b>
                        </div>
                        : false
                }
                <div className="row row-cols-1 row-cols-md-3 mb-3 mt-3">
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Бесплатный</h4>
                            </div>
                            <div className="card-body">
                                <ul className="list-group mt-3 mb-4">
                                    <li className="list-group-item active">Параметры тарифа</li>
                                    <li className="list-group-item text-danger"><i
                                        className="bi bi-bookmark-x-fill"> </i>Время задержки обьявлений 3 часа</li>
                                    <li  className="list-group-item text-danger"><i
                                        className="bi bi-bookmark-x-fill"> </i>Количество переходов в сутки - 15</li>
                                    <li  className="list-group-item text-danger"><i
                                        className="bi bi-bookmark-x-fill"> </i>Количество сохраненных поисковый фильтров - 4</li>
                                    <li className="list-group-item text-danger"><i
                                        className="bi bi-bookmark-x-fill"> </i>Уведомление в телеграмм о новых объявлениях</li>
                                </ul>
                                {
                                    props.appData['userData']
                                        ? props.appData['userData']['agregator_tarif_type'] === "Free"
                                            ? <b>Колличество оставшихся просмотров на сегодня: {props.appData['userData']['count_last_access_view']}</b>
                                            : false
                                        : false
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-primary">
                            <div className="card-header py-3 text-white bg-primary border-primary">
                                <h4 className="my-0 fw-normal">Старт</h4>
                            </div>
                            <div className="card-body">
                                <ul className="list-group mt-3 mb-4">
                                    <li className="list-group-item active">Параметры тарифа</li>
                                    <li className="list-group-item text-success"><i
                                        className="bi bi-bookmark-check-fill"> </i>Нет задержки по времяни</li>
                                    <li className="list-group-item text-danger"><i
                                        className="bi bi-bookmark-x-fill"> </i>Ограничения по переходам в месяц (в зависимости от оформленного пакета)</li>
                                    <li className="list-group-item text-success"><i
                                        className="bi bi-bookmark-check-fill"> </i>Нет ограничений по сохраненным поисковым фильтрам</li>
                                    <li className="list-group-item text-success"><i
                                        className="bi bi-bookmark-check-fill"> </i>Уведомление в телеграмм о новых объявлениях</li>
                                </ul>
                                {
                                    props.appData['userData']
                                        ? (PolicyAuth() && props.appData['userData']['agregator_tarif_type'] !== "Start")
                                            ? false
                                            : <div>
                                                <b>Осталось дней по подписке: {props.appData['userData']['count_day_tarif_end']}</b>
                                                <br /><b>Осталось просмотров по подписке: {props.appData['userData']['count_last_access_view']}</b>
                                            </div>
                                        : false
                                }
                                {
                                    (PolicyAuth())
                                        ? <form action="byServise" id="PaymentTarifStartForm" method="GET"
                                                className="needs-validation mt-3" noValidate
                                                onSubmit={(event) => SendForm(event, (data) => {
                                                    if (data['success']) {
                                                        window.location = data['payment_url'];
                                                    }
                                                }, true)}>
                                            <div>
                                                <label className="form-label">Колличество просмотров в месяц</label>
                                                <select name="tarif_plan" className="form-select" required>
                                                    <option value="Start_50">50 просмотров - 500 руб.</option>
                                                    <option value="Start_80">80 просмотров - 750 руб.</option>
                                                    <option value="Start_130">130 просмотров - 1000 руб.</option>
                                                    <option value="Start_170">170 просмотров - 1250 руб.</option>
                                                    <option value="Start_210">210 просмотров - 1500 руб.</option>
                                                    <option value="Start_250">250 просмотров - 1700 руб.</option>
                                                </select>
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <label className="form-label">Промокод</label>
                                                <input name="promocode" placeholder="Введите промокод" type="text"
                                                       className="form-control"/>
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                            <div className="alert alert-danger d-none mt-3">

                                            </div>
                                            <div className="alert alert-success mt-2 d-none">

                                            </div>
                                            <input value="Оплатить" type="submit"
                                                   className="w-100 btn btn-lg btn-primary mt-3"/>
                                        </form>
                                        : <ul className="list-group">
                                            <li className="list-group-item active">Прайс</li>
                                            <li className="list-group-item">50 просмотров - 500 руб.</li>
                                            <li className="list-group-item">80 просмотров - 750 руб.</li>
                                            <li className="list-group-item">130 просмотров - 1000 руб.</li>
                                            <li className="list-group-item">170 просмотров - 1250 руб.</li>
                                            <li className="list-group-item">210 просмотров - 1500 руб.</li>
                                            <li className="list-group-item">250 просмотров - 1700 руб.</li>
                                        </ul>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-primary">
                            <div className="card-header py-3 text-white bg-success border-primary">
                                <h4 className="my-0 fw-normal">Бизнес</h4>
                            </div>
                            <div className="card-body">
                                <ul className="list-group mt-3 mb-4">
                                    <li className="list-group-item active">Параметры тарифа</li>
                                    <li className="list-group-item text-success"><i
                                        className="bi bi-bookmark-check-fill"> </i>Нет задержки по времяни</li>
                                    <li className="list-group-item text-success"><i
                                        className="bi bi-bookmark-check-fill"> </i>Нет ограничений по переходам</li>
                                    <li className="list-group-item text-success"><i
                                        className="bi bi-bookmark-check-fill"> </i>Нет ограничений по сохраненным поисковым фильтрам</li>
                                    <li className="list-group-item text-success"><i
                                        className="bi bi-bookmark-check-fill"> </i>Уведомление в телеграмм о новых объявлениях</li>
                                </ul>
                                {
                                    props.appData['userData']
                                        ? (PolicyAuth() && props.appData['userData']['agregator_tarif_type'] !== "Pro")
                                            ? false
                                            : <b>Осталось дней по подписке: {props.appData['userData']['count_day_tarif_end']}</b>
                                        : false
                                }
                                {
                                    (PolicyAuth())
                                        ? <form action="byServise" id="PaymentTarifProForm" method="GET"
                                                className="needs-validation mt-3" noValidate
                                                onSubmit={(event) => SendForm(event, (data) => {
                                                    if (data['success']) {
                                                        window.location = data['payment_url'];
                                                    }
                                                }, true)}>
                                            <div>
                                                <label className="form-label">Срок оформления тарифа</label>
                                                <select name="tarif_plan" className="form-select" required>
                                                    <option value="Pro_1d">1 день - 350 руб.</option>
                                                    <option value="Pro_7d">7 дней - 1200 руб.</option>
                                                    <option value="Pro_14d">14 дней - 1800 руб.</option>
                                                    <option value="Pro_1m">1 месяц - 2450 руб.</option>
                                                    <option value="Pro_3m">3 месяца - 6600 руб.</option>
                                                    <option value="Pro_6m">6 месяцев - 14500 руб. + 1 мес. в подарок
                                                    </option>
                                                    <option value="Pro_9m">9 месяцев - 19000 руб.</option>
                                                    <option value="Pro_12m">12 месяцев - 25000 руб. + 2 мес. в подарок
                                                    </option>
                                                </select>
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <label className="form-label">Промокод</label>
                                                <input name="promocode" placeholder="Введите промокод" type="text"
                                                       className="form-control"/>
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                            <div className="alert alert-danger d-none mt-3">

                                            </div>
                                            <div className="alert alert-success mt-2 d-none">

                                            </div>
                                            <input value="Оплатить" type="submit"
                                                   className="w-100 btn btn-lg btn-success mt-3"/>
                                        </form>
                                        : <ul className="list-group">
                                            <li className="list-group-item active">Прайс</li>
                                            <li className="list-group-item">1 день - 350 руб.</li>
                                            <li className="list-group-item">7 дней - 1200 руб.</li>
                                            <li className="list-group-item">14 дней - 1800 руб.</li>
                                            <li className="list-group-item">1 месяц - 2450 руб.</li>
                                            <li className="list-group-item">3 месяца - 6600 руб.</li>
                                            <li className="list-group-item">6 месяцев - 14500 руб. + 1 мес. в подарок</li>
                                            <li className="list-group-item">9 месяцев - 19000 руб.</li>
                                            <li className="list-group-item">12 месяцев - 25000 руб. + 2 мес. в подарок</li>
                                        </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4>
                Сервис собирает информацию из 50-и самых популярных площадок по продаже подержанных ТС, например: Авито, Auto.ru и др.
            </h4>
            <p>
                Сервис за несколько секунд находит все свежие предложения на самых популярных площадках и добавляет их в ленту.
                Чем быстрее вы отреагируете на объявление - тем выгоднее сможете приобрести ТС.
                Новые данные подтягиваются в ленту из других площадок в течение нескольких секунд.
                Как только продавец внесет изменения в объявление, они обновятся и в сервисе.
            </p>
        </div>
    );
};

export default AgregatorPage;
