import React, {useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import OrganizationTablePersonal from "../../components/OrganizationPage/OrganizationTablePersonal";
import Modal from "react-bootstrap/Modal";
import SendForm from "../../Utility/SendForm";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import ApiRequest from "../../Utility/ApiRequest";
import wordByIndex from "../../Utility/Utility";
import PasswordInput from "../../components/PaswordInput/PasswordInput";
import {AddressSuggestions} from "react-dadata";
import $ from "jquery";

let editProfileIndex = 0;
let removeProfileIndex = 0;
let changePasswordProfileIndex = 0;

const OrganizationPage = (props) => {

    const [defaultPersonal, setDefaultPersonal] = useState(props.appData['organizationData']['user_organization']);
    const [personal, setPersonal] = useState(props.appData['organizationData']['user_organization']);
    const [addNewPersonal, setAddNewPersonal] = useState(false);
    const [editOrganizationData, setEditOrganizationData] = useState(false);
    const [editProfilePersonal, setEditProfilePersonal] = useState(false);
    const [personData, setPersonData] = useState(false);
    const [delPersonal, setDelPersonal] = useState(false);
    const [changePasswordPersonal, setChangePasswordPersonal] = useState(false);
    const [address, setAddress] = useState(props.appData['organizationData']['address']);

    const getGeolocation = (event) => {
        setAddress(event.value);
        const coords = event.data.geo_lat + "," + event.data.geo_lon;
        $("#geocontrolInput").attr("value", coords);
    }
    const SuccessAddPersonal = (data) => {
        setPersonal([...personal, data['personData']]);
        setDefaultPersonal([...defaultPersonal, data['personData']]);
        setAddNewPersonal(false);
    }
    const SetEditProfilePerson = (user_id, keyName) => {
        setEditProfilePersonal(false);
        ApiRequest("getPersonDataForEdit?", "person_id=" + user_id, "GET").then((data) => {
            setPersonData(data['personData']);
            setEditProfilePersonal(true);
            editProfileIndex = keyName;
        });
    }
    const SuccessEditPersonal = (data) => {
        personal[editProfileIndex]['phone'] = data['personData']['phone'];
        personal[editProfileIndex]['fio'] = data['personData']['fio'];
        setPersonal(personal);
        defaultPersonal[editProfileIndex]['phone'] = data['personData']['phone'];
        defaultPersonal[editProfileIndex]['fio'] = data['personData']['fio'];
        setDefaultPersonal(defaultPersonal);
        setTimeout(setEditProfilePersonal, 1000, false);
    }
    const DeletePresonal = (user_id, keyName) => {
        setDelPersonal(true);
        removeProfileIndex = keyName;
    }
    const SuccessDeleteProfilePerson = (data) => {
        delete personal[removeProfileIndex];
        setPersonal(personal);
        delete defaultPersonal[removeProfileIndex];
        setDefaultPersonal(defaultPersonal);
        setTimeout(setDelPersonal, 1000, false);
    }
    const changePasswordProfilePersonal = (user_id, keyName) => {
        setChangePasswordPersonal(true);
        changePasswordProfileIndex = keyName;
    }
    const SuccessChangePasswordProfilePersonal = (data) => {
        setTimeout(setChangePasswordPersonal, 1000, false);
    }
    const filterPerson = (fio) => {
        if (fio !== ""){
            let result = []
            defaultPersonal.map((person) => {
                const fio_ = person['fio'].toLowerCase();
                if(fio_.includes(fio.toLowerCase()))
                   result.push(person);
                return true;
            });
            setPersonal(result);
        } else
            setPersonal(defaultPersonal);
    }

    return (
        <div>
            <PageHeader title="Моя организация" />
            <b>Общая информация </b>
            <button onClick={(event) => setEditOrganizationData(true)} className="btn btn-sm btn-success">
                <i className="bi bi-pencil-fill"></i> Редактировать
        </button>
            <div className="row">
                <div className="col-md-4 text-center mt-3">
                    <b><i className="bi bi-building-fill"></i> Название организации:
                    </b><br/>
                    {
                        props.appData['organizationData']['name']
                    }
                </div>
                <div className="col-md-4 text-center mt-3">
                    <b><i className="bi bi-geo-alt-fill"></i> Адрес:
                    </b><br/>
                    {
                        props.appData['organizationData']['address']
                    }
                </div>
                <div className="col-md-4 text-center mt-3">
                    <b><i className="bi bi-telephone-inbound-fill"></i> Номер телефона:
                    </b><br/>
                    {
                        props.appData['organizationData']['phone']
                    }
                </div>
            </div>
            <div className="mt-5">
                <b>Сотрудники организации </b>
                <button onClick={(event) => setAddNewPersonal(true)} className="btn btn-sm btn-success">
                    <i className="bi bi-person-fill-add"></i> Добавить сотрудника
                </button>
                <div className="input-group mt-2">
                <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                </span>
                    <input onChange={(event) => filterPerson(event.target.value)} className="form-control" placeholder="ФИО" defaultValue="" />
                </div>

                {
                    (personal)
                    ? <OrganizationTablePersonal actionChangePassword={changePasswordProfilePersonal} actionDeletePersonal={DeletePresonal} actionEditProfile={SetEditProfilePerson} admin_id={props.appData['organizationData']['admin_id']} personal={personal} />
                        : false
                }
            </div>
            {
                (addNewPersonal)
                ? <Modal show={addNewPersonal} onHide={(event) => setAddNewPersonal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Добавление нового сотрудника</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form action="addNewPersonalOrganization" id="AddNewPersonalForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, SuccessAddPersonal, true, true)}>
                                <div>
                                    <div className="">
                                        <label htmlFor="validationCustom01" className="form-label">Логин</label>
                                        <input name="login" id="validationCustom01" type="text" className="form-control" placeholder="Введите логин" required />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="validationCustom02" className="form-label">Пароль</label>
                                        <PasswordInput name="password" type="password" id="validationCustom02" className="form-control" placeholder="Введите пароль, не мение 8 символов" required />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="validationCustom03" className="form-label">Повторите пароль</label>
                                        <PasswordInput name="repeatPassword" type="password" id="validationCustom03" className="form-control" placeholder="Повторите пароль" required />
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div className="">
                                        <label htmlFor="validationCustom04" className="form-label">Адрес электронной почты</label>
                                        <input name="mail" type="text" id="validationCustom04" className="form-control" placeholder="Введите адрес электронной почты" required />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="validationCustom05" className="form-label">Номер телефона</label>
                                        <input name="phone" type="text" id="validationCustom05" className="form-control" placeholder="Введите номер телефона: +7 918 1234567" required />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div>
                                        <label htmlFor="validationCustom06" className="form-label">Фамилия</label>
                                        <input name="lastName" type="text" id="validationCustom06" className="form-control" placeholder="Введите фамилию" required />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="validationCustom07" className="form-label">Имя</label>
                                        <input name="Name" type="text" id="validationCustom07" className="form-control" placeholder="Введите имя" required />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="validationCustom08" className="form-label">Отчество</label>
                                        <input name="middleName" type="text"id="validationCustom08" className="form-control" placeholder="Отчество" />
                                    </div>
                                </div>
                                <div className="alert alert-danger d-none mt-3">

                                </div>
                                <div className="alert alert-success mt-3 d-none">

                                </div>
                                <input type="submit" value="Добавить сотрудника" className="btn btn-success mt-3" />
                                <button onClick={(event) => setAddNewPersonal(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                            </form>
                        </Modal.Body>
                    </Modal>
                    : false
            }

            {
                (editProfilePersonal)
                ? <Modal show={editProfilePersonal} onHide={(event) => setEditProfilePersonal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Редактирование профиля сотрудника</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                (personData)
                                ? <form action="editProfilePersonal" id="editProfilePersonalForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, SuccessEditPersonal, true, false)}>
                                        <div>
                                            <div className="mt-2">
                                                <label htmlFor="validationCustom05" className="form-label">Номер телефона</label>
                                                <input name="phone" type="text" defaultValue={personData['phone']} id="validationCustom05" className="form-control" placeholder="Введите номер телефона: +7 918 1234567" required />
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                            <input type="text" name="person_id" className="d-none" defaultValue={personData['id']} />
                                            <div className="mt-2">
                                                <label htmlFor="validationCustom06" className="form-label">Фамилия</label>
                                                <input name="lastName" defaultValue={wordByIndex(personData['fio'], 1)} type="text" id="validationCustom06" className="form-control" placeholder="Введите фамилию" required />
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <label htmlFor="validationCustom07" className="form-label">Имя</label>
                                                <input name="Name" defaultValue={wordByIndex(personData['fio'], 2)} type="text" id="validationCustom07" className="form-control" placeholder="Введите имя" required />
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <label htmlFor="validationCustom08" className="form-label">Отчество</label>
                                                <input name="middleName" defaultValue={wordByIndex(personData['fio'], 3)} type="text"id="validationCustom08" className="form-control" placeholder="Отчество" />
                                            </div>
                                        </div>
                                        <div className="alert alert-danger d-none mt-3">

                                        </div>
                                        <div className="alert alert-success mt-3 d-none">

                                        </div>
                                        <input type="submit" value="Изменить сотрудника" className="btn btn-success mt-3" />
                                        <button onClick={(event) => setEditProfilePersonal(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                                    </form>
                                    : <LoadingIcon />
                            }
                        </Modal.Body>
                    </Modal>
                    : false
            }

            {
                (delPersonal)
                    ? <Modal show={delPersonal} onHide={(event) => setDelPersonal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Удаление сотрудника</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form action="deleteProfilePerson" id="delPersonalProfileForm" method="GET" onSubmit={(event) => SendForm(event, SuccessDeleteProfilePerson, true, false)}>
                                <div>
                                    Вы действительно хотите удалить <b>{personal[removeProfileIndex]['fio']}</b> из числа сотрудников ?
                                    <hr/>
                                    <div className="mt-2">
                                        <input name="person_id" type="text" defaultValue={personal[removeProfileIndex]['id']} className="form-control d-none" />
                                    </div>
                                    <div className="alert alert-danger d-none mt-3">

                                    </div>
                                    <div className="alert alert-success mt-3 d-none">

                                    </div>
                                    <input type="submit" value="Удалить" className="btn btn-danger mt-3" />
                                    <button onClick={(event) => setDelPersonal(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    : false
            }

            {
                (changePasswordPersonal)
                    ? <Modal show={changePasswordPersonal} onHide={(event) => setChangePasswordPersonal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Изменение пароля</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form action="changePasswordProfilePerson"id="AddNewPersonalForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, SuccessChangePasswordProfilePersonal, true, false)}>
                                <div>
                                    Вы изменяете пароль для сотрудника <b>{personal[changePasswordProfileIndex]['fio']}</b>
                                    <hr/>
                                    <div className="mt-2">
                                        <input name="person_id" type="text" defaultValue={personal[changePasswordProfileIndex]['id']} className="form-control d-none" />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="validationCustom02" className="form-label">Пароль</label>
                                        <PasswordInput name="password" type="password" id="validationCustom02" className="form-control" placeholder="Введите пароль, не мение 8 символов" required />
                                    </div>
                                    <div className="mt-2">
                                        <label htmlFor="validationCustom03" className="form-label">Повторите пароль</label>
                                        <PasswordInput name="repeatPassword" type="password" id="validationCustom03" className="form-control" placeholder="Повторите пароль" required />
                                    </div>
                                    <div className="alert alert-danger d-none mt-3">

                                    </div>
                                    <div className="alert alert-success mt-3 d-none">

                                    </div>
                                    <input type="submit" value="Сменить пароль" className="btn btn-warning mt-3" />
                                    <button onClick={(event) => setChangePasswordPersonal(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    : false
            }
            {
                editOrganizationData
                    ? <Modal show={editOrganizationData} onHide={(event) => setEditOrganizationData(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Редактирование данных организации</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form action="editOrganizationData" id="editOrganizationDataForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, (data) => {
                                if (data['success'])
                                    if (data['organizationData'])
                                        props.reloadOrganizationData(data['organizationData']);
                            }, true, false)}>
                                <div className="">
                                    <div className="">
                                        <label htmlFor="validationCustom06" className="form-label">Наименование организации</label>
                                        <input name="organizationName" type="text" defaultValue={props.appData['organizationData']['name']} id="validationCustom06" className="form-control" placeholder="Введите наименование организации" required />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="validationCustom07" className="form-label">Адрес организации</label>
                                        <AddressSuggestions token="6cc4b26d810fcf2537bcd2ba90f37b926acf6558" value={address} defaultQuery={address} onChange={(event) => getGeolocation(event)} />
                                        <input name="organizationAddress" type="text" id="validationCustom07" value={address} className="form-control d-none" placeholder="Введите адрес организации" required />
                                        <input name="geocontrol" type="text" className="form-control mt-1 d-none" id="geocontrolInput" value={props.appData['organizationData']['geolocation']} />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <input name="organization_id" type="text" defaultValue={props.appData['organizationData']['id']} className="d-none" />
                                    <div className="mt-3">
                                        <label htmlFor="validationCustom08" className="form-label">Номер телефона организации</label>
                                        <input name="organizationPhone" defaultValue={props.appData['organizationData']['phone']} type="text"id="validationCustom08" className="form-control" placeholder="Введите номер телефона организации: +7 918 1234567" />
                                    </div>
                                </div>
                                <div className="alert alert-danger d-none mt-3">

                                </div>
                                <div className="alert alert-success mt-3 d-none">

                                </div>
                                <hr />
                                <input type="submit" value="Сохранить" className="btn btn-success mt-3" />
                                <button onClick={(event) => setEditOrganizationData(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                            </form>
                        </Modal.Body>
                    </Modal>
                    : false
            }
        </div>
    );
};

export default OrganizationPage;
