import React, {useEffect, useState} from 'react';
import SendForm from "../../../Utility/SendForm";
import {toast} from "react-toastify";

const CategoryAdminPageItem = ({category ,...props}) => {

    const padding = (category.podcategory) ? "ml-5" : "";

    return (
        <div className={padding}>
            <div className="form-check">
                <label className="form-check-label">
                    {category.name}
                    {
                        ((props.rang + 1) <= 4)
                            ? <span>
                                <span onClick={(event) => props.showAddCategoryForm(category.id)} className="text-success ml-2" style={{cursor: "pointer"}}><i className="bi bi-plus-square"></i></span>
                                <span onClick={(event) => props.setCategoryDelete(category.id, category.name)} className="text-danger ml-2" style={{cursor: "pointer"}}><i className="bi bi-x-square"></i></span>
                            </span>
                            : false
                    }


                </label>
            </div>
            {
                ((props.rang + 1) <= 4)
                    ? <form id={"form_add_podcategory_" + category.id} action="addCategory" method="GET" className={"d-none needs-validation " + padding} noValidate onSubmit={(event) => SendForm(event, (data) => {
                        if(data['success']) {
                            toast.success(data['success'])
                            props.reloadCategory(data['category']);
                            props.setNewCategory(data['category']);
                            props.hideAddCategoryForm(event, category.id)
                        } else if (data['error'])
                            toast.error(data['error'])
                    }, true)}>
                        <div className="input-group">
                            <input name="name" type="text" className="form-control" placeholder="Введите название категории" required />
                            <input name="parent_id" type="text" className="form-control d-none" defaultValue={category.id} />
                            <div className="input-group-prepend">
                                <button type="submit" className="btn btn-sm btn-success">Добавить</button>
                                <button onClick={(event) => props.hideAddCategoryForm(event, category.id)} className="btn btn-sm btn-danger">Отмена</button>
                            </div>
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-2 d-none">

                            </div>
                        </div>
                    </form>
                    : false
            }
            {
                (category.podcategory)
                    ?   Object.keys(category.podcategory).map((keyName, i) => {
                        return <CategoryAdminPageItem setCategoryDelete={props.setCategoryDelete} setNewCategory={props.setNewCategory} reloadCategory={props.reloadCategory} hideAddCategoryForm={props.hideAddCategoryForm} showAddCategoryForm={props.showAddCategoryForm} parent_id={category.id} rang={props.rang + 1} click={props.click} category={category.podcategory[keyName]} key={category.podcategory[keyName]['id']} />
                    })
                    : false
            }
        </div>
    );
};

export default CategoryAdminPageItem;
