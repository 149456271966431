import React, {useState} from 'react';
import {Link} from "react-router-dom";
import GlobalParam from "../../Utility/AppGlobalParam";
import $ from 'jquery'

const CategoryItem = (props) => {

    const [nonePodcategory, setNonePodcategory] = useState("d-none");

    const openPodcategory = (event) => {

        $(".link_category").removeClass("text-success");
        $(".link_category").removeClass("font-weight-bold");

        $("#category_ling_" + props.cat['id']).addClass("text-success");
        $("#category_ling_" + props.cat['id']).addClass("font-weight-bold");

        if(event.target.getAttribute("openpodcat") === "false"){
            setNonePodcategory(false);
            event.target.setAttribute("openpodcat", "true");
        } else {
            setNonePodcategory("d-none");
            event.target.setAttribute("openpodcat", "false");
        }
    }

    return (
        <div className={(props.display) ? "ml-3 d-none" : "ml-3"}>

            <Link id={"category_ling_" + props.cat['id']} openpodcat="false" onClick={(event) => openPodcategory(event)} to={GlobalParam.urlCategoryAds + props.cat['id']} className="link-underline link-underline-opacity-0 link_category">
                {
                    props.cat['name']
                }
                {/*<div className="row">*/}
                {/*    <div className="col-md-9">*/}
                {/*        {*/}
                {/*            props.cat['name']*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <div className="col-md-3 text-right">*/}
                {/*        {*/}
                {/*            (props.cat['count_ads'])*/}
                {/*                ? <span className="badge badge-success ml-1">{props.cat['count_ads']}</span>*/}
                {/*                : false*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Link>
            <div style={styles.borderDiv}></div>
            {
                (Object.keys(props.cat['podcategory']).length)
                    ?   Object.keys(props.cat['podcategory']).map((keyName, i) => {
                        return <CategoryItem display={nonePodcategory} cat={props.cat['podcategory'][keyName]} key={props.cat['podcategory'][keyName]['id']} />
                    })
                    : false
            }
        </div>
    );
};

const styles = {
    borderDiv: {
        borderBottomColor: "#e0e0e0",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        paddingTop: 2,
        paddingBottom: 2,
    }
}

export default CategoryItem;
