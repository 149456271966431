import React, {useEffect, useState} from 'react';
import MessageModal from "../../components/MessageModal/MessageModal";
import {Link, useSearchParams} from "react-router-dom";
import CategoryItem from "../../components/CategoryItem/CategoryItem";
import AdsCard from "../../components/AdsCard/AdsCard";
import VikupImg from '../../images/vikup_avto.png'
import PopularAdsTable from "../../components/MainPage/PopularAdsTable";
import doska from '../../images/doska.png'

const MainPage = (props) => {
    const [searchParams] = useSearchParams();
    const [activateAccaunt, setActivateAccount] = useState(false);

    useEffect(() => {
        if (searchParams.get("setActivate") === "true") {
            setActivateAccount (true);
        }
        props.loadAppSettings();
    }, [searchParams]);

    return (
        <div>
            {(activateAccaunt) ? <MessageModal title="Подтверждение регистрации">Вы успешно подтвердили регистрацию.</MessageModal> : ""}
            {
                props.appData['settings']['UserMessage'] !== ""
                    ?<div className="bg-success text-white text-center p-2 mb-3">
                        <b>
                            {
                                props.appData['settings']['UserMessage']
                            }
                        </b>
                    </div>
                    : false
            }
            <div className="row">
                <div className="col-md-9">
                    <div style={styles.podium}>
                    {
                        (props.appData['mainCategory'])
                            ? Object.keys(props.appData['mainCategory']).map((keyName, i) => {
                                    return <CategoryItem
                                        name={props.appData['mainCategory'][keyName]['name']}
                                        icon={props.appData['mainCategory'][keyName]['icon']}
                                        image_icon={props.appData['mainCategory'][keyName]['image_icon']}
                                        id_category={props.appData['mainCategory'][keyName]['id']}
                                        key={props.appData['mainCategory'][keyName]['id']} />
                                    })
                            : false
                    }
                        {
                            (props.appData['settings']['enable_premium_search'] === 1)
                                ? <Link to="/agregator/info">
                                    <div className="d-inline-block mr-2 shadow bg-success text-white" style={styles.category_c}>
                                        <div>
                                            <div className="pr-3">
                                                <b>Премиум поиск</b>
                                            </div>
                                            <div className="text-right" style={{overflow: "hidden"}}>
                                                <img src={doska} style={styles.img} width="70" height="70" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                : false
                        }
                    </div>
                    <div className="mt-3 text-center">
                        {
                            (props.appData['mainPageAds']['adsMain'])
                                ? Object.keys(props.appData['mainPageAds']['adsMain']).map((keyName, i) => {
                                    return <AdsCard Ads={props.appData['mainPageAds']['adsMain'][keyName]} key={props.appData['mainPageAds']['adsMain'][keyName]['id']} />
                                })
                                : false
                        }
                    </div>
                    <div className="mt-5"></div>
                </div>
                <div className="col-md-3">
                    {
                        props.appData['settings']['bannerVikupShow']
                            ? <div className="card pt-2">
                                <img className="card-img-top" src={VikupImg} width="100%" alt="" />
                                <div className="card-body">
                                    <h5 className="card-title text-center">
                                        Выкупим ваш автомобиль !
                                    </h5>
                                    <p className="card-text text-center">
                                        СРОЧНЫЙ ВЫКУП АВТОМОБИЛЕЙ ПОСЛЕ ДТП
                                        , С ЮРИДИЧЕСКИМИ ПРОБЛЕМАМИ (ОГРАНИЧЕНИЯ
                                        КРЕДИТНЫЕ, ЗАЛОГОВЫЕ И ТД.), ПОСЛЕ ПОЖАРА
                                        В КРАСНОДАРСКОМ КРАЕ, РЕСПУБЛИКЕ АДЫГЕЯ,
                                        РОСТОВСКОЙ ОБЛАСТИ, CТАВРОПОЛЬСКОМ КРАЕ И ДРУГИХ РЕГИОНАХ.
                                        ОЦЕНКА ПО ФОТО ЗА 5 МИНУТ,
                                        ВЫКУП И ВЫПЛАТА ЗА ЧАС!
                                    </p>
                                    <div className="text-center">
                                        <h4>
                                            <a className="link-underline link-underline-opacity-0" href={"tel:" + props.appData['settings']['number_phone_baner_vikup_avto_number']}>
                                                <i className="bi bi-telephone-outbound-fill"> </i>
                                                позвонить
                                            </a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            : false
                    }
                    {
                        (props.appData['popularAds']['adsMainPopular'] && props.appData['settings']['popular_ads_work'])
                            ? <PopularAdsTable ads={props.appData['popularAds']['adsMainPopular']} />
                            : false
                    }
                </div>
            </div>
        </div>
    );
};

const styles = {
    podium: {
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 5,
    },
    category_c: {
        minWidth: 120,
        borderRadius: 10,
        paddingTop: 5,
        paddingLeft: 10,
        cursor: "pointer",
        borderColor: "#abd6fc",
        borderStyle: "solid",
        borderWidth: 1,
    },
    img: {
        position: "relative",
        top: 10,
        left: 10
    }
}

export default MainPage;
