import React from 'react';
import {Link} from "react-router-dom";
import GlobalParam from "../../Utility/AppGlobalParam";

const CategoryTag = (props) => {
    return (
        <div className="d-inline mt-3">
            <div style={styles.link}>
                <Link className="text-success" to={GlobalParam.urlCategoryAds + props.id}>
                    {props.title}
                </Link>
            </div>
            <div className="ml-2 d-inline"></div>
        </div>
    );
};

const styles = {
    link: {
        padding: 5,
        background: "#bde5ae",
        display: "inline"
    }
}

export default CategoryTag;
