import React from 'react';

const TablePromoItem = (props) => {
    return (
        <tr>
            <td scope="col">{props.index + 1}</td>
            <td scope="col">{props.promo['name']}</td>
            <td scope="col">
                {
                    props.promo['discount']
                        ? props.promo['discount'] + "%"
                        : props.promo['discount_money']
                            ? props.promo['discount_money'] + " р."
                            : false
                }
            </td>
            <td scope="col">{props.promo['count']}</td>
            <td scope="col">{props.promo['count_enter']}</td>
            <td scope="col">{props.promo['date_action']}</td>
            <td scope="col">{props.promo['tarif']}</td>
            <td scope="col">
            {
                (props.promo['active'] === 1)
                    ? <span className="text-success">Активен</span>
                    : <span className="text-danger">Не активен</span>
            }
            <br />
            {
                (props.promo['isAktia'] === 1)
                    ? <span className="text-success"><b>Акция</b></span>
                    : false
            }
            </td>
            <td scope="col">
                <div>

                    <button className="dropdown-toggle btn btn-primary" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                        <span className="p-1">Действия</span>
                    </button>

                    <ul className="dropdown-menu p-2">
                        <li>
                            {
                                (props.promo['active'] === 1)
                                    ? <button onClick={(event) => props.editActive(props.promo['id'], 1)} className="btn btn-sm btn-warning ml-1 mt-1"><i className="bi bi-bag-x-fill"></i> Сделать не активным</button>
                                    : <button onClick={(event) => props.editActive(props.promo['id'], 2)} className="btn btn-sm btn-primary ml-1 mt-1"><i className="bi bi-bag-x-fill"></i> Сделать активным</button>
                            }
                        </li>
                        <li>
                            <button onClick={(event) => props.deletePromocode(props.promo['id'])} className="btn btn-sm btn-danger ml-1 mt-1"><i className="bi bi-trash3-fill"></i> Удалить</button>
                        </li>
                    </ul>
                </div>
            </td>
        </tr>
    );
};

export default TablePromoItem;
