import React from 'react';
import {getDataStorage} from "../../Utility/Storage";
import {Link} from "react-router-dom";
import {exitAccount} from "../../Utility/Policy";
import wordByIndex from "../../Utility/Utility";

const NavUserMenu = (props) => {

    return (
        <div>

            <Link to="/account" className="nav-link dropdown-toggle text-white" role="button" data-bs-toggle="dropdown"
               aria-expanded="false">
                <i className="bi bi-person-circle pr-1"></i>
                <span className="p-1">{wordByIndex(getDataStorage('user_f'), 2)}</span>
            </Link>

            <ul className="dropdown-menu">
                <li><Link to="/account/Ads" className="menu_elem dropdown-item" onClick={(event) => props.closeNavigation()}><i className="bi bi-file-earmark-spreadsheet-fill"></i> Мои обьявления</Link></li>
                {
                    (props.appData['userData']['is_organization'] === 1)
                    ? <li><Link to="/account/Organization" className="menu_elem dropdown-item" onClick={(event) => props.closeNavigation()}><i className="bi bi-building-fill"></i> Моя организация</Link></li>
                        : false
                }
                <li><Link to="/account/Likes" className="menu_elem dropdown-item" onClick={(event) => props.closeNavigation()}><i className="bi bi-star-fill"></i> Избранное</Link></li>
                <li><Link to="/account/Setting" className="menu_elem dropdown-item" onClick={(event) => props.closeNavigation()}><i className="bi bi-person-circle"></i> Профиль</Link></li>
                <li>
                    <hr className="dropdown-divider" />
                </li>
                <li><button onClick={exitAccount} className="menu_elem dropdown-item"> <i className="bi bi-x-square-fill"></i> Выйти</button></li>
            </ul>
        </div>
    );
};

export default NavUserMenu;
