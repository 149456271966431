import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import TemplateAdminPanel from "../../components/AdminPanel/TemplatePage/TemplateAdminPanel";
import apiRequest from "../../Utility/ApiRequest";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import {Link} from "react-router-dom";

const AdminMainPage = (props) => {

    const [loadingStatistic, setLoadingStatistic] = useState(false);
    const [statistic, setStatistic] = useState(false);

    useEffect(() => {
        apiRequest("getAppStatistic?").then((data) => {
           console.log(data);
            setStatistic(data);
            setLoadingStatistic(true)
        });
    }, [setStatistic]);

    return (
        <div>
            <PageHeader title="Административная панель" />
            <TemplateAdminPanel appData={props.appData} id_activ={7}>
                <PageHeader title="Статистика" noHr={true} />
                {
                    loadingStatistic
                        ? <div>
                        <Link to="https://metrica.yandex.com/dashboard?group=day&period=week&id=94675498" target="_blank">
                            Яндекс метрика
                        </Link>
                            <div className="mt-3">
                                <h6>
                                    <i className="bi bi-file-earmark-spreadsheet-fill"></i> Статистика обьявлений
                                </h6>
                                <table className="table table-striped mt-3">
                                    <thead>
                                    <tr>
                                        <th scope="col">Параметр</th>
                                        <th scope="col" className="text-right">Значение</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Всего обьявлений</td>
                                        <td align="right">{statistic['allAds']}</td>
                                    </tr>
                                    <tr>
                                        <td>Обьявлений за сегодня</td>
                                        <td align="right">{statistic['adsToday']}</td>
                                    </tr>
                                    <tr>
                                        <td>Обьявлений за текущий месяц</td>
                                        <td align="right">{statistic['adsMonths']}</td>
                                    </tr>
                                    <tr>
                                        <td>Активных обьявлений всего</td>
                                        <td align="right">{statistic['adsActive']}</td>
                                    </tr>
                                    <tr>
                                        <td>Не активных обьявлений всего</td>
                                        <td align="right">{statistic['adsNotActive']}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-5">
                                <h6>
                                    <i className="bi bi-people-fill"></i> Статистика пользователей
                                </h6>
                                <table className="table table-striped mt-3">
                                    <thead>
                                    <tr>
                                        <th scope="col">Параметр</th>
                                        <th scope="col" className="text-right">Значение</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Всего пользователей</td>
                                        <td align="right">{statistic['allUser']}</td>
                                    </tr>
                                    <tr>
                                        <td>Новых пользователей за сегодня</td>
                                        <td align="right">{statistic['userToday']}</td>
                                    </tr>
                                    <tr>
                                        <td>Новых пользователей за текущий месяц</td>
                                        <td align="right">{statistic['userMonths']}</td>
                                    </tr>
                                    <tr>
                                        <td>Всего пользователей с подтвержденным аккаунтом</td>
                                        <td align="right">{statistic['userActiveAccount']}</td>
                                    </tr>
                                    <tr>
                                        <td>Всего пользователей с не подтвержденным аккаунтом</td>
                                        <td align="right">{statistic['userNotActiveAccount']}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-5">
                                <h6>
                                    <i className="bi bi-building-fill"></i> Статистика организаций
                                </h6>
                                <table className="table table-striped mt-3">
                                    <thead>
                                    <tr>
                                        <th scope="col">Параметр</th>
                                        <th scope="col" className="text-right">Значение</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Всего организаций</td>
                                        <td align="right">{statistic['countOrganization']}</td>
                                    </tr>
                                    <tr>
                                        <td>Новых организаций за сегодня</td>
                                        <td align="right">{statistic['organizationToday']}</td>
                                    </tr>
                                    <tr>
                                        <td>Новых организаций за текущий месяц</td>
                                        <td align="right">{statistic['organizationMonths']}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-5">
                                <h6>
                                    <i className="bi bi-search"></i> Статистика премиум поиска
                                </h6>
                                <table className="table table-striped mt-3">
                                    <thead>
                                    <tr>
                                        <th scope="col">Параметр</th>
                                        <th scope="col" className="text-right">Значение</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Всего обьявлений</td>
                                        <td align="right">{statistic['allAdsAgregator']} шт.</td>
                                    </tr>
                                    <tr>
                                        <td>Avito</td>
                                        <td align="right">{statistic['countAdsAvito']} шт.</td>
                                    </tr>
                                    <tr>
                                        <td>Drom</td>
                                        <td align="right">{statistic['countAdsDrom']} шт.</td>
                                    </tr>
                                    <tr>
                                        <td>Auto.ru</td>
                                        <td align="right">{statistic['countAdsAuto']} шт.</td>
                                    </tr>
                                    <tr>
                                        <td>Yula</td>
                                        <td align="right">{statistic['countAdsYula']} шт.</td>
                                    </tr>
                                    <tr>
                                        <td>VK</td>
                                        <td align="right">{statistic['countAdsVK']} шт.</td>
                                    </tr>
                                    <tr>
                                        <td>Обьявлений за сегодня</td>
                                        <td align="right">{statistic['countAdsToday']} шт.</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : <LoadingIcon />
                }

            </TemplateAdminPanel>
        </div>
    );
};

export default AdminMainPage;
