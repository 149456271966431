import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainPage from "./pages/publish_part/MainPage";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import CatalogPage from "./pages/publish_part/CatalogPage";
import HelpPage from "./pages/publish_part/helpPage";
import AboutPage from "./pages/publish_part/AboutPage";
import RegistrationPage from "./pages/publish_part/RegistrationPage";
import ForgotPasswodPage from "./pages/publish_part/ForgotPasswodPage";
import PrivatyPage from "./pages/publish_part/PrivatyPage";
import ChangePasswordPage from "./pages/publish_part/ChangePasswordPage";
import PolicyAuth from "./Utility/Policy";
import AdsPage from "./pages/user_part/AdsPage";
import LikesPage from "./pages/user_part/LikesPage";
import SettingPage from "./pages/user_part/SettingPage";
import AddAdsPage from "./pages/user_part/AddAdsPage";
import TowTruckPage from "./pages/publish_part/TowTruckPage";
import React, {useEffect, useState} from "react";
import ApiRequest from "./Utility/ApiRequest";
import LoadingIcon from "./components/LoadingIcon/LoadingIcon";
import OrganizationPage from "./pages/user_part/OrganizationPage";
import TransportationPage from "./pages/publish_part/TransportationPage";
import AdsViewPage from "./pages/publish_part/AdsViewPage";
import AdminMainPage from "./pages/admin_part/AdminMainPage";
import AdminUserPage from "./pages/admin_part/AdminUserPage";
import AdminAdsPage from "./pages/admin_part/AdminAdsPage";
import AdminCategoryPage from "./pages/admin_part/AdminCategoryPage";
import AdminSpravochnikPage from "./pages/admin_part/AdminSpravochnikPage";
import AdminPromoPage from "./pages/admin_part/AdminPromoPage";
import AdminSettingsPage from "./pages/admin_part/AdminSettingsPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppClosedPage from "./pages/publish_part/AppClosedPage";
import AgregatorPage from "./pages/publish_part/AgregatorPage";
import PremiumSearch from "./pages/user_part/PremiumSearch";
import AdminPremiumSearch from "./pages/admin_part/AdminPremiumSearch";

function App() {

    const [appData, setAppData] = useState();

    const loadAppSettings = () => {
        ApiRequest("getAppData?").then((data) => {
            setAppData(data);
            console.log(data)
        });
    }

    useEffect(()=>{
        loadAppSettings();
    }, [setAppData]);

    const reloadCategory = (category) => {
        appData['category'] = category;
        setAppData(appData);
    }
    const reloadSPCars = (SPCars) => {
        appData['SPCars'] = SPCars;
        setAppData(appData);
    }
    const reloadSettings = (settings) => {
        appData['settings'] = settings;
        setAppData(appData);
    }
    const reloadOrganizationData = (OrganizationData) => {
        appData['organizationData'] = OrganizationData;
        setAppData(appData);
    }
    const dicrementAgregatorUserViewToday = () => {
        if (appData['userData']['count_access_view_today']) {
            if (parseInt(appData['userData']['count_access_view_today']) > 0) {
                const newCountView = parseInt(appData['userData']['count_access_view_today']) - 1;
                appData['userData']['count_access_view_today'] = newCountView;
                setAppData(appData);
            }
        }
    }

    return (
      (appData)
      ? <BrowserRouter>
              {
                  appData['settings']['app_work'] || appData['userData']['role'] === "admin"
                      ? <NavigationBar appData={appData}  />
                      : false
              }
            <div className="container pt-5">
                <Routes>
                    {
                        appData['settings']['app_work'] || appData['userData']['role'] === "admin"
                            ? <>
                                    <Route path="*" element={<MainPage appData={appData} loadAppSettings={loadAppSettings} />}/>
                                    <Route exact path="/catalog" element={<CatalogPage appData={appData} />}/>
                                    <Route exact path="/help" element={<HelpPage />}/>
                                    <Route exact path="/about" element={<AboutPage settings={appData['settings']} />}/>
                                    <Route exact path="/privaty" element={<PrivatyPage />}/>
                                    <Route exact path="/towtruck" element={<TowTruckPage appData={appData} />}/>
                                    <Route exact path="/transportation"  element={<TransportationPage appData={appData} />}/>
                                    <Route exact path="/Ads" element={<AdsViewPage appData={appData} />}/>

                                    {(appData['settings']['enable_premium_search'] === 1) ? <Route exact path="/agregator/info" element={<AgregatorPage appData={appData} />}/> : ""}
                                    {!PolicyAuth() ? <Route exact path="/ChangePassword" element={<ChangePasswordPage />}/> : ""}

                                    {!PolicyAuth() && appData['settings']['registr_work'] ? <Route exact path="/registration" element={<RegistrationPage />}/> : ""}
                                    {!PolicyAuth() ? <Route exact path="/forgotPassword" element={<ForgotPasswodPage />}/> : ""}

                                    {PolicyAuth() ? <Route exact path="/account" element={<MainPage appData={appData} loadAppSettings={loadAppSettings} />} /> : ""}
                                    {PolicyAuth() ? <Route exact path="/account/Ads" element={<AdsPage appData={appData} />} /> : ""}
                                    {PolicyAuth() ? <Route exact path="/account/premiumSearch" element={<PremiumSearch appData={appData} dicrementAgregatorUserViewToday={dicrementAgregatorUserViewToday} />} /> : ""}
                                    {
                                        (appData['organizationData'])
                                            ? (PolicyAuth() && appData['organizationData']['admin_id'] === appData['userData']['id'])
                                                ? <Route exact path="/account/Organization" element={<OrganizationPage reloadOrganizationData={reloadOrganizationData} appData={appData} />} />
                                                : ""
                                            : false
                                    }
                                    {PolicyAuth() ? <Route exact path="/account/Likes" element={<LikesPage appData={appData} />} /> : ""}
                                    {PolicyAuth() ? <Route exact path="/account/Setting" element={<SettingPage appData={appData} setAppData={setAppData} />} /> : ""}
                                    {(PolicyAuth() && appData['settings']['add_ads_work']) || (PolicyAuth() && appData['userData']['role'] === "admin") ? <Route exact path="/account/AddAds" element={<AddAdsPage appData={appData} />} /> : ""}
                                </>
                            : <Route path="*" element={<AppClosedPage appData={appData} />}/>
                    }

                    {PolicyAuth() && appData['userData']['role'] === "admin" ? <Route exact path="/spoadm" element={<AdminMainPage appData={appData} />} /> : ""}
                    {PolicyAuth() && appData['userData']['role'] === "admin" ? <Route exact path="/spoadm/users" element={<AdminUserPage appData={appData} />} /> : ""}
                    {PolicyAuth() && (appData['userData']['role'] === "admin" || appData['userData']['moderator']) ? <Route exact path="/spoadm/ads" element={<AdminAdsPage appData={appData} />} /> : ""}
                    {PolicyAuth() && appData['userData']['role'] === "admin" ? <Route exact path="/spoadm/premiumSearch" element={<AdminPremiumSearch reloadSettings={reloadSettings} appData={appData} />} /> : ""}
                    {PolicyAuth() && appData['userData']['role'] === "admin" ? <Route exact path="/spoadm/category" element={<AdminCategoryPage reloadCategory={reloadCategory} appData={appData} />} /> : ""}
                    {PolicyAuth() && appData['userData']['role'] === "admin" ? <Route exact path="/spoadm/spravochnik" element={<AdminSpravochnikPage reloadSPCars={reloadSPCars} appData={appData} />} /> : ""}
                    {PolicyAuth() && (appData['userData']['role'] === "admin" || appData['userData']['moderator']) ? <Route exact path="/spoadm/promo" element={<AdminPromoPage appData={appData} />} /> : ""}
                    {PolicyAuth() && appData['userData']['role'] === "admin" ? <Route exact path="/spoadm/settings" element={<AdminSettingsPage appData={appData} reloadSettings={reloadSettings} />} /> : ""}
                </Routes>
            </div>
              <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
              />
            <div className="mt-5"></div>
        </BrowserRouter>
          : <LoadingIcon />
  );
}

export default App;
