import React from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";

const HelpPage = () => {
    return (
        <div>
            <PageHeader title="Помощь" />
        </div>
    );
};

export default HelpPage;
