import React, {useState} from 'react';
import AdsListTableItem from "../AdsCard/AdsListTableItem";
import Modal from "react-bootstrap/Modal";
import sendForm from "../../Utility/SendForm";
import FormEditAds from "./FormEditAds";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import apiRequest from "../../Utility/ApiRequest";

const AdsListTable = (props) => {

    const [delAds, setDelAds] = useState(false);
    const [delAdsId, setDelAdsId] = useState(false);
    const [delAdsName, setDelAdsName] = useState(false);

    const [changeStatus, setChangeStatus] = useState(false);
    const [changeStatuslAdsId, setСhangeStatusAdsId] = useState(false);
    const [changeStatusAdsName, setСhangeStatusAdsName] = useState(false);
    const [changeStatusAdsType, setСhangeStatusAdsType] = useState(false);

    const [editAds, setEditAds] = useState(false);
    const [editAdsName, setEditAdsName] = useState(false);
    const [editAdsData, setEditAdsData] = useState(false);

    const setDelAdsAction = (ads_id, name) => {
        setDelAds(true);
        setDelAdsId(ads_id);
        setDelAdsName(name);
    }
    const setChangeStatusAdsAction = (ads_id, name, type) => {
        setChangeStatus(true);
        setСhangeStatusAdsId(ads_id);
        setСhangeStatusAdsName(name);
        setСhangeStatusAdsType(type)
    }
    const setEditAdsAction = (ads_id, name, data) => {
        setEditAds(true);
        setEditAdsName(name);
        apiRequest("getAds?", "ads_id=" + ads_id + "&editType=true").then((data) => {
            if(data['adsData']){
                setEditAdsData(data['adsData']);
            }
        });
    }

    const successDelAds = (data) => {
        if (data['ads_id']){
            props.delAds(data['ads_id']);
            setTimeout(setDelAds, 1000, false);
            setTimeout(setChangeStatus, 1000, false);
        }
    }

    const editAdsExit = () => {
        setEditAds(false);
        setEditAdsData(false);
        setEditAdsName(false);
    }

    return (
        <div>
            <table className="table table-striped mt-3">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Картинка</th>
                    <th scope="col">Название</th>
                    <th scope="col">Цена</th>
                    <th scope="col">Просмотры</th>
                    <th scope="col">Категории</th>
                    <th scope="col">Дата публикации</th>
                    <th scope="col">Статус</th>
                    <th scope="col">Действия</th>
                </tr>
                </thead>
                <tbody>
                {
                    Object.keys(props.Ads).map((keyName, i) => {
                        return <AdsListTableItem setEditAdsAction={setEditAdsAction} setChangeStatusAdsAction={setChangeStatusAdsAction} delAds={setDelAdsAction} index={i+1} Ads={props.Ads[keyName]} key={props.Ads[keyName]['id']} />
                    })
                }
                </tbody>
            </table>
            {
                (delAds)
                    ?<Modal show={delAds} onHide={(event) => setDelAds(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Удаление обьявления</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form action="deleteAds" id="delAdsForm" onSubmit={(event) => sendForm(event, successDelAds, true, true)} method="GET">
                                <div>
                                    Вы действительно хотите удалить обьявление <b>{delAdsName}</b>?
                                    <hr/>
                                    <div className="mt-2">
                                        <input name="ads_id" type="text" defaultValue={delAdsId} className="form-control d-none" />
                                    </div>
                                    <div className="alert alert-danger d-none mt-3">

                                    </div>
                                    <div className="alert alert-success mt-3 d-none">

                                    </div>
                                    <input type="submit" value="Удалить" className="btn btn-danger mt-3" />
                                    <button onClick={(event) => setDelAds(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    : false
            }
            {
                (changeStatus)
                    ? <Modal show={changeStatus} onHide={(event) => setChangeStatus(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Изменение статуса обьявления</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form action="changeStatusAdsNoActiv" id="delAdsForm" onSubmit={(event) => sendForm(event, successDelAds, true, true)} method="GET">
                                <div>
                                    Вы действительно хотите сделать обьявление <b>{changeStatusAdsName}</b>
                                    {
                                        (changeStatusAdsType === 1)
                                        ? " не активным"
                                            : " активным"
                                    }?
                                    <hr/>
                                    <div className="mt-2">
                                        <input name="ads_id" type="text" defaultValue={changeStatuslAdsId} className="form-control d-none" />
                                        <input name="type_status" type="text" defaultValue={changeStatusAdsType} className="form-control d-none" />
                                    </div>
                                    <div className="alert alert-danger d-none mt-3">

                                    </div>
                                    <div className="alert alert-success mt-3 d-none">

                                    </div>
                                    <input type="submit" value="Сделать" className="btn btn-warning mt-3" />
                                    <button onClick={(event) => setChangeStatus(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    : false
            }
            {
                (editAds)
                    ?<Modal size="lg" show={editAds} onHide={(event) => editAdsExit()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Редактирование обьявления</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                 <div>
                                    Вы редактируете обьявление <b>{editAdsName}</b>
                                    <hr/>
                                    {
                                        (editAdsData)
                                            ? <FormEditAds setEditAds={setEditAds} adsData={editAdsData} dataForm={props.dataForm} />
                                            : <LoadingIcon />
                                    }
                                 </div>
                            </Modal.Body>
                        </Modal>
                        : false
            }
        </div>
    );
};

export default AdsListTable;
