const TableUsers = (props) => {

    return (
        <table className="table table-striped mt-3">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">ФИО</th>
                <th scope="col">Адрес электронной почты</th>
                <th scope="col">Номер телефона</th>
                <th scope="col">Роль</th>
                <th scope="col">Действия</th>
            </tr>
            </thead>
            <tbody>
            {
                Object.keys(props.users).map((keyName, i) => {
                    return (
                        <tr key={keyName}>
                            <th className="align-middle" scope="row">{i+1}</th>
                            <td className="align-middle"><b>{props.users[keyName]['fio']}</b>
                                {
                                    (props.users[keyName]['organizationData'])
                                        ? <div>
                                            Организация: {props.users[keyName]['organizationData']['name']}
                                        </div>
                                        : false
                                }
                            </td>
                            <td className="align-middle">{props.users[keyName]['mail']}</td>
                            <td className="align-middle">{props.users[keyName]['phone']}</td>
                            <td className="align-middle">
                                <b id={"role_user_" + props.users[keyName]['id']}>
                                    {
                                        props.users[keyName]['role'] === "admin"
                                            ? "Администратор"
                                            : props.users[keyName]['moderator']
                                                ? "Модератор"
                                                : "Пользователь"
                                    }
                                </b>
                            </td>
                            <td className="align-middle">
                                <div>

                                    <button className="dropdown-toggle btn btn-primary" data-bs-toggle="dropdown"
                                          aria-expanded="false">
                                        <span className="p-1">Действия</span>
                                    </button>

                                    <ul className="dropdown-menu p-2">
                                        <li>
                                            <button onClick={(event) => props.setViewDataUserF(props.users[keyName]['id'])} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-database-fill"></i> Все данные</button>
                                        </li>
                                        <li>
                                            <button onClick={(event) => props.setUserAdsAccessCountF(props.users[keyName]['id'])} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-sort-numeric-up-alt"></i> Ограничения</button>
                                        </li>
                                        <li>
                                            <button onClick={(event) => props.setDisableCategoryUserF(props.users[keyName]['id'])} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-x-octagon-fill"></i> Блокировка категорий</button>
                                        </li>
                                        <li>
                                            <button onClick={(event) => props.setPremiumSearchF(props.users[keyName]['id'])} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-search"></i> Премиум поиск</button>
                                        </li>
                                        <li>
                                            {
                                                props.users[keyName]['role'] === "user"
                                                    ? !props.users[keyName]['moderator']
                                                        ? <button onClick={(event) => props.setModeratorType(props.users[keyName]['id'], 2)} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-person-fill-gear"></i> Назначить модератором</button>
                                                        : <button onClick={(event) => props.setModeratorType(props.users[keyName]['id'], 1)} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-person-fill-gear"></i> Исключить из модераторов</button>
                                                    : false
                                            }
                                        </li>
                                        <li>
                                            {
                                                !props.users[keyName]['detour_moderator_ads']
                                                    ? <button onClick={(event) => props.setDetourModeratorAds(props.users[keyName]['id'], 2)} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-arrow-90deg-right"></i> Разрешить обход проверки обьявлений</button>
                                                    : <button onClick={(event) => props.setDetourModeratorAds(props.users[keyName]['id'], 1)} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-arrow-90deg-right"></i> Запретить обход проверки обьявлений</button>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
    );
};

export default TableUsers;
