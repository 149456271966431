import React, {useEffect, useState} from 'react';
import SendForm from "../../Utility/SendForm";
import {toast} from "react-toastify";
import Select from "react-select";
import $ from "jquery";

const EditFilterForm = (props) => {

    const [flag, setFlag] = useState(false);

    useEffect(() => {
        if (!flag){
            if (props.dataEditFilter['privod'])
                $("#ds1 option:contains('" + props.dataEditFilter['privod'] + "')").prop("selected", true);
            if (props.dataEditFilter['steeringType'])
                $("#ds2 option:contains('" + props.dataEditFilter['steeringType'] + "')").prop("selected", true);
            if (props.dataEditFilter['typeMotor'])
                $("#ds3 option:contains('" + props.dataEditFilter['typeMotor'] + "')").prop("selected", true);
            if (props.dataEditFilter['transmissionType'])
                $("#ds4 option:contains('" + props.dataEditFilter['transmissionType'] + "')").prop("selected", true);

            let geolocationV = props.dataEditFilter['geocontrol'].split(",");
            let cityes = props.dataEditFilter['geocontrol'];
            geolocationV.map((elem) => {
                $("#editRegion>div>input.region_btn[type='checkbox'][value = '" + elem + "']").each(function () {
                    $(this).prop("checked", true);
                    cityes = cityes.replace(elem + ",", "");
                    cityes = cityes.replace(elem, "");
                });
                return true;
            });

            if (cityes)
                $("#cityInput2").val(cityes);

            onSetRegionInput2();
            setGeoControlValue();
            setFlag(true);
        }
    }, [[]]);

    const setGeoControlValue = () => {
        const valueRegion = $("#region2").prop("value");
        let valueCity = $("#cityInput2").prop("value");
        if (valueCity[valueCity.length - 1] !== ",")
            valueCity = valueCity + ",";
        let valueGeoControl = valueRegion + valueCity;
        $("#geocontrolInput2").prop("value", valueGeoControl);
    }

    const onSetRegionInput2 = () => {

        let allRegion = "";
        $("#editRegion>div>input.region_btn[type='checkbox']:checked").each(function () {
            allRegion += $(this).val().replace(/[0-9] /g, '') + ",";
        });

        $("#region2").prop("value", allRegion);

        setGeoControlValue()
    }

    let statusselectAllRegion = 0;
    const selectAllRegion = (event) => {
        event.preventDefault();

        if(statusselectAllRegion === 0){
            $("input.region_btn").prop("checked", true);
            statusselectAllRegion = 1;
        } else {
            $("input.region_btn").prop("checked", false);
            statusselectAllRegion = 0;
        }

        onSetRegionInput2();
    }

    return (
        <div>
            <div className="card-body">
                <form action="editSettingsFilter" id="editSettingsFilterForm" method="POST" noValidate onSubmit={(event) => SendForm(event, (data) => {
                    if(data['success']){
                        window.location.reload();
                    } else if (data['error'])
                        toast.error(data['error']);
                }, true)}>
                    <div className="row mt-2">
                        <div className="col-md-3">
                            <label className="form-label">Марка автомобиля</label>
                            <Select
                                options={props.carMarka}
                                placeholder="Марка автомобиля"
                                isSearchable={true}
                                onChange={(event) => props.setAdsAvtoMarka(event.value)}
                                defaultValue = {
                                    props.carMarka.filter(option =>
                                        option.value === parseInt(props.dataEditFilter['avtoMarka_id']))
                                }
                            />
                            <input name={"avtoMarka"} type={"text"} value={parseInt(props.dataEditFilter['avtoMarka_id'])} className={"d-none"} required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        <input name={"filtr_id"} type={"text"} value={props.dataEditFilter['id']} className={"d-none"} required />
                        <div className="col-md-3">
                            <label className="form-label">Модель автомобиля</label>
                            <input name="avtoModel" placeholder="Например: Camry" type="text" defaultValue={props.dataEditFilter['avtoModel']} className="form-control" />
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">Привод</label>
                            <select name="privod" id="ds1" className="form-select">
                                <option value=""></option>
                                <option value="1">Передний</option>
                                <option value="2">Задний</option>
                                <option value="3">Полный</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">Руль</label>
                            <select name="steeringType" id="ds2" className="form-select">
                                <option value=""></option>
                                <option value="1">Левый</option>
                                <option value="2">Правый</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-3">
                            <label className="form-label">Обьем двигателя (л.)</label>
                            <input name="volumeMotor" placeholder="Например: 1.6" defaultValue={props.dataEditFilter['volumeMotor']} type="number" size="5" className="form-control" />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">Тип двигателя</label>
                            <select name="typeMotor" id="ds3" className="form-select">
                                <option value=""></option>
                                <option value="1">Бензин</option>
                                <option value="2">Дизель</option>
                                <option value="3">Гибрид</option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="validationCustom01" className="form-label">Мощность двигателя (л.с.)</label>
                            <input name="strongMotor" defaultValue={props.dataEditFilter['strongMotor']} placeholder="Например: 250" type="number" size="5" className="form-control" />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        {/*<div className="col-md-3">*/}
                        {/*    <label htmlFor="validationCustom01" className="form-label">Макс. пробег двигателя (тыс. км.)</label>*/}
                        {/*    <input name="mileageMotor" placeholder="Например: 200" type="number" size="5" className="form-control" />*/}
                        {/*    <div className="valid-feedback">*/}
                        {/*        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено*/}
                        {/*    </div>*/}
                        {/*    <div className="invalid-feedback">*/}
                        {/*        Поле обязательно к заполнению*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-md-3">
                            <label className="form-label">Тип трансмиссии</label>
                            <select name="transmissionType" id="ds4" className="form-select">
                                <option value=""></option>
                                <option value="1">Механика</option>
                                <option value="2">Автомат</option>
                                <option value="3">Вариатор</option>
                                <option value="4">Робот</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-3">
                            <label className="form-label">Год выпуска</label>
                            <input name="yearCreate" defaultValue={props.dataEditFilter['yearCreate']} placeholder="Например: 2010" type="number" className="form-control" />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="validationCustom01" className="form-label">Цена от</label>
                            <input name="price_start" defaultValue={props.dataEditFilter['price_start']} placeholder="Например: 0" type="number" size="5" className="form-control" />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="validationCustom01" className="form-label">Цена до</label>
                            <input name="price_end" defaultValue={props.dataEditFilter['price_end']} placeholder="Например: 100000" type="number" size="5" className="form-control" />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom01" className="form-label">Регионы</label>
                        <input name="geocontrol" type="text" className="form-control mt-1 d-none" id="geocontrolInput2" placeholder="" />
                        <input type="text" className="form-control mt-1 d-none" id="region2" placeholder="" />
                        <div onClick={(event) => selectAllRegion(event)} className="btn btn-link">Выбрать все регионы</div>
                        <div className="row pl-3" id="editRegion">
                            <div className="col-md-3">
                                <input type="checkbox" value="Республика Адыгея" className="form-check-input region_btn" onClick={onSetRegionInput2} />1 Республика Адыгея<br />
                                <input type="checkbox" value="Республика Башкортостан" className="form-check-input region_btn" onClick={onSetRegionInput2} />2 Республика Башкортостан<br />
                                <input type="checkbox" value="Республика Бурятия" className="form-check-input region_btn" onClick={onSetRegionInput2} />3 Республика Бурятия<br />
                                <input type="checkbox" value="Республика Алтай" className="form-check-input region_btn" onClick={onSetRegionInput2} />4 Республика Алтай<br />
                                <input type="checkbox" value="Республика Дагестан" className="form-check-input region_btn" onClick={onSetRegionInput2} />5 Республика Дагестан<br />
                                <input type="checkbox" value="Республика Ингушетия" className="form-check-input region_btn" onClick={onSetRegionInput2} />6 Республика Ингушетия<br />
                                <input type="checkbox" value="Кабардино-Балкарская Республика" className="form-check-input region_btn" onClick={onSetRegionInput2} />7 Кабардино-Балкарская Республика<br />
                                <input type="checkbox" value="Республика Калмыкия" className="form-check-input region_btn" onClick={onSetRegionInput2} />8 Республика Калмыкия<br />
                                <input type="checkbox" value="Карачаево-Черкесская Республика" className="form-check-input region_btn" onClick={onSetRegionInput2} />9 Карачаево-Черкесская Республика<br />
                                <input type="checkbox" value="Республика Карелия" className="form-check-input region_btn" onClick={onSetRegionInput2} />10 Республика Карелия<br />
                                <input type="checkbox" value="Республика Коми" className="form-check-input region_btn" onClick={onSetRegionInput2} />11 Республика Коми<br />
                                <input type="checkbox" value="Республика Марий Эл" className="form-check-input region_btn" onClick={onSetRegionInput2} />12 Республика Марий Эл<br />
                                <input type="checkbox" value="Республика Мордовия" className="form-check-input region_btn" onClick={onSetRegionInput2} />13 Республика Мордовия<br />
                                <input type="checkbox" value="Республика Саха" className="form-check-input region_btn" onClick={onSetRegionInput2} />14 Республика Саха<br />
                                <input type="checkbox" value="Республика Северная Осетия" className="form-check-input region_btn" onClick={onSetRegionInput2} />15 Республика Северная Осетия<br />
                                <input type="checkbox" value="Республика Татарстан" className="form-check-input region_btn" onClick={onSetRegionInput2} />16 Республика Татарстан<br />
                                <input type="checkbox" value="Республика Тыва" className="form-check-input region_btn" onClick={onSetRegionInput2} />17 Республика Тыва<br />
                                <input type="checkbox" value="Удмуртская Республика" className="form-check-input region_btn" onClick={onSetRegionInput2} />18 Удмуртская Республика<br />
                                <input type="checkbox" value="Республика Хакасия" className="form-check-input region_btn" onClick={onSetRegionInput2} />19 Республика Хакасия<br />
                                <input type="checkbox" value="Чеченская Республика" className="form-check-input region_btn" onClick={onSetRegionInput2} />20 Чеченская Республика<br />
                                <input type="checkbox" value="Ямало-Ненецкий автономный округ" className="form-check-input region_btn" onClick={onSetRegionInput2} />89 Ямало-Ненецкий автономный округ<br />
                                <input type="checkbox" value="Запорожская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />90 Запорожская область<br />
                            </div>
                            <div className="col-md-3">
                                <input type="checkbox" value="Чувашская Республика" className="form-check-input region_btn" onClick={onSetRegionInput2} />21 Чувашская Республика<br />
                                <input type="checkbox" value="Алтайский край" className="form-check-input region_btn" onClick={onSetRegionInput2} />22 Алтайский край<br />
                                <input type="checkbox" value="Краснодарский край" className="form-check-input region_btn" onClick={onSetRegionInput2} />23 Краснодарский край<br />
                                <input type="checkbox" value="Красноярский край" className="form-check-input region_btn" onClick={onSetRegionInput2} />24 Красноярский край<br />
                                <input type="checkbox" value="Приморский край" className="form-check-input region_btn" onClick={onSetRegionInput2} />25 Приморский край<br />
                                <input type="checkbox" value="Ставропольский край" className="form-check-input region_btn" onClick={onSetRegionInput2} />26 Ставропольский край<br />
                                <input type="checkbox" value="Хабаровский край" className="form-check-input region_btn" onClick={onSetRegionInput2} />27 Хабаровский край<br />
                                <input type="checkbox" value="Амурская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />28 Амурская область<br />
                                <input type="checkbox" value="Архангельская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />29 Архангельская область<br />
                                <input type="checkbox" value="Астраханская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />30 Астраханская область<br />
                                <input type="checkbox" value="Белгородская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />31 Белгородская область<br />
                                <input type="checkbox" value="Брянская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />32 Брянская область<br />
                                <input type="checkbox" value="Владимирская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />33 Владимирская область<br />
                                <input type="checkbox" value="Волгоградская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />34 Волгоградская область<br />
                                <input type="checkbox" value="Вологодская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />35 Вологодская область<br />
                                <input type="checkbox" value="Воронежская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />36 Воронежская область<br />
                                <input type="checkbox" value="Ивановская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />37 Ивановская область<br />
                                <input type="checkbox" value="Иркутская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />38 Иркутская область<br />
                                <input type="checkbox" value="Калининградская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />39 Калининградская область<br />
                                <input type="checkbox" value="Калужская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />40 Калужская область<br />
                                <input type="checkbox" value="Донецкая Народная Республика" className="form-check-input region_btn" onClick={onSetRegionInput2} />93 Донецкая Народная Республика<br />
                                <input type="checkbox" value="Луганская Народная Республика" className="form-check-input region_btn" onClick={onSetRegionInput2} />94 Луганская Народная Республика<br />
                            </div>
                            <div className="col-md-3">
                                <input type="checkbox" value="Камчатский край" className="form-check-input region_btn" onClick={onSetRegionInput2} />41 Камчатский край<br />
                                <input type="checkbox" value="Кемеровская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />42 Кемеровская область<br />
                                <input type="checkbox" value="Кировская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />43 Кировская область<br />
                                <input type="checkbox" value="Костромская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />44 Костромская область<br />
                                <input type="checkbox" value="Курганская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />45 Курганская область<br />
                                <input type="checkbox" value="Курская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />46 Курская область<br />
                                <input type="checkbox" value="Ленинградская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />47 Ленинградская область<br />
                                <input type="checkbox" value="Липецкая область" className="form-check-input region_btn" onClick={onSetRegionInput2} />48 Липецкая область<br />
                                <input type="checkbox" value="Магаданская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />49 Магаданская область<br />
                                <input type="checkbox" value="Московская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />50 Московская область<br />
                                <input type="checkbox" value="Мурманская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />51 Мурманская область<br />
                                <input type="checkbox" value="Нижегородская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />52 Нижегородская область<br />
                                <input type="checkbox" value="Новгородская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />53 Новгородская область<br />
                                <input type="checkbox" value="Новосибирская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />54 Новосибирская область<br />
                                <input type="checkbox" value="Омская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />55 Омская область<br />
                                <input type="checkbox" value="Оренбургская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />56 Оренбургская область<br />
                                <input type="checkbox" value="Орловская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />57 Орловская область<br />
                                <input type="checkbox" value="Пензенская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />58 Пензенская область<br />
                                <input type="checkbox" value="Пермский край" className="form-check-input region_btn" onClick={onSetRegionInput2} />59 Пермский край<br />
                                <input type="checkbox" value="Псковская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />60 Псковская область<br />
                                <input type="checkbox" value="Республика Крым" className="form-check-input region_btn" onClick={onSetRegionInput2} />91 Республика Крым<br />
                                <input type="checkbox" value="Херсонская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />95 Херсонская область<br />
                            </div>
                            <div className="col-md-3">
                                <input type="checkbox" value="Ростовская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />61 Ростовская область<br />
                                <input type="checkbox" value="Рязанская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />62 Рязанская область<br />
                                <input type="checkbox" value="Самарская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />63 Самарская область<br />
                                <input type="checkbox" value="Саратовская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />64 Саратовская область<br />
                                <input type="checkbox" value="Сахалинская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />65 Сахалинская область<br />
                                <input type="checkbox" value="Свердловская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />66 Свердловская область<br />
                                <input type="checkbox" value="Смоленская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />67 Смоленская область<br />
                                <input type="checkbox" value="Тамбовская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />68 Тамбовская область<br />
                                <input type="checkbox" value="Тверская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />69 Тверская область<br />
                                <input type="checkbox" value="Томская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />70 Томская область<br />
                                <input type="checkbox" value="Тульская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />71 Тульская область<br />
                                <input type="checkbox" value="Тюменская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />72 Тюменская область<br />
                                <input type="checkbox" value="Ульяновская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />73 Ульяновская область<br />
                                <input type="checkbox" value="Челябинская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />74 Челябинская область<br />
                                <input type="checkbox" value="Забайкальский край" className="form-check-input region_btn" onClick={onSetRegionInput2} />75 Забайкальский край<br />
                                <input type="checkbox" value="Ярославская область" className="form-check-input region_btn" onClick={onSetRegionInput2} />76 Ярославская область<br />
                                <input type="checkbox" value="Еврейская автономная область" className="form-check-input region_btn" onClick={onSetRegionInput2} />79 Еврейская автономная область<br />
                                <input type="checkbox" value="Ненецкий автономный округ" className="form-check-input region_btn" onClick={onSetRegionInput2} />83 Ненецкий автономный округ<br />
                                <input type="checkbox" value="Ханты-Мансийский автономный округ" className="form-check-input region_btn" onClick={onSetRegionInput2} />86 Ханты-Мансийский автономный округ<br />
                                <input type="checkbox" value="Чукотский автономный округ" className="form-check-input region_btn" onClick={onSetRegionInput2} />87 Чукотский автономный округ<br />
                            </div>
                        </div>
                    </div>
                    <label htmlFor="validationCustom01" className="form-label mt-3">Города</label>
                    <input type="text" className="form-control mt-1" id="cityInput2" placeholder="Введите названия городов через запятую" />
                    <div className="alert alert-danger d-none mt-3">

                    </div>
                    <div className="alert alert-success mt-2 d-none">

                    </div>
                    <div className="mt-3">
                        <input className="btn btn-success w-100" type="submit" value="Изменить фильтр" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditFilterForm;
