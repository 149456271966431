import axios from "axios";
import {getDataStorage} from "./Storage";

const apiRequest = async (url_method, param = "", method = "GET", uploadFile = false) => {
    const hash = 'df7g67df6g78df6g7df6g78df8df90d00as-dsad9as0d9fd7gfd668fd78d-sdfdsfdsf5sd6f56sdf56sd5f6sdf';
    let token = getDataStorage("access_token");
    token = (token) ? "&access_token=" + token : ""
    const BaseUrl = 'https://api.kuban-avto.com/main/';
    let configRequest = {};

    if (param === "")
        param = "request=true";

    if(uploadFile){
        configRequest = {
            headers: {
                'content-type': 'multipart/form-data',
            }
        }
    }

    if (method === "GET")
        return axios.get(BaseUrl + url_method + param + "&hash=" + hash + token, configRequest).then(response => response.data);

    if (method === "POST")
        return axios.post(BaseUrl + url_method + 'hash=' + hash + token, param, configRequest).then(response => response.data);
}

export default apiRequest;