import React from 'react';

const TableDataPart = (props) => {
    return (
        <div className="row">
            <div className="col-md-6">
                <b>Общая информация</b>
                <div className="mt-1">Тип запчасти: {props.adsData['typePart']}</div>
                <div className="mt-1">Марка автомобиля: {props.adsData['avtoMarka']['name']}</div>
                <div className="mt-1">Модель автомобиля: {props.adsData['avtoModel']}</div>
            </div>
            <div className="col-md-6">
                {
                    (props.adsData['diamWheel'] !== "" && props.adsData['diamOtverst'] !== "" &&
                        props.adsData['countOtverst'] !== "" && props.adsData['posadOtverst'] !== "" &&
                        props.adsData['viletWheel'] !== "" && props.adsData['lengthPokrishki'] !== "")
                        ? <div>
                            <b>Дополнительная информация</b>
                            <div className="mt-1">Диаметр колеса (дюйм): {props.adsData['diamWheel']}</div>
                            <div className="mt-1">Диаметр крепежных отверстий (мм.): {props.adsData['diamOtverst']}</div>
                            <div className="mt-1">Количество отверстий (шт.): {props.adsData['countOtverst']}</div>
                            <div className="mt-1">Посадочное отверстие (мм.): {props.adsData['posadOtverst']}</div>
                            <div className="mt-1">Вылет колеса (мм.): {props.adsData['viletWheel']}</div>
                            <div className="mt-1">Размер покрышки: {props.adsData['lengthPokrishki']}</div>
                        </div>
                        : false
                }
            </div>
        </div>
    );
};

export default TableDataPart;
