import React from 'react';
import TableAdsFavoriteItem from "../AdsCard/TableAdsFavoriteItem";

const TableAdsFavorite = (props) => {

    return (
        <div>
            <table className="table table-striped mt-3">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Картинка</th>
                    <th scope="col">Название</th>
                    <th scope="col">Цена</th>
                    <th scope="col">Просмотры</th>
                    <th scope="col">Категории</th>
                    <th scope="col">Дата публикации</th>
                </tr>
                </thead>
                <tbody>
                {
                    Object.keys(props.Ads).map((keyName, i) => {
                        return <TableAdsFavoriteItem index={i+1} Ads={props.Ads[keyName]} key={props.Ads[keyName]['id']} />
                    })
                }
                </tbody>
            </table>
        </div>
    );
};

export default TableAdsFavorite;
