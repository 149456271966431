import React, {useEffect} from 'react';
import $ from "jquery";

const FormTypeCar = (props) => {

    useEffect(() => {
        if (props.adsData) {
            let color_ = props.adsData['color'];
            let vinNomer = props.adsData['vinNomer'];
            let yearCreate = props.adsData['yearCreate'];
            let privod = props.adsData['privod'];
            let steeringType = props.adsData['steeringType'];
            let transmissionType = props.adsData['transmissionType'];
            let volumeMotor = props.adsData['volumeMotor'];
            let typeMotor = props.adsData['typeMotor'];
            let mileageMotor = props.adsData['mileageMotor'];
            let strongMotor = props.adsData['strongMotor'];
            let ptsCountUser = props.adsData['ptsCountUser'];
            let ptsType = props.adsData['ptsType'];
            let price = props.adsData['price'];
            let description = props.adsData['description'];

            $("select[name='privod'] option:contains('" + privod + "')").prop('selected', true);
            $("select[name='steeringType'] option:contains('" + steeringType + "')").prop('selected', true);
            $("select[name='transmissionType'] option:contains('" + transmissionType + "')").prop('selected', true);
            $("select[name='typeMotor'] option:contains('" + typeMotor + "')").prop('selected', true);
            $("select[name='ptsType'] option:contains('" + ptsType + "')").prop('selected', true);

            $("input[name='color']").attr("value", color_);
            $("input[name='vinNomer']").attr("value", vinNomer);
            $("input[name='yearCreate']").attr("value", yearCreate);
            $("input[name='volumeMotor']").attr("value", volumeMotor);
            $("input[name='strongMotor']").attr("value", strongMotor);
            $("input[name='ptsCountUser']").attr("value", ptsCountUser);
            $("input[name='price']").attr("value", price);
            $("input[name='mileageMotor']").attr("value", mileageMotor);
            $("textarea[name='description']").attr("value", description);
        }
    }, [props.adsData]);

    return (
        <div>
            <div className="mt-3">
                <b>Цвет</b>
                <hr />
                <input type="color" name="color" className="form-control form-control-color w-100" title="" required />
                <div className="valid-feedback">
                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                </div>
                <div className="invalid-feedback">
                    Поле обязательно к заполнению
                </div>
            </div>
            <div className="mt-3">
                <b>VIN номер</b>
                <hr />
                <input id="vinNomerInput" style={{textTransform: "uppercase"}} type="text" name="vinNomer" className="form-control form-control-color w-100" placeholder="" required />
                <div className="valid-feedback">
                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                </div>
                <div className="invalid-feedback">
                    Поле обязательно к заполнению
                </div>
            </div>
            <div className="mt-3">
                <b>Общая информация</b>
                <hr />
                <div className="row">
                    <div className="col-md-3">
                        <label className="form-label">Год выпуска</label>
                        <input name="yearCreate" placeholder="" type="number" size="5" className="form-control" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Привод</label>
                        <select name="privod" className="form-select">
                            <option value="1">Передний</option>
                            <option value="2">Задний</option>
                            <option value="3">Полный</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Руль</label>
                        <select name="steeringType" className="form-select">
                            <option value="1">Левый</option>
                            <option value="2">Правый</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Тип трансмиссии</label>
                        <select name="transmissionType" className="form-select">
                            <option value="1">Механическая</option>
                            <option value="2">Автоматическая</option>
                            <option value="3">Вариаторная</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <b>Информация о двигателе</b>
                <hr />
                <div className="row">
                    <div className="col-md-3">
                        <label className="form-label">Обьем двигателя (л.)</label>
                        <input name="volumeMotor" placeholder="" type="number" size="5" className="form-control" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-2">
                        <label className="form-label">Тип двигателя</label>
                        <select name="typeMotor" className="form-select">
                            <option value="1">Бензин</option>
                            <option value="2">Дизель</option>
                            <option value="3">Гибрид</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom01" className="form-label">Мощность двигателя (л.с.)</label>
                        <input name="strongMotor" placeholder="" type="number" size="5" className="form-control" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom01" className="form-label">Пробег двигателя (тыс. км.)</label>
                        <input name="mileageMotor" placeholder="" type="number" size="5" className="form-control" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <b>Информация о владельцах</b>
                <hr />
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Владельцев по ПТС</label>
                        <input name="ptsCountUser" placeholder="" type="number" size="5" className="form-control" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Тип ПТС</label>
                        <select name="ptsType" className="form-select">
                            <option value="1">Оригинал</option>
                            <option value="2">Дубликат</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <label className="form-label"><b>Цена (руб.)</b></label>
                <input name="price" placeholder="" type="number" size="7" className="form-control" required />
                <div className="valid-feedback">
                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                </div>
                <div className="invalid-feedback">
                    Поле обязательно к заполнению
                </div>
                <div className="custom-control custom-checkbox mr-sm-2 mt-1">
                    <input defaultChecked={(props.adsData && props.adsData['isNotFixedPrice']) ? true : false} value="1" name="isNotFixedPrice" type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                    <label className="custom-control-label" htmlFor="customControlAutosizing"> Цена является плавающей</label>
                </div>
            </div>
            <div className="mt-3">
                <b>Описание</b>
                <hr />
                <div className="form-group">
                    <textarea name="description" className="form-control" rows="6">
                        {
                            (props.adsData)
                                ? props.adsData['description']
                                : ""
                        }
                    </textarea>
                </div>
            </div>
        </div>
    );
};


export default FormTypeCar;
