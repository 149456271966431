import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import apiRequest from "../../Utility/ApiRequest";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import GlobalParam from "../../Utility/AppGlobalParam";
import $ from 'jquery';
import Modal from "react-bootstrap/Modal";
import SendForm from "../../Utility/SendForm";
import Select from "react-select";
import {toast} from "react-toastify";
import screen_1 from "../../images/screen_1.png"
import screen_2 from "../../images/screen_2.png"
import {forEach} from "react-bootstrap/ElementChildren";
import EditFilterForm from "../../components/PremiumSearch/EditFilterForm";
import {useSearchParams} from "react-router-dom";

const PremiumSearch = (props) => {
    const [searchParams] = useSearchParams();
    const [allAds, setAllAds] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);
    const [loadStartAds, setLoadStartAds] = useState(false);
    const [loadLiveAds, setLoadLiveAds] = useState(false);
    const [loadNextStepAds, setLoadNextStepAds] = useState(false);
    const [dopInfoAds, setDopInfo] = useState(false);
    const [dopInfoData, setDopInfoData] = useState(false);
    const [editSettingFilter, setEditSettingFilter] = useState(false);
    const [carMarka, setCarMarka] = useState(false);
    const [adsAvtoMarka, setAdsAvtoMarka] = useState("");
    const [userFilters, setUserFilters] = useState(false);
    const [loadUserFilter, setLoadUserFilter] = useState(false);
    const [errorUserFilter, setErrorUserFilter] = useState(false);
    const [viewImage, setViewImage] = useState(false);
    const [intervalLoadLifeAds, setIntervalLoadLifeAds] = useState(false);
    const [dataEditFilter, setDataEditFilter] = useState(0);

    const loadDeffaultAds = () => {
        setErrorMessage(false);
        apiRequest("getAdsAgregator?").then((data) => {
            if (data['success']){
                setAllAds(data['ads']);
            } else
                setErrorMessage(data['error']);
            setLoadStartAds(true);
        });
    }
    const loadLiveAdsF = () => {
        if(!loadLiveAds) {
            setLoadLiveAds(true);
            let first_id = 0;

            allAds.map((elem, i) => {
                if (first_id === 0)
                    first_id = elem['id'];
            });

            if (first_id === 0)
                return false

            apiRequest("loadLiveAddAdsAgregator?", "last_ads_id=" + first_id).then((data) => {
                if (data['success']){
                    setAllAds([...data['ads'], ...allAds]);
                }
                setLoadLiveAds(false);
            });
        }
    }
    const loadNextAdsSpisok = () => {
        if(!loadNextStepAds) {
            setLoadNextStepAds(true);
            let first_id = 0;

            allAds.map((elem, i) => {
                first_id = elem['id'];
            });

            if (first_id === 0)
                return false

            apiRequest("loadNextAdsSpisok?", "last_ads_id=" + first_id).then((data) => {
                if (data['success']){
                    setAllAds([...allAds, ...data['ads']]);
                }
                setLoadNextStepAds(false);
            });
        }
    };

    const openDopInfoAdsAgregator = (id) => {
        Object.keys(allAds).map((keyName, i) => {
            if (allAds[keyName]['id'] === id)
                setDopInfoData(allAds[keyName])
        });
        setDopInfo(true)
    }
    const openAdsFromSite = (id) => {

        if(!$(".alert-danger").hasClass("d-none"))
            $(".alert-danger").addClass("d-none");

        $(".alert-danger").empty();

        apiRequest("openAdsFromSite?", "ads_id=" + id).then((data) => {
           if(data['success']){
               props.dicrementAgregatorUserViewToday();
               window.open(data['success'], '_blank');
           } else {
               $(".alert-danger").append(data['error']);
               $(".alert-danger").removeClass("d-none");
           }
        });
    }

    const EnableSettingFilterEdit = (filter_id, type) => {
        apiRequest("EnableSettingFilterEdit?", "filter_id=" + filter_id + "&type=" + type).then((data) => {
            if(data['success']){
                toast.success(data['success']);
                setUserFilters(data['filters']);
                loadDeffaultAds();
            } else {
                toast.error(data['error']);
            }
        });
    }
    const deleteSettingFilterEdit = (filter_id) => {
        apiRequest("deleteSettingFilterEdit?", "filter_id=" + filter_id).then((data) => {
            if(data['success']){
                window.location.reload();
            } else {
                toast.error(data['error']);
            }
        });
    }

    const setMainImg = (url) => {
        $(".main_image").attr("src", url);
    }

    useEffect(() => {
        setCarMarka(props.appData['SPCars']);
        if (!loadStartAds){
            loadDeffaultAds();
            setLoadStartAds(true)
        }

        if (!loadUserFilter){
            apiRequest("getMySettingsFilter?").then((data) => {
                if (data['success']){
                    setUserFilters(data['filters']);
                } else
                    setErrorUserFilter(data['error']);

                setLoadUserFilter(true);
            });
        }

        if (searchParams.get("adsId")) {
            const idAds = searchParams.get("adsId");
            apiRequest('GetDataOneAdsAggregator?', 'ads_id=' + idAds).then((data) => {
                if(data['adsData']){
                    setDopInfoData(data['adsData']);
                    setDopInfo(true);
                }
            });
        }

        if (!intervalLoadLifeAds){
            const loadLive = setInterval(() => {
                loadLiveAdsF()
            }, GlobalParam.intervalLoadAdsFromAgregator);

            // очистка интервала
            return () => {
                clearInterval(loadLive)
            };
            setIntervalLoadLifeAds(true);
        }
    }, [setAllAds, allAds, searchParams]);

    const setGeoControlValue = () => {
        const valueRegion = $("#region").prop("value");
        let valueCity = $("#cityInput").prop("value");
        if (valueCity[valueCity.length - 1] !== ",")
            valueCity = valueCity + ",";
        let valueGeoControl = valueRegion + valueCity;
        $("#geocontrolInput").prop("value", valueGeoControl);
    }

    const onSetRegionInput = () => {
        let allRegion = "";
        $("input.region_btn[type='checkbox']:checked").each(function () {
            allRegion += $(this).val().replace(/[0-9] /g, '') + ",";
        });
        $("#region").prop("value", allRegion);
        setGeoControlValue()
    }

    let statusselectAllRegion = 0;
    const selectAllRegion = (event) => {
        event.preventDefault();

        if(statusselectAllRegion === 0){
            $("input.region_btn").prop("checked", true);
            statusselectAllRegion = 1;
        } else {
            $("input.region_btn").prop("checked", false);
            statusselectAllRegion = 0;
        }

        onSetRegionInput();
    }

    const setEditFilterF = (id) => {
        setDataEditFilter(id);
        setEditSettingFilter(true);
    }
    const loadAdsVK = () => {
        if ($("#vk_checkbox_viewAds").prop("checked")){
            setLoadLiveAds(true);
            setLoadNextStepAds(true);
            apiRequest("getAdsAgregatorVK?").then((data) => {
                console.log(data);
                if (data['success']){
                    setAllAds(data['ads']);
                } else
                    setErrorMessage(data['error']);
            });
        } else {
            loadDeffaultAds();
            setLoadLiveAds(false);
            setLoadNextStepAds(false);
        }
    }

    return (
        <div>
            <PageHeader title="Премиум поиск" />
            <div className="mt-3" id="accordion">
                <div className="mt-3">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="bg-primary" id="headingOne">
                                <h5 className="mb-0">
                                    <button className="btn btn-primary w-100" data-toggle="collapse" data-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                        <b>Добавить новый фильтр</b>
                                    </button>
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="bg-primary" id="headingTwo">
                                <h5 className="mb-0">
                                    <button className="btn btn-primary w-100 collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo">
                                        <b>Мои фильтры</b>
                                    </button>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="btn btn-primary w-100 mt-3">
                        <div className="form-check form-switch">
                            <input onChange={(event) => loadAdsVK()} id="vk_checkbox_viewAds" className="form-check-input" type="checkbox" />
                            <label className="form-check-label" htmlFor=""><b>Отобразить обьявления из Вконтакте</b></label>
                        </div>
                    </div>
                </div>
                <div className="mt-1">
                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne"
                         data-parent="#accordion">
                        <div className="card-body">
                            <form action="addSettingsFilter" id="addSettingsFilterForm" method="POST" noValidate onSubmit={(event) => SendForm(event, (data) => {
                                if(data['success']){
                                    window.location.reload();
                                } else if (data['error'])
                                    toast.error(data['error']);
                            }, true)}>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label className="form-label">Марка автомобиля</label>
                                        <Select
                                            options={carMarka}
                                            placeholder="Марка автомобиля"
                                            isSearchable={true}
                                            onChange={(event) => setAdsAvtoMarka(event.value)}
                                        />
                                        <input name={"avtoMarka"} type={"text"} value={adsAvtoMarka} className={"d-none"} required />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">Модель автомобиля</label>
                                        <input name="avtoModel" placeholder="Например: Camry" type="text" className="form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">Привод</label>
                                        <select name="privod" className="form-select">
                                            <option value=""></option>
                                            <option value="1">Передний</option>
                                            <option value="2">Задний</option>
                                            <option value="3">Полный</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">Руль</label>
                                        <select name="steeringType" className="form-select">
                                            <option value=""></option>
                                            <option value="1">Левый</option>
                                            <option value="2">Правый</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label className="form-label">Обьем двигателя (л.)</label>
                                        <input name="volumeMotor" placeholder="Например: 1.6" type="number" size="5" className="form-control" />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">Тип двигателя</label>
                                        <select name="typeMotor" className="form-select">
                                            <option value=""></option>
                                            <option value="1">Бензин</option>
                                            <option value="2">Дизель</option>
                                            <option value="3">Гибрид</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationCustom01" className="form-label">Мощность двигателя (л.с.)</label>
                                        <input name="strongMotor" placeholder="Например: 250" type="number" size="5" className="form-control" />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    {/*<div className="col-md-3">*/}
                                    {/*    <label htmlFor="validationCustom01" className="form-label">Макс. пробег двигателя (тыс. км.)</label>*/}
                                    {/*    <input name="mileageMotor" placeholder="Например: 200" type="number" size="5" className="form-control" />*/}
                                    {/*    <div className="valid-feedback">*/}
                                    {/*        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено*/}
                                    {/*    </div>*/}
                                    {/*    <div className="invalid-feedback">*/}
                                    {/*        Поле обязательно к заполнению*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-md-3">
                                        <label className="form-label">Тип трансмиссии</label>
                                        <select name="transmissionType" className="form-select">
                                            <option value=""></option>
                                            <option value="1">Механика</option>
                                            <option value="2">Автомат</option>
                                            <option value="3">Вариатор</option>
                                            <option value="4">Робот</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label className="form-label">Год выпуска</label>
                                        <input name="yearCreate" placeholder="Например: 2010" type="number" className="form-control" />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationCustom01" className="form-label">Цена от</label>
                                        <input name="price_start" placeholder="Например: 0" type="number" size="5" className="form-control" />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationCustom01" className="form-label">Цена до</label>
                                        <input name="price_end" placeholder="Например: 100000" type="number" size="5" className="form-control" />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <label htmlFor="validationCustom01" className="form-label">Регионы</label>
                                    <input name="geocontrol" type="text" className="form-control mt-1 d-none" id="geocontrolInput" placeholder="" />
                                    <input type="text" className="form-control mt-1 d-none" id="region" placeholder="" />
                                    <div onClick={(event) => selectAllRegion(event)} className="btn btn-link">Выбрать все регионы</div>
                                    <div className="row pl-3">
                                        <div className="col-md-3">
                                            <input type="checkbox" value="Республика Адыгея" className="form-check-input region_btn" onClick={onSetRegionInput} />1 Республика Адыгея<br />
                                            <input type="checkbox" value="Республика Башкортостан" className="form-check-input region_btn" onClick={onSetRegionInput} />2 Республика Башкортостан<br />
                                            <input type="checkbox" value="Республика Бурятия" className="form-check-input region_btn" onClick={onSetRegionInput} />3 Республика Бурятия<br />
                                            <input type="checkbox" value="Республика Алтай" className="form-check-input region_btn" onClick={onSetRegionInput} />4 Республика Алтай<br />
                                            <input type="checkbox" value="Республика Дагестан" className="form-check-input region_btn" onClick={onSetRegionInput} />5 Республика Дагестан<br />
                                            <input type="checkbox" value="Республика Ингушетия" className="form-check-input region_btn" onClick={onSetRegionInput} />6 Республика Ингушетия<br />
                                            <input type="checkbox" value="Кабардино-Балкарская Республика" className="form-check-input region_btn" onClick={onSetRegionInput} />7 Кабардино-Балкарская Республика<br />
                                            <input type="checkbox" value="Республика Калмыкия" className="form-check-input region_btn" onClick={onSetRegionInput} />8 Республика Калмыкия<br />
                                            <input type="checkbox" value="Карачаево-Черкесская Республика" className="form-check-input region_btn" onClick={onSetRegionInput} />9 Карачаево-Черкесская Республика<br />
                                            <input type="checkbox" value="Республика Карелия" className="form-check-input region_btn" onClick={onSetRegionInput} />10 Республика Карелия<br />
                                            <input type="checkbox" value="Республика Коми" className="form-check-input region_btn" onClick={onSetRegionInput} />11 Республика Коми<br />
                                            <input type="checkbox" value="Республика Марий Эл" className="form-check-input region_btn" onClick={onSetRegionInput} />12 Республика Марий Эл<br />
                                            <input type="checkbox" value="Республика Мордовия" className="form-check-input region_btn" onClick={onSetRegionInput} />13 Республика Мордовия<br />
                                            <input type="checkbox" value="Республика Саха" className="form-check-input region_btn" onClick={onSetRegionInput} />14 Республика Саха<br />
                                            <input type="checkbox" value="Республика Северная Осетия" className="form-check-input region_btn" onClick={onSetRegionInput} />15 Республика Северная Осетия<br />
                                            <input type="checkbox" value="Республика Татарстан" className="form-check-input region_btn" onClick={onSetRegionInput} />16 Республика Татарстан<br />
                                            <input type="checkbox" value="Республика Тыва" className="form-check-input region_btn" onClick={onSetRegionInput} />17 Республика Тыва<br />
                                            <input type="checkbox" value="Удмуртская Республика" className="form-check-input region_btn" onClick={onSetRegionInput} />18 Удмуртская Республика<br />
                                            <input type="checkbox" value="Республика Хакасия" className="form-check-input region_btn" onClick={onSetRegionInput} />19 Республика Хакасия<br />
                                            <input type="checkbox" value="Чеченская Республика" className="form-check-input region_btn" onClick={onSetRegionInput} />20 Чеченская Республика<br />
                                            <input type="checkbox" value="Ямало-Ненецкий автономный округ" className="form-check-input region_btn" onClick={onSetRegionInput} />89 Ямало-Ненецкий автономный округ<br />
                                            <input type="checkbox" value="Запорожская область" className="form-check-input region_btn" onClick={onSetRegionInput} />90 Запорожская область<br />
                                        </div>
                                        <div className="col-md-3">
                                            <input type="checkbox" value="Чувашская Республика" className="form-check-input region_btn" onClick={onSetRegionInput} />21 Чувашская Республика<br />
                                            <input type="checkbox" value="Алтайский край" className="form-check-input region_btn" onClick={onSetRegionInput} />22 Алтайский край<br />
                                            <input type="checkbox" value="Краснодарский край" className="form-check-input region_btn" onClick={onSetRegionInput} />23 Краснодарский край<br />
                                            <input type="checkbox" value="Красноярский край" className="form-check-input region_btn" onClick={onSetRegionInput} />24 Красноярский край<br />
                                            <input type="checkbox" value="Приморский край" className="form-check-input region_btn" onClick={onSetRegionInput} />25 Приморский край<br />
                                            <input type="checkbox" value="Ставропольский край" className="form-check-input region_btn" onClick={onSetRegionInput} />26 Ставропольский край<br />
                                            <input type="checkbox" value="Хабаровский край" className="form-check-input region_btn" onClick={onSetRegionInput} />27 Хабаровский край<br />
                                            <input type="checkbox" value="Амурская область" className="form-check-input region_btn" onClick={onSetRegionInput} />28 Амурская область<br />
                                            <input type="checkbox" value="Архангельская область" className="form-check-input region_btn" onClick={onSetRegionInput} />29 Архангельская область<br />
                                            <input type="checkbox" value="Астраханская область" className="form-check-input region_btn" onClick={onSetRegionInput} />30 Астраханская область<br />
                                            <input type="checkbox" value="Белгородская область" className="form-check-input region_btn" onClick={onSetRegionInput} />31 Белгородская область<br />
                                            <input type="checkbox" value="Брянская область" className="form-check-input region_btn" onClick={onSetRegionInput} />32 Брянская область<br />
                                            <input type="checkbox" value="Владимирская область" className="form-check-input region_btn" onClick={onSetRegionInput} />33 Владимирская область<br />
                                            <input type="checkbox" value="Волгоградская область" className="form-check-input region_btn" onClick={onSetRegionInput} />34 Волгоградская область<br />
                                            <input type="checkbox" value="Вологодская область" className="form-check-input region_btn" onClick={onSetRegionInput} />35 Вологодская область<br />
                                            <input type="checkbox" value="Воронежская область" className="form-check-input region_btn" onClick={onSetRegionInput} />36 Воронежская область<br />
                                            <input type="checkbox" value="Ивановская область" className="form-check-input region_btn" onClick={onSetRegionInput} />37 Ивановская область<br />
                                            <input type="checkbox" value="Иркутская область" className="form-check-input region_btn" onClick={onSetRegionInput} />38 Иркутская область<br />
                                            <input type="checkbox" value="Калининградская область" className="form-check-input region_btn" onClick={onSetRegionInput} />39 Калининградская область<br />
                                            <input type="checkbox" value="Калужская область" className="form-check-input region_btn" onClick={onSetRegionInput} />40 Калужская область<br />
                                            <input type="checkbox" value="Донецкая Народная Республика" className="form-check-input region_btn" onClick={onSetRegionInput} />93 Донецкая Народная Республика<br />
                                            <input type="checkbox" value="Луганская Народная Республика" className="form-check-input region_btn" onClick={onSetRegionInput} />94 Луганская Народная Республика<br />
                                        </div>
                                        <div className="col-md-3">
                                            <input type="checkbox" value="Камчатский край" className="form-check-input region_btn" onClick={onSetRegionInput} />41 Камчатский край<br />
                                            <input type="checkbox" value="Кемеровская область" className="form-check-input region_btn" onClick={onSetRegionInput} />42 Кемеровская область<br />
                                            <input type="checkbox" value="Кировская область" className="form-check-input region_btn" onClick={onSetRegionInput} />43 Кировская область<br />
                                            <input type="checkbox" value="Костромская область" className="form-check-input region_btn" onClick={onSetRegionInput} />44 Костромская область<br />
                                            <input type="checkbox" value="Курганская область" className="form-check-input region_btn" onClick={onSetRegionInput} />45 Курганская область<br />
                                            <input type="checkbox" value="Курская область" className="form-check-input region_btn" onClick={onSetRegionInput} />46 Курская область<br />
                                            <input type="checkbox" value="Ленинградская область" className="form-check-input region_btn" onClick={onSetRegionInput} />47 Ленинградская область<br />
                                            <input type="checkbox" value="Липецкая область" className="form-check-input region_btn" onClick={onSetRegionInput} />48 Липецкая область<br />
                                            <input type="checkbox" value="Магаданская область" className="form-check-input region_btn" onClick={onSetRegionInput} />49 Магаданская область<br />
                                            <input type="checkbox" value="Московская область" className="form-check-input region_btn" onClick={onSetRegionInput} />50 Московская область<br />
                                            <input type="checkbox" value="Мурманская область" className="form-check-input region_btn" onClick={onSetRegionInput} />51 Мурманская область<br />
                                            <input type="checkbox" value="Нижегородская область" className="form-check-input region_btn" onClick={onSetRegionInput} />52 Нижегородская область<br />
                                            <input type="checkbox" value="Новгородская область" className="form-check-input region_btn" onClick={onSetRegionInput} />53 Новгородская область<br />
                                            <input type="checkbox" value="Новосибирская область" className="form-check-input region_btn" onClick={onSetRegionInput} />54 Новосибирская область<br />
                                            <input type="checkbox" value="Омская область" className="form-check-input region_btn" onClick={onSetRegionInput} />55 Омская область<br />
                                            <input type="checkbox" value="Оренбургская область" className="form-check-input region_btn" onClick={onSetRegionInput} />56 Оренбургская область<br />
                                            <input type="checkbox" value="Орловская область" className="form-check-input region_btn" onClick={onSetRegionInput} />57 Орловская область<br />
                                            <input type="checkbox" value="Пензенская область" className="form-check-input region_btn" onClick={onSetRegionInput} />58 Пензенская область<br />
                                            <input type="checkbox" value="Пермский край" className="form-check-input region_btn" onClick={onSetRegionInput} />59 Пермский край<br />
                                            <input type="checkbox" value="Псковская область" className="form-check-input region_btn" onClick={onSetRegionInput} />60 Псковская область<br />
                                            <input type="checkbox" value="Республика Крым" className="form-check-input region_btn" onClick={onSetRegionInput} />91 Республика Крым<br />
                                            <input type="checkbox" value="Херсонская область" className="form-check-input region_btn" onClick={onSetRegionInput} />95 Херсонская область<br />
                                        </div>
                                        <div className="col-md-3">
                                            <input type="checkbox" value="Ростовская область" className="form-check-input region_btn" onClick={onSetRegionInput} />61 Ростовская область<br />
                                            <input type="checkbox" value="Рязанская область" className="form-check-input region_btn" onClick={onSetRegionInput} />62 Рязанская область<br />
                                            <input type="checkbox" value="Самарская область" className="form-check-input region_btn" onClick={onSetRegionInput} />63 Самарская область<br />
                                            <input type="checkbox" value="Саратовская область" className="form-check-input region_btn" onClick={onSetRegionInput} />64 Саратовская область<br />
                                            <input type="checkbox" value="Сахалинская область" className="form-check-input region_btn" onClick={onSetRegionInput} />65 Сахалинская область<br />
                                            <input type="checkbox" value="Свердловская область" className="form-check-input region_btn" onClick={onSetRegionInput} />66 Свердловская область<br />
                                            <input type="checkbox" value="Смоленская область" className="form-check-input region_btn" onClick={onSetRegionInput} />67 Смоленская область<br />
                                            <input type="checkbox" value="Тамбовская область" className="form-check-input region_btn" onClick={onSetRegionInput} />68 Тамбовская область<br />
                                            <input type="checkbox" value="Тверская область" className="form-check-input region_btn" onClick={onSetRegionInput} />69 Тверская область<br />
                                            <input type="checkbox" value="Томская область" className="form-check-input region_btn" onClick={onSetRegionInput} />70 Томская область<br />
                                            <input type="checkbox" value="Тульская область" className="form-check-input region_btn" onClick={onSetRegionInput} />71 Тульская область<br />
                                            <input type="checkbox" value="Тюменская область" className="form-check-input region_btn" onClick={onSetRegionInput} />72 Тюменская область<br />
                                            <input type="checkbox" value="Ульяновская область" className="form-check-input region_btn" onClick={onSetRegionInput} />73 Ульяновская область<br />
                                            <input type="checkbox" value="Челябинская область" className="form-check-input region_btn" onClick={onSetRegionInput} />74 Челябинская область<br />
                                            <input type="checkbox" value="Забайкальский край" className="form-check-input region_btn" onClick={onSetRegionInput} />75 Забайкальский край<br />
                                            <input type="checkbox" value="Ярославская область" className="form-check-input region_btn" onClick={onSetRegionInput} />76 Ярославская область<br />
                                            <input type="checkbox" value="Еврейская автономная область" className="form-check-input region_btn" onClick={onSetRegionInput} />79 Еврейская автономная область<br />
                                            <input type="checkbox" value="Ненецкий автономный округ" className="form-check-input region_btn" onClick={onSetRegionInput} />83 Ненецкий автономный округ<br />
                                            <input type="checkbox" value="Ханты-Мансийский автономный округ" className="form-check-input region_btn" onClick={onSetRegionInput} />86 Ханты-Мансийский автономный округ<br />
                                            <input type="checkbox" value="Чукотский автономный округ" className="form-check-input region_btn" onClick={onSetRegionInput} />87 Чукотский автономный округ<br />
                                        </div>
                                    </div>
                                </div>
                                <label htmlFor="validationCustom01" className="form-label mt-3">Города</label>
                                <input type="text" className="form-control mt-1" id="cityInput" placeholder="Введите названия городов через запятую" onChange={setGeoControlValue} />
                                <div className="alert alert-danger d-none mt-3">

                                </div>
                                <div className="alert alert-success mt-2 d-none">

                                </div>
                                <div className="mt-3">
                                    <input className="btn btn-success w-100" type="submit" value="Добавить фильтр" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="mt-1">
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                            {
                                errorUserFilter
                                    ? <div className="mt-3 alert alert-danger"><b>{errorUserFilter}</b></div>
                                    : false
                            }

                            {
                                userFilters
                                    ? <table className="table table-striped table-hover mt-3">
                                        <thead>
                                        <tr>
                                            <th className="text-center" scope="col"></th>
                                            <th className="text-center" scope="col">Название</th>
                                            <th className="text-center" scope="col">Дата выпуска</th>
                                            <th className="text-center" scope="col">Тип трансмиссии</th>
                                            <th className="text-center" scope="col">Привод</th>
                                            <th className="text-center" scope="col">Руль</th>
                                            <th className="text-center" scope="col">Обьем двигателя</th>
                                            <th className="text-center" scope="col">Тип двигателя</th>
                                            <th className="text-center" scope="col">Мощность двигателя</th>
                                            <th className="text-center" scope="col">Адрес</th>
                                            <th className="text-center" scope="col">Сумма от</th>
                                            <th className="text-center" scope="col">Сумма до</th>
                                            <th className="text-center" scope="col">Действия</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            Object.keys(userFilters).map((keyName, i) => {
                                                return <tr key={i}>
                                                    <td className="text-center">
                                                        {
                                                            userFilters[keyName]['active']
                                                                ? <i className="bi bi-bookmark-check-fill text-success"></i>
                                                                : <i className="bi bi-bookmark-x-fill text-danger"></i>
                                                        }
                                                    </td>
                                                    <td>{userFilters[keyName]['name']} </td>
                                                    <td className="text-center">{userFilters[keyName]['yearCreate']} </td>
                                                    <td className="text-center">{userFilters[keyName]['transmissionType']} </td>
                                                    <td className="text-center">{userFilters[keyName]['privod']} </td>
                                                    <td className="text-center">{userFilters[keyName]['steeringType']} </td>
                                                    <td className="text-center">{userFilters[keyName]['volumeMotor']} </td>
                                                    <td className="text-center">{userFilters[keyName]['typeMotor']} </td>
                                                    <td className="text-center">{userFilters[keyName]['strongMotor']} </td>
                                                    <td className="text-center">{userFilters[keyName]['geocontrol']} </td>
                                                    <td className="text-center">{userFilters[keyName]['price_start']} </td>
                                                    <td className="text-center">{userFilters[keyName]['price_end']} </td>
                                                    <td className="text-center">
                                                        <button className="dropdown-toggle btn btn-primary" role="button" data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                            <span className="p-1">Действия</span>
                                                        </button>

                                                        <ul className="dropdown-menu p-2">
                                                            <li>
                                                                <button onClick={(event) => setEditFilterF(userFilters[i])} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-pencil-fill"></i> Редактировать</button>
                                                            </li>
                                                            <li>
                                                                {
                                                                    userFilters[keyName]['active']
                                                                        ? <button onClick={(event) => EnableSettingFilterEdit(userFilters[keyName]['id'], 1)} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-bookmark-x-fill"></i> Сделать неактивным</button>
                                                                        : <button onClick={(event) => EnableSettingFilterEdit(userFilters[keyName]['id'], 2)} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-bookmark-check-fill"></i> Сделать активным</button>
                                                                }
                                                            </li>
                                                            <li>
                                                                <button onClick={(event) => deleteSettingFilterEdit(userFilters[keyName]['id'])} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-trash3-fill"></i> Удалить</button>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    : !errorUserFilter
                                        ? <LoadingIcon />
                                        : false
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <span className="badge badge-success ml-2">Добавленные меньше 1 часа назад</span>
                <span className="badge badge-warning ml-2">Добавленные от 1-го до 2-х часов назад</span>
                <span className="badge badge-danger ml-2">Добавленные больше 2-x часа назад</span>
            </div>
            {
                props.appData['userData']['agregator_tarif_type'] === "Free"
                    ? <div className="alert alert-info mt-3">
                            Количество оставшихся просмотров на сегодня: <b>{props.appData['userData']['count_last_access_view']}</b>
                        </div>
                    : false
            }
            {
                props.appData['userData']['agregator_tarif_type'] === "Start"
                    ? <div className="alert alert-info mt-3">
                        Осталось дней по подписке: <b>{props.appData['userData']['count_day_tarif_end']}</b>
                        <br />Количество оставшихся просмотров по тарифному плану: <b>{props.appData['userData']['count_last_access_view']}</b>
                    </div>
                    : false
            }
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTelegrammInfo" aria-expanded="true" aria-controls="collapseOne">
                            Как подключить уведомления в Телеграмм ?
                        </button>
                    </h2>
                    <div id="collapseTelegrammInfo" className="accordion-collapse collapse" aria-labelledby="headingOne"
                         data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong>Инструкция по подключению уведомлений о новых объявлениях в Телеграмм</strong>
                            <ol className="list-group list-group-numbered">
                                <li className="list-group-item">Оформите подписку на тарифы <b>Старт</b> или <b>Бизнес</b></li>
                                <li className="list-group-item">Перейдите в телеграмм и найдите бота <b>@KubanAvto_bot</b>
                                <br /> <img src={screen_1} alt="" width="512" /> </li>
                                <li className="list-group-item">Нажмите кнопку <b>Старт</b>
                                    <br /> <img src={screen_2} alt="" width="512" /></li>
                                <li className="list-group-item">Следуйте инструкциям бота</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion mt-3" id="accordionExample2">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne2">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTelegrammInfo2" aria-expanded="true" aria-controls="collapseOne2">
                            Предложить группу Вконтакте, для добавления в список источников обьявлений.
                        </button>
                    </h2>
                    <div id="collapseTelegrammInfo2" className="accordion-collapse collapse" aria-labelledby="headingOne2"
                         data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <form action="addGroupVK" id="addGroupVKForm" method="GET" noValidate onSubmit={(event) => SendForm(event, (data) => {

                            }, true, true)}>

                                <div>
                                    <label className="form-label">Url</label>
                                    <input name="url_group" placeholder="Введите url (адрес страницы) группы, сообщестра или пользователя Вконтакте" type="text" className="form-control" />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>

                                <div className="alert alert-danger d-none mt-3">

                                </div>
                                <div className="alert alert-success mt-2 d-none">

                                </div>
                                <div className="mt-3">
                                    <input className="btn btn-success w-100" type="submit" value="Добавить группу" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {
                errorMessage
                    ? <div className="mt-3 alert alert-danger"><b>{errorMessage}</b></div>
                    : false
            }
            <div className="mt-3">
                {
                    allAds.length
                        ? <table className="table table-striped table-hover mt-3">
                            <thead>
                            <tr>
                                <th className="text-center" scope="col">#</th>
                                <th className="text-center" scope="col">Название</th>
                                <th className="text-center" scope="col">Цена</th>
                                <th className="text-center" scope="col">Год выпуска</th>
                                <th className="text-center" scope="col">Тип трансмиссии</th>
                                <th className="text-center" scope="col">Привод</th>
                                <th className="text-center" scope="col">Состояние</th>
                                <th className="text-center" scope="col">Кол. изображения</th>
                                <th className="text-center" scope="col">Адрес</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(allAds).map((keyName, i) => {
                                    let type = ""

                                    if (allAds[keyName]['add_lost_hourse'] <= 1)
                                        type = "bg-success";
                                    else if (allAds[keyName]['add_lost_hourse'] > 1 && allAds[keyName]['add_lost_hourse'] <= 2)
                                        type = "bg-warning";
                                    else if (allAds[keyName]['add_lost_hourse'] > 2)
                                        type = "bg-danger";

                                    return <tr onClick={(event) => openDopInfoAdsAgregator(allAds[keyName]['id'])} key={i} style={{cursor: "pointer"}}>
                                        <td className={"text-white text-center " + type}>{i+1}</td>
                                        <td>
                                            {allAds[keyName]['name'] + " "}
                                            {
                                                allAds[keyName]['new']
                                                    ? <div className="badge badge-success">новое</div>
                                                    : false
                                            }
                                        </td>
                                        <td className="text-center">{
                                            allAds[keyName]['price']
                                                ? allAds[keyName]['price'] + " руб. "
                                                : false
                                        }</td>
                                        <td className="text-center" scope="col">{allAds[keyName]['YearCreate']}</td>
                                        <td className="text-center" scope="col">{allAds[keyName]['transmissionType']}</td>
                                        <td className="text-center" scope="col">{allAds[keyName]['privod']}</td>
                                        <td className="text-center" scope="col">{
                                            allAds[keyName]['status'] === "Битый"
                                                ? <b className="text-danger">{allAds[keyName]['status']}</b>
                                                : <b className="text-success">{allAds[keyName]['status']}</b>
                                        }</td>
                                        <td className="text-center">{allAds[keyName]['count_img']} шт.</td>
                                        <td>{allAds[keyName]['geolocation'].split(",")[0]}</td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                        : !errorMessage
                            ? <LoadingIcon />
                            : false
                }
            </div>
            <div id="endPageContainer">
                {
                    allAds.length >= 50
                        ? !loadNextStepAds
                            ? <button onClick={(event) => loadNextAdsSpisok()} className="btn btn-success w-100">
                                <b>
                                    Загрузить ещё...
                                </b>
                                </button>
                            : <LoadingIcon />
                        : false
                }
            </div>

            {
                (dopInfoAds)
                    ? <Modal size="lg" show={dopInfoAds} onHide={(event) => setDopInfo(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Вся информация об обьявлении
                                <br /> {dopInfoData['name']}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-7">
                                    {
                                        dopInfoData['site'] !== "avito"
                                            ? <div>
                                                <img src={dopInfoData['image'].split("|")[0]} className="img-thumbnail w-100 main_image" alt="" />
                                                <div onClick={(event) => setViewImage(true)} className="btn btn-sm btn-primary align-middle text-center d-inline-block position-relative w-100" style={{borderRadius: 0}}>
                                                    <i className="bi bi-tv-fill"> </i>
                                                </div>
                                                <br />
                                                {
                                                    dopInfoData['image'].split("|").map(elem => {
                                                        return <img style={{cursor: "pointer"}} onClick={(event) => setMainImg(elem)} src={elem} className="img-thumbnail" alt="" width="100" />
                                                    })
                                                }
                                                {
                                                    (viewImage)
                                                        ? <Modal size={"xl"} show={viewImage} onHide={(event) => setViewImage(false)}>
                                                            <div id="carouselExampleIndicators" className="carousel slide carousel-fade bg-dark"
                                                                 data-bs-ride="carousel">
                                                                <div className="carousel-inner">
                                                                    {
                                                                        dopInfoData['image'].split("|").map((keyName, i) => {
                                                                            return <div className={(i === 0) ? "carousel-item active" : "carousel-item"}>
                                                                                <img width="100%" src={dopInfoData['image'].split("|")[i]} key={keyName} alt="..." />
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                                <button className="carousel-control-prev" type="button"
                                                                        data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                    <span className="visually-hidden">Previous</span>
                                                                </button>
                                                                <button className="carousel-control-next" type="button"
                                                                        data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                    <span className="visually-hidden">Next</span>
                                                                </button>
                                                            </div>
                                                        </Modal>
                                                        : false
                                                }
                                        </div>
                                            : dopInfoData['image'].split("|").map(elem => {
                                                return <img style={{cursor: "pointer"}} onClick={(event) => setMainImg(elem)} src={elem} className="img-thumbnail" alt="" width="100" />
                                            })
                                    }

                                </div>
                                <div className="col-md-5">
                                    {
                                        dopInfoData['params']
                                            ? <h6>Доп. информация</h6>
                                            : false
                                    }
                                    {
                                        dopInfoData['params'].split("|").map((elem, i) => {
                                            return <div key={i}>
                                                <span>{elem}</span>
                                            </div>
                                        })
                                    }
                                    <div className="mt-3">
                                        {
                                            dopInfoData['price']
                                                ? <h6>Цена</h6>
                                                : false
                                        }
                                        <h2>
                                            {
                                                dopInfoData['price']
                                                    ? dopInfoData['price'] + " руб."
                                                    : false
                                            }
                                        </h2>
                                    </div>
                                    <div className="mt-3">
                                        <button onClick={(event) => openAdsFromSite(dopInfoData['id'])} className="btn btn-success w-100">Перейти к обьявлению</button>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h6>Описание</h6>
                                <div className="text-justify">
                                    {
                                        dopInfoData['description']
                                    }
                                </div>
                            </div>
                            <div className="alert alert-danger d-none mt-3">

                            </div>
                        </Modal.Body>
                    </Modal>
                    : false
            }

            {
                (editSettingFilter)
                    ? <Modal size="lg" fullscreen={true} show={editSettingFilter} onHide={(event) => setEditSettingFilter(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Изменение настроек фильтра
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EditFilterForm selectAllRegion={selectAllRegion} onSetRegionInput={onSetRegionInput} dataEditFilter={dataEditFilter} carMarka={carMarka} setAdsAvtoMarka={setAdsAvtoMarka} />
                        </Modal.Body>
                    </Modal>
                    : false
            }
        </div>
    );
};

export default PremiumSearch;
