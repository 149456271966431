import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import apiRequest from "../../Utility/ApiRequest";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import TableAdsFavorite from "../../components/LikesPage/TableAdsFavorite";

const LikesPage = () => {

    const [allAds, setAllAds] = useState(false);
    const [viewAds, setWiewAds] = useState(false);
    const [errorFavorite, setErrorFavorite] = useState(false);
    const [loadAds, setLoadAds] = useState(false);

    useEffect(() => {
        setErrorFavorite(false);
        setLoadAds(false);
        apiRequest("getMyFavorite?").then((data) => {
            if (data['success']){
                setErrorFavorite("");
                setAllAds(data['ads']);
                setWiewAds(data['ads']);
            } else if(data['error']){
                setErrorFavorite(data['error']);
                setAllAds(false);
                setWiewAds(false);
            }
            setLoadAds(true);
        });
    }, []);

    const delAds = (ads_id) => {
        Object.keys(allAds).map((keyName, i) => {
            if (parseInt(allAds[keyName]['id']) === parseInt(ads_id)){
                delete allAds[keyName];
                setAllAds(allAds);
                return true;
            }
            return false;
        });

        Object.keys(viewAds).map((keyName, i) => {
            if (parseInt(viewAds[keyName]['id']) === parseInt(ads_id)){
                delete viewAds[keyName];
                setWiewAds(viewAds);
                return true;
            }
            return false;
        });
    }

    const filterAdsCategory = (val) => {
        if (val !== ""){
            let result = []
            allAds.map((ads) => {
                let issetCat = false
                ads['categories'].map((category) => {
                    const cat_name = category['name'].toLowerCase();
                    if(cat_name.includes(val.toLowerCase()))
                        issetCat = true;
                    return true;
                });
                if (issetCat)
                    result.push(ads);
                return true;
            });

            if(!result.length)
                allAds.map((ads) => {
                    let typeAds = ads['typeAds'];
                    let name = "";
                    if (typeAds === "Car")
                        name = ads['avtoMarka']['name'].toLowerCase() + " " + ads['avtoModel'].toLowerCase();
                    else
                        name = ads['name'].toLowerCase();

                    if(name.includes(val.toLowerCase()))
                        result.push(ads);
                    return true;
                });

            setWiewAds(result);
        } else
            setWiewAds(allAds);
    }

    return (
        <div>
            <PageHeader title="Избранное"/>
            {
                (errorFavorite)
                    ? <div className="alert alert-danger">{errorFavorite}</div>
                    : (loadAds)
                        ? (!viewAds)
                            ? <div className="alert alert-danger">Извините, у Вас нет избранных обьявлений.</div>
                            : false
                        : false
            }
            {
                (viewAds)
                    ? <div>
                        <div className="input-group mt-2">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="bi bi-search"></i>
                                </span>
                            <input onChange={(event) => filterAdsCategory(event.target.value)} className="form-control" placeholder="Введите парметры поиска. Например: Авто" defaultValue="" />
                        </div>
                        <TableAdsFavorite delAds={delAds} Ads={viewAds} />
                    </div>
                    : (!loadAds)
                        ? <LoadingIcon />
                        : false
            }
        </div>
    );
};

export default LikesPage;
