import {Link} from "react-router-dom";
import PageHeader from "../../components/PageHeader/PageHeader";
import SendForm from "../../Utility/SendForm";
import {goAccount} from "../../Utility/Policy";
import React, {useState} from "react";
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import $ from "jquery";
import PasswordInput from "../../components/PaswordInput/PasswordInput";

const RegistrationPage = () => {

    const [isOrganization, setIsOrganization] = useState(false);
    const [address, setAddress] = useState("");

    const showMessageRegSuccess = (data = null) => {
        goAccount(data);
    }

    const getGeolocation = (event) => {
        setAddress(event.value);
        const coords = event.data.geo_lat + "," + event.data.geo_lon;
        $("#geocontrolInput").attr("value", coords);
    }

    return (
        <div>
            <PageHeader title="Регистрация" />
            <form action="registration" id="FormRegistration" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, showMessageRegSuccess, true)}>
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="validationCustom01" className="form-label">Логин</label>
                        <input name="login" id="validationCustom01" type="text" className="form-control" placeholder="Введите логин" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label">Пароль</label>
                        <PasswordInput name="password" id="validationCustom02" placeholder="Введите пароль, не мение 8 символов" />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom03" className="form-label">Повторите пароль</label>
                        <PasswordInput name="repeatPassword" id="validationCustom03" placeholder="Повторите пароль" />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom04" className="form-label">Адрес электронной почты</label>
                        <input name="mail" type="text" id="validationCustom04" className="form-control" placeholder="Введите адрес электронной почты" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom05" className="form-label">Номер телефона</label>
                        <input name="phone" type="text" id="validationCustom05" className="form-control" placeholder="Введите номер телефона: +7 918 1234567" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4">
                        <label htmlFor="validationCustom06" className="form-label">Фамилия</label>
                        <input name="lastName" type="text" id="validationCustom06" className="form-control" placeholder="Введите фамилию" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom07" className="form-label">Имя</label>
                        <input name="Name" type="text" id="validationCustom07" className="form-control" placeholder="Введите имя" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom08" className="form-label">Отчество</label>
                        <input name="middleName" type="text"id="validationCustom08" className="form-control" placeholder="Отчество" />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col">
                        <div className="custom-control custom-checkbox mr-sm-2">
                            <input onChange={(event) => setIsOrganization(event.target.checked)} name="isOrganization" type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                                <label className="custom-control-label" htmlFor="customControlAutosizing"> Являюсь представителем Организации или ИП</label>
                        </div>
                    </div>
                </div>

                {
                    (isOrganization)
                    ? <div className="row mt-3">
                            <div className="col-md-4">
                                <label htmlFor="validationCustom06" className="form-label">Наименование организации</label>
                                <input name="organizationName" type="text" id="validationCustom06" className="form-control" placeholder="Введите наименование организации" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="validationCustom07" className="form-label">Адрес организации</label>
                                <AddressSuggestions token="6cc4b26d810fcf2537bcd2ba90f37b926acf6558" value={address} onChange={(event) => getGeolocation(event)} />
                                <input name="organizationAddress" type="text" id="validationCustom07" value={address} className="form-control d-none" placeholder="Введите адрес организации" required />
                                <input name="geocontrol" type="text" className="form-control mt-1 d-none" id="geocontrolInput" />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="validationCustom08" className="form-label">Номер телефона организации</label>
                                <input name="organizationPhone" type="text"id="validationCustom08" className="form-control" placeholder="Введите номер телефона организации" />
                            </div>
                        </div>
                        : false
                }

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-2 d-none">

                </div>
                <input type="submit" value="Зарегистрироваться" className="btn btn-success mt-3" />
                <div className="m-3 d-inline-block align-middle">
                    <Link to="/privaty">Политика конфиденциальности</Link>
                </div>
            </form>
        </div>
    );
};

export default RegistrationPage;
