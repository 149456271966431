import React from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import Gruz from '../../images/gruz.jpg';

const TransportationPage = (props) => {
    return (
        <div>
            <PageHeader title="Грузоперевозки" />
            <div className="row">
                <div className="col-md-6">
                    <img src={Gruz} width="100%" alt="" />
                </div>
                <div className="col-md-6">
                    <h4>Основная информация</h4>
                    <hr />
                    Стоимость километра пути: <b>45 руб.</b>
                    <br /> Грузоподъёмность: <b>2 т.  (далее по договоренности)</b>
                    <br /> Работаем по <b>Краснодарскому краю , по ЮФО, по России</b>
                    <br /> График работы: <b>круглосуточно</b>
                    <br /> Телефон: <b><a className="_bold" href={"tel:" + props.appData['settings']['phone_gruzoperevozka_number']}>{props.appData['settings']['phone_gruzoperevozka']}</a></b>
                </div>
            </div>
        </div>
    );
};

export default TransportationPage;
