import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import TemplateAdminPanel from "../../components/AdminPanel/TemplatePage/TemplateAdminPanel";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import apiRequest from "../../Utility/ApiRequest";
import TableUsers from "../../components/AdminPanel/UsersPage/TableUsers";
import Modal from "react-bootstrap/Modal";
import SendForm from "../../Utility/SendForm";
import {Link} from "react-router-dom";
import GlobalParam from "../../Utility/AppGlobalParam";
import InputCategory from "../../components/AddAdsPage/InputCategory";
import $ from "jquery";
import {toast} from "react-toastify";

const AdminUserPage = (props) => {

    const [viewDataUser, setViewDataUser] = useState(false);
    const [allUsers, setAllUsers] = useState(false);
    const [viewUsers, setViewUsers] = useState(false);
    const [notUser, setNotUser] = useState(false);
    const [userVievDataId, setUserVievDataId] = useState(false);
    const [userAdsAccessCount, setUserAdsAccessCount] = useState(false);
    const [disableCategoryUser, setDisableCategoryUser] = useState(false);
    const [category, setCategory] = useState(false);
    const [premiumSearch, setPremiumSearch] = useState(false);

    useEffect(() => {
        setCategory(props.appData['category']);
        apiRequest("getAllUser?","").then((data) => {
           if (data['success'])
           {
               setAllUsers(data['users']);
               setViewUsers(data['users']);
           } else if (data['error'])
               setNotUser(data['error']);
        });
    }, [setAllUsers, setViewUsers, setCategory, props.appData]);

    const setViewDataUserF = (id) => {
        Object.keys(viewUsers).map((keyName, i) => {
           if (viewUsers[i]['id'] === id)
               setUserVievDataId(i);
           return true;
        });
        setViewDataUser(true);
    }
    const setUserAdsAccessCountF = (id) => {
        Object.keys(viewUsers).map((keyName, i) => {
            if (viewUsers[i]['id'] === id)
                setUserVievDataId(i);
            return true;
        });
        setUserAdsAccessCount(true);
    }
    const setDisableCategoryUserF = (id) => {
        Object.keys(viewUsers).map((keyName, i) => {
            if (viewUsers[i]['id'] === id)
                setUserVievDataId(i);
            return true;
        });
        setDisableCategoryUser(true);
    }
    const setPremiumSearchF = (id) => {
        Object.keys(viewUsers).map((keyName, i) => {
            if (viewUsers[i]['id'] === id)
                setUserVievDataId(i);
            return true;
        });
        setPremiumSearch(true);
    }

    const successEditAccessCountAds = (data) => {
        if(data['success']){
            setTimeout(setUserAdsAccessCount, 1000, false);
        }
    }
    const filterPerson = (fio) => {
        if (fio !== ""){
            let result = []
            allUsers.map((person) => {
                const fio_ = person['fio'].toLowerCase();
                if(fio_.includes(fio.toLowerCase()))
                    result.push(person);
                return true;
            });
            setViewUsers(result);
        } else
            setViewUsers(allUsers);
    }

    const CheckedCategoryAllChildren = (id, type) => {
        $("input[parent_id='" + id + "']").each(function () {
            $(this).prop("checked", type);
            CheckedCategoryAllChildren($(this).attr("value"), type);
        });
    }
    const setFormAddAdsTrue = (type, event) => {
        if (event.target.checked) {
            CheckedCategoryAllChildren(event.target.value, true);
        } else {
            CheckedCategoryAllChildren(event.target.value, false);

            if($("input[id='category_" + event.target.getAttribute('parent_id') + "']").prop("checked"))
                $("input[id='category_" + event.target.value + "']").prop("checked", true);
        }

        let categoryValue = "";
        $("input[type='checkbox']:checked").each(function () {
            if ($(this).hasClass("input_option_category"))
                categoryValue += "/" + $(this).val() + "/";
        });

        $("#categoryInput").prop("value", categoryValue);
    }
    const sendDateDisableCategory = (event) => {
        event.preventDefault();

        SendForm(event, (data) => {
            if (data['success'])
                viewUsers[userVievDataId]['disable_category'] = data['disabledCategory'];
        }, true, false);
    }
    const setModeratorType = (user_id, type) => {
        apiRequest("moderatorEdit?", "user_id=" + user_id + "&type=" + type).then((data) => {
            if (data['success']){
                Object.keys(viewUsers).map((keyName, i) => {
                    if (viewUsers[i]['id'] === user_id)
                        viewUsers[i] = data['userData'];
                    return true;
                });
                setViewUsers(viewUsers);

                if (data['userData']['role'] === "admin")
                    $("#role_user_" + user_id).empty().append("Администратор")
                else if (data['userData']['moderator'])
                    $("#role_user_" + user_id).empty().append("Модератор")
                else
                    $("#role_user_" + user_id).empty().append("Пользователь")

                toast.success(data['success']);

            } else
                toast.error(data['error']);
        });
    }

    const setDetourModeratorAds = (user_id, type) => {
        apiRequest("detourModeratorAdsEdit?", "user_id=" + user_id + "&type=" + type).then((data) => {
            if (data['success']){
                Object.keys(viewUsers).map((keyName, i) => {
                    if (viewUsers[i]['id'] === user_id)
                        viewUsers[i] = data['userData'];
                    return true;
                });
                setViewUsers(viewUsers);
                toast.success(data['success']);
            } else
                toast.error(data['error']);
        });
    }

    return (
        <div>
            <PageHeader title="Административная панель" />
            <TemplateAdminPanel appData={props.appData} id_activ={1}>
                <PageHeader title="Пользователи сайта" noHr={true} />
                {
                    (viewUsers)
                    ?   <div>
                            <input onChange={(event) => filterPerson(event.target.value)} className="form-control" placeholder="ФИО" defaultValue="" />
                            <TableUsers setDetourModeratorAds={setDetourModeratorAds} setModeratorType={setModeratorType} setPremiumSearchF={setPremiumSearchF} setDisableCategoryUserF={setDisableCategoryUserF} setViewDataUserF={setViewDataUserF} setUserAdsAccessCountF={setUserAdsAccessCountF} users={viewUsers} />
                        </div>
                        : (notUser)
                            ? <div className="alert alert-danger">{notUser}</div>
                            : <LoadingIcon />
                }
            </TemplateAdminPanel>

            {
                (viewDataUser)
                    ? <Modal size={"lg"} show={viewDataUser} onHide={(event) => setViewDataUser(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Все данные пользователя
                                <br/>{viewUsers[userVievDataId]['fio']}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <b>Данные пользователя</b>
                                <hr />
                                <table className="table table-striped mt-3">
                                    <tbody>
                                    <tr><td>Логин</td><td>{viewUsers[userVievDataId]['login']}</td></tr>
                                    <tr><td>ФИО</td><td>{viewUsers[userVievDataId]['fio']}</td></tr>
                                    <tr><td>Номер телефона</td><td>{viewUsers[userVievDataId]['phone']}</td></tr>
                                    <tr><td>Адрес электронной почти</td><td>{viewUsers[userVievDataId]['mail']}</td></tr>
                                    <tr><td>Активирован ли аккаунт ?</td><td>
                                        {
                                            (viewUsers[userVievDataId]['isActivate'])
                                            ? <b>Да</b>
                                                : <b>Нет</b>
                                        }
                                    </td></tr>
                                    <tr><td>Ограничение по обьявлениям</td><td>
                                        {
                                            viewUsers[userVievDataId]['role'] === "admin" || viewUsers[userVievDataId]['moderator'] === 1
                                                ? <b>Без ограничений</b>
                                                : <div>
                                                    разрешено {viewUsers[userVievDataId]['adsAccessCount']},
                                                    осталось {
                                                    parseInt(viewUsers[userVievDataId]['adsAccessCount']) - parseInt(viewUsers[userVievDataId]['countAds'])
                                                }
                                                </div>
                                        }

                                    </td></tr>
                                    <tr><td>Обход модерации</td><td>
                                        {
                                            (viewUsers[userVievDataId]['detour_moderator_ads'])
                                                ? <b>Разрешен</b>
                                                : <b>Запрещен</b>
                                        }
                                    </td></tr>
                                    <tr><td>Дата регистрации</td><td>{viewUsers[userVievDataId]['date_registr']}</td></tr>
                                    <tr><td>Дата последнего входа</td><td>{viewUsers[userVievDataId]['date_last_enter']}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            {
                                (viewUsers[userVievDataId]['organizationData'])
                                ? <div className="mt-3">
                                        <b>Данные организации</b>
                                        <hr />
                                        <table className="table table-striped mt-3">
                                            <tbody>
                                            <tr><td>Название</td><td>{viewUsers[userVievDataId]['organizationData']['name']}</td></tr>
                                            <tr><td>Адрес</td><td>{viewUsers[userVievDataId]['organizationData']['address']}</td></tr>
                                            <tr><td>Номер телефона</td><td>{viewUsers[userVievDataId]['organizationData']['phone']}</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    : false
                            }
                            <div className="mt-3">
                                <b>Статистика</b>
                                <hr />
                                <table className="table table-striped mt-3">
                                    <tbody>
                                        <tr><td>Колличество обьявлений</td><td>{viewUsers[userVievDataId]['countAds']}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-3">
                                <b>Премиум поиск</b>
                                <hr />
                                <table className="table table-striped mt-3">
                                    <tbody>
                                    <tr><td>Тарифный план</td><td>
                                        {
                                            (viewUsers[userVievDataId]['agregator_tarif_type'] === "Free")
                                                ? " Бесплатный"
                                                : (viewUsers[userVievDataId]['agregator_tarif_type'] === "Pro")
                                                    ? " Pro"
                                                    : false
                                        }
                                    </td></tr>
                                    <tr><td>Дата подключения</td><td>{viewUsers[userVievDataId]['date_access_agregator']}</td></tr>
                                    </tbody>
                                </table>
                            </div>
                            {
                                (parseInt(viewUsers[userVievDataId]['countAds']) > 0)
                                    ? <Link to={GlobalParam.urlUserAds + viewUsers[userVievDataId]['id']}>Все обьявления пользователя</Link>
                                    : false
                            }
                        </Modal.Body>
                    </Modal>
                    : false
            }

            {
                (userAdsAccessCount)
                    ? <Modal show={userAdsAccessCount} onHide={(event) => setUserAdsAccessCount(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Ограничение пользователя
                                <br/>{viewUsers[userVievDataId]['fio']}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form action="editUserAccessAdsCount" noValidate id="editUserAccessAdsCountForm" method="GET" onSubmit={(event) => SendForm(event, successEditAccessCountAds, true, false)}>
                                <div>
                                    <div className="">
                                        <label htmlFor="validationCustom01" className="form-label">Допустимое ограничение количества обновлений</label>
                                        <input name="countAds" id="validationCustom01" type="number" className="form-control" placeholder="Введите разрешеное количество обновлений" required />
                                        <input name="user_id" defaultValue={viewUsers[userVievDataId]['id']} type="text" className="form-control d-none" />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                    <div className="alert alert-danger d-none mt-3">

                                    </div>
                                    <div className="alert alert-success mt-3 d-none">

                                    </div>
                                    <input type="submit" value="Сохранить" className="btn btn-success mt-3" />
                                    <button onClick={(event) => setUserAdsAccessCount(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    : false
            }

            {
                (premiumSearch)
                    ? <Modal show={premiumSearch} onHide={(event) => setPremiumSearch(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Премиум поиск пользователя
                                <br/>{viewUsers[userVievDataId]['fio']}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <b>Текущий тарифный план:
                                {
                                    (viewUsers[userVievDataId]['agregator_tarif_type_visible'] === "Free")
                                        ? " Бесплатный"
                                        : (viewUsers[userVievDataId]['agregator_tarif_type'] === "Pro")
                                            ? " Бизнес"
                                            : (viewUsers[userVievDataId]['agregator_tarif_type'] === "Start")
                                                ? " Старт"
                                                : false
                                }
                            </b>
                            <br /><b>Дата окончания подписки:
                            {
                                " " + viewUsers[userVievDataId]['date_access_end_agregator']
                            }</b>
                            <form className="mt-3" action="editUserPremiumSearchTarif" noValidate id="editUserPremiumSearchTarifForm" method="GET" onSubmit={(event) => SendForm(event, (data) => {
                                if(data['success']){
                                    toast.success(data['success']);
                                    viewUsers[userVievDataId]['agregator_tarif_type'] = data['tarif'];
                                    setAllUsers(data['users']);
                                } else if (data['error'])
                                    toast.error(data['error']);
                            }, false, false)}>
                                <div className="form-check form-switch">
                                    <input name="app_work" checked={true} disabled={true} className="form-check-input" type="checkbox" />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Доступ к сервису</label>
                                </div>
                                <select name="tarif" required id="TypeTarifUserSelect" className="form-select mt-3">
                                    <option>Тарифный план</option>
                                    <option value="Free">Бесплатный</option>
                                    <option value="Start_50">Старт 50 просмотров</option>
                                    <option value="Start_80">Старт 80 просмотров</option>
                                    <option value="Start_130">Старт 130 просмотров</option>
                                    <option value="Start_170">Старт 170 просмотров</option>
                                    <option value="Start_210">Старт 210 просмотров</option>
                                    <option value="Start_250">Старт 250 просмотров</option>
                                    <option value="Pro_1d">Бизнес 1 день</option>
                                    <option value="Pro_7d">Бизнес 7 дней</option>
                                    <option value="Pro_14d">Бизнес 14 дней</option>
                                    <option value="Pro_1m">Бизнес 1 месяц</option>
                                    <option value="Pro_3m">Бизнес 3 месяца</option>
                                    <option value="Pro_6m">Бизнес 6 месяцев</option>
                                    <option value="Pro_9m">Бизнес 9 месяцев</option>
                                    <option value="Pro_12m">Бизнес 12 месяцев</option>
                                </select>
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                                <input name="user_id" defaultValue={viewUsers[userVievDataId]['id']} type="text" className="form-control d-none" />
                                <div className="alert alert-danger d-none mt-3">

                                </div>
                                <div className="alert alert-success mt-3 d-none">

                                </div>
                                <input type="submit" value="Сохранить" className="btn btn-success mt-3" />
                            </form>
                        </Modal.Body>
                    </Modal>
                    : false
            }

            {
                (disableCategoryUser)
                    ? <Modal size={"lg"} show={disableCategoryUser} onHide={(event) => setDisableCategoryUser(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Блокировка категорий для
                                <br/>{viewUsers[userVievDataId]['fio']}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form action="setDisableCategoryUser" noValidate id="setDisableCategoryUserForm" method="GET" onSubmit={(event) => sendDateDisableCategory(event)}>
                                <div>
                                    {
                                        (category)
                                            ?   Object.keys(category).map((keyName, i) => {
                                                return <InputCategory checked_category={viewUsers[userVievDataId]['disable_category']} click={setFormAddAdsTrue} category={category[keyName]} key={category[keyName]['id']} />
                                            })
                                            : false
                                    }
                                    <input name="user_id" defaultValue={viewUsers[userVievDataId]['id']} type="text" className="form-control d-none" />
                                    <input name="categoryInput" id="categoryInput" defaultValue={viewUsers[userVievDataId]['disable_category']} type="text" className="form-control d-none" />
                                    <div className="alert alert-danger d-none mt-3">

                                    </div>
                                    <div className="alert alert-success mt-3 d-none">

                                    </div>
                                    <input type="submit" value="Заблокировать" className="btn btn-success mt-3" />
                                    <button onClick={(event) => setDisableCategoryUser(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                    : false
            }

        </div>
    );
};

export default AdminUserPage;