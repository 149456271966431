import $ from 'jquery';
import apiRequest from "./ApiRequest";

const formDefaultStatus = (event, id) => {
    if (!$(id).find(".alert-danger").hasClass("d-none"))
        $(id).find(".alert-danger").addClass("d-none");

    if (!$(id).find(".alert-success").hasClass("d-none"))
        $(id).find(".alert-success").addClass("d-none");

    $("#" + event.target.getAttribute("id")).find('input[type="text"],input[type="password"],textarea').each(function() {
        if ($(this).attr("required")){
            $(this).removeClass("is-invalid");
            $(this).removeClass("is-valid");
        }
    });
}
const formMessageError = (id, message) => {
    $(id).find(".alert-danger").empty();
    $(id).find(".alert-danger").append(message);
    $(id).find(".alert-danger").removeClass("d-none");
}
const formMessageRessponse = (id, message) => {
    $(id).find(".alert-success").empty();
    $(id).find(".alert-success").append(message);
    $(id).find(".alert-success").removeClass("d-none");
}

const SendForm = (event, callback = () => {}, add_response = true, resset = false, uploadFile = false, file= "", callbackResponseError = () => {}) => {
    const id = "#" + event.target.getAttribute("id");
    const action = event.target.getAttribute("action") + "?";
    const method = event.target.getAttribute("method");

    formDefaultStatus(event, id);
    $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").addClass("disabled");
    $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").attr("disabled", true);
    event.preventDefault();

    $("#" + event.target.getAttribute("id")).find('input[type="text"],input[type="number"],input[type="color"],input[type="password"],input[type="date"],textarea').each(function() {
        if ($(this).attr("required"))
            if ($(this).val() !== ""){
                $(this).addClass("is-valid");
                $(this).removeClass("is-invalid");
            } else {
                $(this).removeClass("is-valid");
                $(this).addClass("is-invalid");
            }

        if($(this).attr('type') === "password")
            if ($(this).val().length <= 8) {
                $(this).removeClass("is-valid");
                $(this).addClass("is-invalid");
            }
    });

    if ($(id).find(".is-invalid").length <= 0){
        let formData = "";

        if (method === "GET")
            formData = $(id).serialize();
        else if (method === "POST"){
            formData = new FormData();
            $("#" + event.target.getAttribute("id")).find('input[type="text"],input[type="number"],input[type="color"],input[type="password"],input[type="checkbox"],select,textarea').each(function(){
                let name = $(this).attr("name");
                let value = $(this).val();
                if ($(this).attr("type") !== "checkbox")
                    formData.append(name, value);
                else
                    if ($(this).prop('checked'))
                        formData.append(name, value);
            });
            if (uploadFile && file) {
                let rotate_img_string = "";

                for (let i = 0; i < file.length; i++){
                    formData.append("images_" + [i], file[i]);
                    if (file[i]['rotate'] !== 0)
                        rotate_img_string += "images_" + [i] + ":" + file[i]['rotate'] + "|";
                }

                formData.append("rotate_img_string", rotate_img_string);
            }
        }

        apiRequest(action, formData, method, uploadFile).then(data => {

            if (data['success'] !== undefined){

                callback(data);

                if (add_response) {
                    formMessageRessponse(id, data['success']);
                }

                if (resset) {
                    $(id).trigger("reset");
                }

            }

            if (data['error'] !== undefined)
                formMessageError(id, data['error']);
                callbackResponseError();
        });

    } else {
        formMessageError(id, "Пожалуйста, заполните все обязательные поля.");
        callbackResponseError();
    }

    $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").removeClass("disabled");
    $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").attr("disabled", false);

}

export default SendForm;