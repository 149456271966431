import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import SendForm from "../../Utility/SendForm";
import {useSearchParams} from "react-router-dom";
import GlobalParam from "../../Utility/AppGlobalParam";
import {goAccount} from "../../Utility/Policy";
import PasswordInput from "../../components/PaswordInput/PasswordInput";

const ChangePasswordPage = () => {

    const [searchParams] = useSearchParams();
    const [token, setToken] = useState("");

    useEffect(() => {
        if (searchParams.get("change") !== null && searchParams.get("link") !== null) {
            setToken(searchParams.get("link"));
        } else {
            window.location.href = GlobalParam.domain;
        }
    }, [searchParams]);

    const actionSuccessChangePassword = (data = null) => {
        goAccount(data);
    }

    return (
        <div>
            <PageHeader title="Восстановление пароля" />

            <form action="changePassword" method="GET" id="FormChangePassword" noValidate onSubmit={(event) => SendForm(event, actionSuccessChangePassword,true, true)}>
                <input value={token} name="access_token" type="text" className="form-control d-none" placeholder="Повторите пароль" />
                <div className="form-group">
                    <PasswordInput name="password" id="validationCustom02" placeholder="Введите пароль, не мение 8 символов" />
                    <div className="invalid-feedback">
                        Поле обязательно к заполнению
                    </div>
                </div>
                <div className="form-group">
                    <PasswordInput name="passwordRepeat" id="validationCustom02" placeholder="Введите пароль, не мение 8 символов" />
                    <div className="invalid-feedback">
                        Поле обязательно к заполнению
                    </div>
                </div>
                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <button type="submit" className="btn btn-primary w-100 mt-3">
                    <i className="bi bi-person-exclamation"></i> Восстановить пароль
                </button>
            </form>
        </div>
    );
};

export default ChangePasswordPage;
