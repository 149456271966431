import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {Link} from "react-router-dom";
import SendForm from "../../Utility/SendForm";
import {goAccount} from "../../Utility/Policy";
import PasswordInput from "../PaswordInput/PasswordInput";

let flag = false;

const AutorizationForm = (props) => {
    const [show, setShow] = useState(false);

    const hadnleOpen = () => setShow(true);
    const handleClose = () => setShow(false);

    const showMessageAuthSuccess = (data) => {
        goAccount(data);
    }

    useEffect(() => {
        if (!flag)
            if (props.show){
                setShow(props.show);
                flag = true;
            }
    }, [[]]);

    return (
        <>
            {
                !props.hideBtnAutorize
                    ? <button id="btn_enter" onClick={() => hadnleOpen()} className="nav-link text-white"><i
                        className="bi bi-person-circle"></i> Войти</button>
                    : false
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Вход</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="auth" noValidate method="GET" id="FormAutchAccount" onSubmit={(event) => SendForm(event, showMessageAuthSuccess)}>
                        <div className="form-group text-left">
                            <input name="login" type="text" className="form-control" id="exampleInputEmail1"
                                   aria-describedby="emailHelp" placeholder="Адрес электронной почти или Логин" required />
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                        </div>

                        <div className="form-group mt-3 text-left">
                            <PasswordInput name="password" type="password" className="form-control" id="exampleInputPassword1"
                                   placeholder="Пароль, не мение 8 символов"  required/>
                        </div>
                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <button type="submit" className="btn btn-primary w-100 mt-3"><i
                            className="bi bi-person-down"></i> Войти в личный кабинет</button>
                        <hr />
                        <div className="row">
                            <div className="col-md-6 text-center">
                                <span className="align-middle">
                                    <Link className="btn btn-danger w-100 mt-2" onClick={() => handleClose()} to="/forgotPassword"><i
                                        className="bi bi-person-exclamation"></i> Забыли пароль ?</Link>
                                </span>
                            </div>
                            <div className="col-md-6 text-center align-middle">

                                {
                                    props.registrAppSettings
                                        ?   <Link onClick={() => handleClose()} to="/registration" className="btn btn-success w-100 mt-2"><i
                                            className="bi bi-person-fill-add"></i> Зарегистрироваться</Link>
                                        : false
                                }
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AutorizationForm;