import React from 'react';

const TableDataCar = (props) => {
    return (
        <div style={styles.containerData}>
                <div className="row">
                       <div className="col-md-6">
                               <b>Общая информация</b>
                               <div className="mt-1">Цвет: <div className="w-100 d-inline" style={{padding: 2, backgroundColor: props.adsData['color']}}>__________</div></div>
                               <div className="mt-1">Год выпуска: {props.adsData['yearCreate']}</div>
                               <div className="mt-1">Привод: {props.adsData['privod']}</div>
                               <div className="mt-1">Руль: {props.adsData['steeringType']}</div>
                               <div className="mt-1">Тип трансмиссии: {props.adsData['transmissionType']}</div>
                       </div>
                       <div className="col-md-6">
                               <b>Информация о двигателе</b>
                               <div className="mt-1">Обьем двигателя (л.): {props.adsData['volumeMotor']}</div>
                               <div className="mt-1">Тип двигателя: {props.adsData['typeMotor']}</div>
                               <div className="mt-1">Мощность двигателя (л.с.): {props.adsData['strongMotor']}</div>
                               <div className="mt-1">Пробег двигателя (тыс. км.): {props.adsData['mileageMotor']}</div>
                               <div className="mt-3"></div>
                               <b>Информация о владельцах</b>
                               <div className="mt-1">Владельцев по ПТС: {props.adsData['ptsCountUser']}</div>
                               <div className="mt-1">Тип ПТС: {props.adsData['ptsType']}</div>
                       </div>
                </div>
        </div>
    );
};

const styles = {
        containerData: {
                fontSize: 18,
        }
}

export default TableDataCar;
