import React from 'react';
import { Riple } from "react-loading-indicators";

const LoadingIcon = () => {
    return (
        <div className="text-center">
            <Riple size="small" />
        </div>
    );
};

export default LoadingIcon;
