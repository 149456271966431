import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import {useSearchParams} from "react-router-dom";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import wordByIndex, {isNumeric} from "../../Utility/Utility";
import GlobalParam from "../../Utility/AppGlobalParam";
import ApiRequest from "../../Utility/ApiRequest";
import CategoryTag from "../../components/AdsViewPage/CategoryTag";
import $ from "jquery";
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import TableDataCar from "../../components/AdsViewPage/TableDataCar";
import TableDataPart from "../../components/AdsViewPage/TableDataPart";
import PolicyAuth from "../../Utility/Policy";
import apiRequest from "../../Utility/ApiRequest";
import {
    OKIcon,
    TelegramIcon,
    VKIcon,
    WhatsappIcon,
} from "react-share";
import Modal from "react-bootstrap/Modal";

const AdsViewPage = (props) => {

    const [searchParams] = useSearchParams();
    const [loadAds, setLoadAds] = useState(false);
    const [dataAds, setDataAds] = useState();
    const [titleAds, setTitleAds] = useState("");
    const [getNumberPhone, setGetNumberPhone] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const [viewImage, setViewImage] = useState(false);

    useEffect(() => {
        if (searchParams.get("id")) {
            const AdsId = searchParams.get("id");
            if (isNumeric(AdsId)){
                ApiRequest("getAds?", "ads_id=" + AdsId).then((data) => {
                    if (data['success']){
                        setLoadAds(true);
                        setDataAds(data['adsData']);
                        if (data['adsData']['typeAds'] === "Car")
                            setTitleAds(data['adsData']['avtoMarka']['name'] + " " + data['adsData']['avtoModel']);
                        else
                            setTitleAds(data['adsData']['name']);

                        setFavorite(data['adsData']['favorite']);

                    } else
                        window.location.href = GlobalParam.domain;
                });
            } else
                window.location.href = GlobalParam.domain;
        } else
            window.location.href = GlobalParam.domain;

    }, [searchParams, setTitleAds]);

    const setMainImg = (src) => {
        $("#AdsMainImg").attr("src", src);
    }

    const setFavoriteAds = (ads_id, type) => {
        apiRequest("setFavorite?", "ads_id=" + ads_id + "&type=" + type).then((data) => {
           if (data['success']) {
               if (type === "setter")
                   setFavorite(1);
               else if (type === "delete")
                   setFavorite(0);
           }
        });
    }

    return (
        <div>
            {
                (loadAds)
                    ? <div>
                        <PageHeader title={titleAds} />
                        {
                            (dataAds['accept_moder'])
                                ? false
                                : <div className="alert alert-warning">Обьявление находится на стадии проверки, после проверки оно отобразится на сайте.</div>
                        }
                        <div className="row">
                            <div className="col-md-2 mt-1" title="Дата публикаци">
                                <b><i className="bi bi-calendar-date-fill"></i> {dataAds['date_add']}</b>
                            </div>
                            <div className="col-md-5 mt-1" title="Категории">
                                <b><i className="bi bi-bookmark-check-fill"></i> </b>
                                {
                                    Object.keys(dataAds['categories']).map((keyName, i) => {
                                        return <CategoryTag key={keyName} title={dataAds['categories'][keyName]['name']} id={dataAds['categories'][keyName]['id']} />
                                    })
                                }
                            </div>
                            <div className="col-md-3 mt-1 text-right">
                                <a rel="noreferrer"  className="mr-1" href={"https://connect.ok.ru/offer?url=" + GlobalParam.currentUrl + "&title=" + titleAds} target="_blank"><OKIcon size={32} round={true} /></a>
                                <a rel="noreferrer"  className="mr-1" href={"https://telegram.me/share/url?url=" + GlobalParam.currentUrl + "&text=" + titleAds} target="_blank"><TelegramIcon size={32} round={true} /></a>
                                <a rel="noreferrer"  className="mr-1" href={"https://vk.com/share.php?url=" + GlobalParam.currentUrl} target="_blank"><VKIcon size={32} round={true} /></a>
                                <a rel="noreferrer"  href={"https://api.whatsapp.com/send?text=" + titleAds + " " + GlobalParam.currentUrl} data-action="share/whatsapp/share" target="_blank"><WhatsappIcon size={32} round={true} /></a>
                            </div>
                            <div className="col-md-2 mt-1 text-right">
                                <button title="Количество просмотров" className="btn btn-sm btn-warning"><i className="bi bi-eye-fill"></i> <b>{dataAds['views']}</b></button>
                                {
                                    (PolicyAuth())
                                    ? (favorite)
                                        ? <button onClick={(event) => setFavoriteAds(dataAds['id'], "delete")} title="Удалить из избранных" className="btn btn-sm btn-danger ml-2"><i className="bi bi-bag-heart-fill"></i></button>
                                        : <button onClick={(event) => setFavoriteAds(dataAds['id'], "setter")} title="Добавить в избранное" className="btn btn-sm btn-success ml-2"><i className="bi bi-bag-heart-fill"></i></button>
                                    : false
                                }
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-7">
                                <div>
                                    {
                                        (dataAds['images'].length)
                                            ? <div>
                                                <img onClick={(event) => setViewImage(true)} id="AdsMainImg" width="100%" src={GlobalParam.AdsImagePatch + dataAds['id'] + "/" + dataAds['images'][0]} alt="" style={{cursor: "pointer"}} />
                                                <div onClick={(event) => setViewImage(true)} className="btn btn-sm btn-primary align-middle text-center d-inline-block position-relative w-100" style={{borderRadius: 0}}>
                                                    <i className="bi bi-tv-fill"> </i>
                                                </div>
                                                {
                                                    Object.keys(dataAds['images']).map((keyName, i) => {
                                                        let img_name = dataAds['images'][keyName].split(".");
                                                        return <img style={{cursor: "pointer"}} onClick={(event) => setMainImg(GlobalParam.AdsImagePatch + dataAds['id'] + "/" + dataAds['images'][keyName])} className="mt-2 pl-2" width="100" src={GlobalParam.AdsImagePatch + dataAds['id'] + "/" + img_name[0] + "_100x100.png"} alt="" key={keyName} />
                                                    })
                                                }
                                            </div>
                                            : <div className="text-center">
                                                <img alt="" src={GlobalParam.notImgAdsImage} width="400"/>
                                            </div>
                                    }
                                </div>
                                 <div className="mt-3">
                                     {
                                         (dataAds['typeAds'] !== "Service")
                                             ? <div>
                                                 <h3 className="">Характеристики</h3>
                                                 <hr />
                                             </div>
                                             : false
                                     }
                                    {
                                        (dataAds['typeAds'] === "Car")
                                            ? <TableDataCar adsData={dataAds} />
                                            : (dataAds['typeAds'] === "Part")
                                                ? <TableDataPart adsData={dataAds} />
                                                : false
                                    }
                                 </div>
                                {
                                    (dataAds['description'])
                                        ? <div className="mt-3">
                                            <h3>Описание</h3>
                                            <hr />
                                            <div id="DescriptionConteiner">
                                                {
                                                    dataAds['description']
                                                }
                                            </div>
                                        </div>
                                        : false
                                }

                            </div>
                            <div className="col-md-5 pt-2">

                                {
                                    dataAds['set_aktia_message']
                                        ? <div className="alert alert-info">
                                            <i className="bi bi-exclamation-circle-fill"></i> Внимание:
                                            в данный момент действует скидка -
                                            {
                                                    dataAds['set_aktia_message']
                                                    ? props.appData['aktia']['discount'] + "%"
                                                    : props.appData['aktia']['discount_money']
                                                        ? props.appData['aktia']['discount_money'] + " руб."
                                                        : false
                                            }
                                        </div>
                                        : false
                                }

                                <div className="card sticky-top" style={{border: "none"}}>
                                    {
                                        (dataAds['typeAds'] !== "Service")
                                            ?<h3 className="text-success"><i className="bi bi-cash-stack"></i> {dataAds['price']} руб.</h3>
                                            : <div>
                                                <h3>Прайс лист</h3>
                                                <div id="priceDescriptionContainer" className="text-success">
                                                    {
                                                        dataAds['priceDescription']
                                                    }
                                                </div>
                                            </div>
                                    }
                                    {
                                        (dataAds['isNotFixedPrice'])
                                            ? <div className="alert alert-warning mt-2">
                                                <i className="bi bi-exclamation-circle-fill"></i> Внимание:
                                                цена не является фиксированной, уточняйте текущую цену у продавца.
                                            </div>
                                            : false
                                    }
                                    {
                                        (dataAds['organization_id'])
                                            ?<div className="mt-1">
                                                <b>Данные организации</b>
                                                <br/><span>{dataAds['organizationData']['name']}</span>
                                                <br/><span>{dataAds['organizationData']['address']}</span>
                                            </div>
                                            : ""
                                    }
                                    <div className="mt-3">
                                        <b>Имя продавца</b>
                                        <br/><span>{wordByIndex(dataAds['avtorData']['fio'], 2)}</span>
                                    </div>

                                    <button onClick={(event) => setGetNumberPhone(true)} className="btn btn-large btn-success mt-3 w-100" style={styles.btn_get_Number}>

                                        {
                                            (getNumberPhone)
                                                ? <i className="bi bi-telephone-fill pr-2"></i>
                                                : false
                                        }
                                        {
                                            (getNumberPhone)
                                                ? dataAds['phone']
                                                : "Получить номер телефона"
                                        }
                                        {
                                            (getNumberPhone)
                                                ? <div style={styles.btn_get_Number_Message}>
                                                    Пожалуйста, сообщите продавцу о том где вы узнали об обьявлении.
                                                </div>
                                                : false
                                        }
                                    </button>
                                    {
                                        (dataAds['geocontrol'] !== "")
                                            ? <div className="w-100 mt-3">
                                                {
                                                    (dataAds['geolocationValue'])
                                                        ? <div className="mt-1">
                                                            <b>Место положение</b>
                                                            <br/><span>{dataAds['geolocationValue']}</span>
                                                            <div className="mt-3"></div>
                                                        </div>
                                                        : false
                                                }
                                                <YMaps>
                                                    <Map width='100%'
                                                         defaultState={{
                                                             center: [dataAds['geocontrol'][0], dataAds['geocontrol'][1]],
                                                             zoom: 15,
                                                         }}
                                                    >
                                                        <Placemark geometry={[dataAds['geocontrol'][0], dataAds['geocontrol'][1]]} />
                                                    </Map>
                                                </YMaps>
                                            </div>
                                            : false
                                    }
                                </div>
                            </div>
                        </div>

                        {
                            (viewImage)
                                ? <Modal size={"xl"} show={viewImage} onHide={(event) => setViewImage(false)} aria-labelledby="contained-modal-title-vcenter"
                                         centered>

                                    <div id="carouselExampleIndicators" className="carousel slide carousel-fade bg-dark"
                                         data-bs-ride="carousel" data-keyboard={true}>
                                        <div className="carousel-inner align-items-center">
                                            {
                                                Object.keys(dataAds['images']).map((keyName, i) => {
                                                    return <div className={(i === 0) ? "carousel-item active" : "carousel-item"}>
                                                        <img width="100%" src={GlobalParam.AdsImagePatch + dataAds['id'] + "/" + dataAds['images'][keyName]} key={keyName} alt="..." />
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <button className="carousel-control-prev" type="button"
                                                data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button"
                                                data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>

                                </Modal>
                                : false
                        }

                    </div>
                    : <LoadingIcon />
            }

        </div>
    );
};

const styles = {
    btn_get_Number: {
        height: 100,
        fontSize: 24,
    },
    map: {
        minWidth: "100%",
    },
    btn_get_Number_Message: {
        fontSize: 14,
        color: "#eeeeee"
    }
}

export default AdsViewPage;
