import {Link} from "react-router-dom";
import AutorizationForm from "../AutorizationForm/AutorizationForm";
import PolicyAuth from "../../Utility/Policy";
import React, {useEffect, useState} from "react";
import NavUserMenu from "../NavUserMenu/NavUserMenu";
import {HeaderView} from "../../Utility/Utility";
import GlobalParam from "../../Utility/AppGlobalParam";
import {useResize} from "../../Utility/ResizeHook";
import $ from 'jquery'

const NavigationBar = (props) => {

    const [showAllCategory, setShowAllCategory] = useState("d-none");
    const [category, setCategory] = useState(false);
    const [openCategoryId, setOpenCategoryId] = useState(0);
    const [autoCloseNavBar, setAutoCloseNavBar] = useState(false);

    useEffect(() => {
        setCategory(props.appData['category']);
    }, [props.appData]);

    if (!useResize()['isScreenLg']){
        if (!autoCloseNavBar)
            setAutoCloseNavBar(true);
    } else{
        if (autoCloseNavBar)
            setAutoCloseNavBar(false);
    }

    const showAllCategoryF = () => {
        if (showAllCategory === "d-none")
            setShowAllCategory("d-block");
        else
            setShowAllCategory("d-none");
    }
    const setNewOpenCategoryId = (id) => {
        setOpenCategoryId(id);
    }

    const closeNavigation = () => {
        if (autoCloseNavBar)
            $('#ToogleBtn').trigger('click');
    }

    return (
        <div className={(HeaderView() !== "/ads") ? "card sticky-top" : ""} style={{border: "none"}}>
            <nav className="navbar bg-primary navbar-expand-lg navbar-light p-1 shadow-sm">
                <div className="container">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation" id="ToogleBtn">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <div className="navbar-nav">
                                <Link to="" className="nav-link mx-2 text-white" onClick={(event) => closeNavigation()}>Главная</Link>
                                <Link to="/catalog" className="nav-link mx-2 text-white" onClick={(event) => closeNavigation()}>Каталог</Link>
                                <Link to="/towtruck" className="nav-link mx-2 text-white" onClick={(event) => closeNavigation()}>Эвакуатор</Link>
                                <Link to="/transportation" className="nav-link mx-2 text-white" onClick={(event) => closeNavigation()}>Грузоперевозки</Link>
                                {/*<Link to="/help" className="menu_elem nav-link mx-2 text-white" onClick={(event) => closeNavigation()}>Помощь</Link>*/}
                                <Link to="/about" className="nav-link mx-2 text-white" onClick={(event) => closeNavigation()}>О нас</Link>
                        </div>
                        <ul className="navbar-nav ms-auto ">
                            <div className="btn-group" role="group" aria-label="First group">
                            {
                                ((PolicyAuth() && props.appData['settings']['add_ads_work']) || (PolicyAuth() && props.appData['userData']['role'] === "admin"))
                                    ? <Link to="/account/AddAds" className="btn btn-small btn-success text-white" onClick={(event) => closeNavigation()}>Разместить обьявление</Link>
                                    : ""
                            }
                            {
                                ((PolicyAuth() && props.appData['userData']['access_agregator']) || (PolicyAuth() && props.appData['userData']['role'] === "admin"))
                                    ? <Link to="/account/premiumSearch" className="btn btn-small btn-danger text-white" onClick={(event) => closeNavigation()}>Премиум поиск</Link>
                                    : ""
                            }
                            </div>
                            <li className="nav-item dropdown">
                                {
                                    (!PolicyAuth())
                                    ? <AutorizationForm registrAppSettings={props.appData['settings']['registr_work']} />
                                    : <NavUserMenu appData={props.appData}  closeNavigation={closeNavigation}/>
                                }

                            </li>
                            {
                                (PolicyAuth())
                                    ? (props.appData['userData']['moderator'])
                                        ? <li className="nav-item">
                                            <Link to="/spoadm/ads" className="nav-link mx-2 text-white" onClick={(event) => closeNavigation()}>
                                                <i className="bi bi-gear-fill"></i> Модерация
                                            </Link>
                                        </li>
                                        : false
                                    :false
                            }
                            {
                                (PolicyAuth())
                                ? (props.appData['userData']['role'] === "admin")
                                    ? <li className="nav-item">
                                            <Link to="/spoadm" className="nav-link text-white" onClick={(event) => closeNavigation()}>
                                                <i className="bi bi-gear-fill"></i> Админ панель</Link>
                                        </li>
                                        : false
                                    :false
                            }
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container">
                <div className="row pt-3">
                    <div className="col-md-2 text-center">
                        <Link to="" className="navbar-brand text-white ">
                            <img src={require('../../images/logo-kuban-avto-1.png')} width="170" className="m-1" alt="" />
                        </Link>
                    </div>
                    <div className="col-md-2 text-center">
                        <button onClick={(event) => showAllCategoryF()} className="btn btn-primary mt-1 w-100"><i className="bi bi-list-ol"></i> Все категории</button>
                    </div>
                    <div className="col-md-8">
                        <form action={GlobalParam.urlCategoryAds} method="GET">
                            <div className="row">
                                <div className="col-md-10 text-center">
                                    <input name="search" type="text" className="form-control mt-1 w-100" placeholder="Поиск по обьявлениям" />
                                </div>
                                <div className="col-md-2 text-center">
                                    <button type="submit" className="btn btn-primary mt-1 w-100"><i
                                        className="bi bi-search"></i> Найти</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={"mt-3 position-relative bg-white shadow p-3 " + showAllCategory}>
                    <div className="row">
                        <div className="col-md-2">
                            {
                                (category)
                                    ?   Object.keys(category).map((keyName, i) => {
                                            return <div key={i} className={"mt-2 p-2 " + ((openCategoryId === i) ? "bg-success" : "")}>
                                                <Link onClick={(event) => setNewOpenCategoryId(i)} className={(openCategoryId === i) ? "text-white" : ""}>
                                                    {category[keyName]['name']}
                                                </Link>
                                            </div>
                                    })
                                    : false
                            }
                        </div>
                        <div className="col-md-10">
                            {
                                (category)
                                    ?   Object.keys(category[openCategoryId+1]['podcategory']).map((keyName, i) => {
                                        return <div key={i}>
                                            <Link onClick={(event) => showAllCategoryF()} to={GlobalParam.urlCategoryAds + category[openCategoryId+1]['podcategory'][keyName]['id']}>
                                                {category[openCategoryId+1]['podcategory'][keyName]['name']}
                                            </Link>
                                        </div>
                                    })
                                    : false
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-2"></div>
        </div>
    );
};

export default NavigationBar;
