import React from 'react';
import TableAdsRequireAcceptItem from "./TableAdsRequireAcceptItem";

const TableAdsRequireAccept = (props) => {
    return (
        <table className="table table-striped mt-3">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Картинка</th>
                <th scope="col">Название</th>
                <th scope="col">Цена</th>
                <th scope="col">Просмотры</th>
                <th scope="col">Категории</th>
                <th scope="col">Дата публикации</th>
                <th scope="col">Автор</th>
                <th scope="col">Организация</th>
                <th scope="col">Действия</th>
            </tr>
            </thead>
            <tbody>
            {
                Object.keys(props.Ads).map((keyName, i) => {
                    return <TableAdsRequireAcceptItem adsSuccessAccept={props.adsSuccessAccept} index={i+1} Ads={props.Ads[keyName]} key={props.Ads[keyName]['id']} />
                })
            }
            </tbody>
        </table>
    );
};

export default TableAdsRequireAccept;
