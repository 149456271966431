import React from 'react';
import {slicetext} from "../../Utility/Utility";
import GlobalParam from "../../Utility/AppGlobalParam";
import {Link} from "react-router-dom";

const AdsCard = (props) => {

    let img_name = "";
    if (props.Ads['images'].length)
        img_name = props.Ads['images'][0].split(".");
    let name = ""

    if (props.Ads['typeAds'] === "Car")
        name = props.Ads['avtoMarka']['name'] + " " + props.Ads['avtoModel'];
    else
        name = props.Ads['name'];

    if (name.length > 15)
        name = slicetext(name, 15);

    return (
        <div className="card d-inline-block mt-3 p-1" style={styles.mainCard}>
            <Link to={GlobalParam.urlViewAds + props.Ads['id']}>
                {
                    (props.Ads['images'].length)
                        ? <img alt="" className="card-img-top" src={GlobalParam.AdsImagePatch + props.Ads['id'] + "/" + img_name[0] + "_300x300." + img_name[1]} />
                        : <img alt="" className="card-img-top" src={GlobalParam.notImgAdsImage} style={{maxHeight: 300}}/>
                }
            </Link>
            <div className="card-body text-left">
                <Link className="link-underline link-underline-opacity-0" to={GlobalParam.urlViewAds + props.Ads['id']}>
                    <h5 className="card-title">{name}</h5>
                </Link>
                <p className="card-text">
                    {
                        (props.Ads['description'])
                            ? <span>{slicetext(props.Ads['description'], 15)}<br/></span>
                            : <span>
                            .... <br />
                            </span>
                    }
                    <b className="text-success">{props.Ads['price']} руб.</b></p>
            </div>
            <Link to={GlobalParam.urlViewAds + props.Ads['id']} className="btn btn-primary w-100">
                <i className="bi bi-arrow-right-circle-fill"></i> Смотреть
            </Link>
        </div>
    );
};

const styles = {
    mainCard: {

        border: "none",
    }
}

export default AdsCard;
