import React from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import wordByIndex from "../../Utility/Utility";
import SendForm from "../../Utility/SendForm";

const SettingPage = (props) => {

    const successEditProfile = (data) => {
        if (data['appData'])
            props.setAppData(data['appData']);
    }

    return (
        <div>
            <PageHeader title="Профиль" />

            <b>Данные профиля</b>
            <form action="editProfile" id="EditProfileForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, successEditProfile, true, false)}>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom04" className="form-label">Адрес электронной почты</label>
                        <input name="mail" disabled={true} defaultValue={props.appData['userData']['mail']} type="text" id="validationCustom04" className="form-control" placeholder="Введите адрес электронной почты" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom05" className="form-label">Номер телефона</label>
                        <input name="phone" defaultValue={props.appData['userData']['phone']} type="text" id="validationCustom05" className="form-control" placeholder="Введите номер телефона: +7 918 1234567" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-4">
                        <label htmlFor="validationCustom06" className="form-label">Фамилия</label>
                        <input defaultValue={wordByIndex(props.appData['userData']['fio'], 1)} name="lastName" type="text" id="validationCustom06" className="form-control" placeholder="Введите фамилию" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom07" className="form-label">Имя</label>
                        <input name="Name" defaultValue={wordByIndex(props.appData['userData']['fio'], 2)} type="text" id="validationCustom07" className="form-control" placeholder="Введите имя" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom08" className="form-label">Отчество</label>
                        <input name="middleName" defaultValue={wordByIndex(props.appData['userData']['fio'], 3)} type="text"id="validationCustom08" className="form-control" placeholder="Отчество" />
                    </div>
                </div>
                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Сохранить данные" className="btn btn-success mt-3" />
            </form>
            <div className="mt-3"></div>

            <b>Сменить пароль</b>
            <form action="changePassword" id="ChangePasswordForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, () => {}, true, true)}>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom02" className="form-label">Новый пароль</label>
                        <input name="password" type="password" id="validationCustom02" className="form-control" placeholder="Введите новый пароль, не мение 8 символов" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom03" className="form-label">Повторите новый пароль</label>
                        <input name="passwordRepeat" type="password" id="validationCustom03" className="form-control" placeholder="Повторите новый пароль" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>
                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-2 d-none">

                </div>
                <input type="submit" value="Сменить пароль" className="btn btn-success mt-3" />
            </form>
            {
                (props.appData['organizationData'])
                ? <div className="mt-3">
                    <b>Данные организации</b>
                    <br/><span>Название: {props.appData['organizationData']['name']}</span>
                    <br/><span>Адрес: {props.appData['organizationData']['address']}</span>
                    <br/><span>Номер телефона: {props.appData['organizationData']['phone']}</span>
                </div>
                : false
            }
        </div>
    );
};

export default SettingPage;
