import React from 'react';
import GlobalParam from "../../Utility/AppGlobalParam";
import CategoryTag from "../AdsViewPage/CategoryTag";
import {Link} from "react-router-dom";
import {slicetext} from "../../Utility/Utility";

const AdsListTableItem = (props) => {

    let img_name = "";
    if (props.Ads['images'].length)
        img_name = props.Ads['images'].split(".");

    let name = ""

    if (props.Ads['typeAds'] === "Car")
        name = props.Ads['avtoMarka']['name'] + " " + props.Ads['avtoModel'];
    else
        name = props.Ads['name'];

    return (
        <tr>
            <td className="align-middle">
                {props.index}
            </td>
            <td className="align-middle">
                <Link to={GlobalParam.urlViewAds + props.Ads['id']}>
                    {
                        (props.Ads['images'].length)
                            ? <img src={GlobalParam.AdsImagePatch + props.Ads['id'] + "/" + img_name[0] + "_100x100." + img_name[1]} alt="" />
                            : <img alt="" src={GlobalParam.notImgAdsImage} width="100"/>
                    }
                </Link>
            </td>
            <td className="align-middle">
                <Link to={GlobalParam.urlViewAds + props.Ads['id']}>
                    <h5>{name}</h5>
                </Link>
                <div>
                    {
                        slicetext(props.Ads['description'], 20)
                    }
                </div>
            </td>
            <td className="align-middle">
                {
                    (props.Ads['typeAds'] !== "Service")
                        ?<div>{props.Ads['price']} руб.</div>
                        : <div>
                            Прайс лист
                        </div>
                }
            </td>
            <td className="align-middle">
                {props.Ads['views']}
            </td>
            <td className="align-middle">
                {
                    Object.keys(props.Ads['categories']).map((keyName, i) => {
                        return <CategoryTag key={keyName} title={props.Ads['categories'][keyName]['name']} id={props.Ads['categories'][keyName]['id']} />
                    })
                }
            </td>
            <td className="align-middle">
                {props.Ads['date_add']}
            </td>
            <td className="align-middle">
                {
                    (props.Ads['accept_moder'] === 0)
                        ? <div className="text-warning font-weight-bold">На проверке</div>
                        : (props.Ads['accept_moder'] === 1)
                            ? <div className="text-success font-weight-bold">Проверка пройдена</div>
                            : <div className="text-danger font-weight-bold">Проверка не пройдена</div>
                }
            </td>
            <td className="align-middle">

                <span className="dropdown-toggle btn btn-primary" role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    <span className="p-1">Действия</span>
                </span>

                <ul className="dropdown-menu p-2">
                    {
                        (props.Ads['accept_moder'] !== 2)
                            ? <li>
                                <button onClick={(event) => props.setEditAdsAction(props.Ads['id'], name)} className="btn btn-sm btn-primary mt-1"><i className="bi bi-pencil-fill"></i> Редактировать</button>
                            </li>
                            : false
                    }
                    <li>
                        {
                            (props.Ads['accept_moder'] === 1)
                                ? (props.Ads['active'] === 1)
                                    ? <div>
                                        <button onClick={(event) => props.setChangeStatusAdsAction(props.Ads['id'], name, 1)} className="btn btn-sm btn-warning mt-1">
                                            <i className="bi bi-bag-x-fill"></i> Сделать не активным
                                        </button>
                                    </div>
                                    : <div>
                                        <button onClick={(event) => props.setChangeStatusAdsAction(props.Ads['id'], name, 2)} className="btn btn-sm btn-warning mt-1">
                                            <i className="bi bi-bag-x-fill"></i> Сделать активным
                                        </button>
                                    </div>
                                : false
                        }
                    </li>
                    <li>
                        <button onClick={(event) => props.delAds(props.Ads['id'], name)} className="btn btn-sm btn-danger mt-1"><i className="bi bi-trash-fill"></i> Удалить</button>
                    </li>
                </ul>
            </td>
        </tr>
    );
};

export default AdsListTableItem;
