import React from 'react';
import GlobalParam from "../../Utility/AppGlobalParam";
import {Link} from "react-router-dom";

const CategoryItem = (props) => {
    return (
        <span>
        {
            (!props.short)
            ? <Link to={GlobalParam.urlCategoryAds + props.id_category}>
                <div className="d-inline-block mr-2 shadow" style={styles.category_c}>
                    <div>
                        <div className="pr-3">
                            {props.name}
                        </div>
                        <div className="text-right" style={{overflow: "hidden"}}>
                            <img src={GlobalParam.categoryIconPatch + props.image_icon + ".png"} style={styles.img} width="70" height="70" alt="" />
                        </div>
                    </div>
                </div>
            </Link>
            : props.name
        }
        </span>
    );
};

const styles = {
    category_c: {
        background: '#bbd9f5',
        color: '#0a3622',
        minWidth: 120,
        borderRadius: 10,
        paddingTop: 5,
        paddingLeft: 10,
        cursor: "pointer",
        borderColor: "#abd6fc",
        borderStyle: "solid",
        borderWidth: 1,
    },
    img: {
        position: "relative",
        top: 10,
        left: 10
    }
};


export default CategoryItem;
