import React, {useEffect, useState} from 'react';
import $ from "jquery";
import GlobalParam from "../../Utility/AppGlobalParam";
import sendForm from "../../Utility/SendForm";
import Select from "react-select";
import {AddressSuggestions} from "react-dadata";
import ImgAds from "../AddAdsPage/ImgAds";
import FormTypeCar from "../AddAdsPage/FormTypeCar";
import FormTypePart from "../AddAdsPage/FormTypePart";
import FormTypeService from "../AddAdsPage/FormTypeService";
import apiRequest from "../../Utility/ApiRequest";
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';

let indexLastImage = 0;

const FormEditAds = (props) => {
    const [carMarka, setCarMarka] = useState(false);
    const [displayImgAds, setdisplayImgAds] = useState([]);
    const [checkedFormType, setFormType] = useState(false);
    const [adsAvtoMarka, setAdsAvtoMarka] = useState("");
    const [typePartWheel, setTypePartWheel] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);
    const [geolocation, setGeolocation] = useState(props.adsData['geocontrol'].split(","));
    const [addressName, setAddressName] = useState(props.adsData['geolocationValue']);
    const [defaultGeolocation, setdefaultGeolocation] = useState();


    useEffect(() => {
        setCarMarka(props.dataForm['SPCars']);
        setFormType(props.adsData['typeAds']);
        setAdsAvtoMarka(props.adsData['avtoMarka']);

        if(props.adsData['images'].split("/").length)
            setCurrentImages(props.adsData['images'].split("/"));
        if (props.adsData['diamWheel'])
            setTypePartWheel(true);

    }, [props.dataForm, props.adsData]);

    const addImgAds = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImage++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAds([...displayImgAds, ...arrImg]);
        event.target.value = null;
    }
    const deleteImgAds = (idImg, delete_server = false, ads_id = false) => {
        setdisplayImgAds(displayImgAds.filter(obj => {
            return obj['index'] !== idImg;
        }));
        setCurrentImages(currentImages.filter(obj => {
            return obj !== idImg;
        }));
        if (delete_server)
            if (ads_id) {
                apiRequest("deleteAdsImg?", "ads_id=" + ads_id + "&idImg=" + idImg).then((data) => {
                    if (data['error'])
                        alert(data['error']);
                });
            }
    }
    const rotateImage = (idImg) => {
        let newQuaternion = 0;
        displayImgAds.map((obj, i) => {
            if(obj['index'] === idImg){
                if (displayImgAds[i]['file']['rotate'] < 270){
                    displayImgAds[i]['file']['rotate'] = displayImgAds[i]['file']['rotate'] + 90;
                    newQuaternion = displayImgAds[i]['file']['rotate'];
                } else {
                    displayImgAds[i]['file']['rotate'] = 0;
                    newQuaternion = displayImgAds[i]['file']['rotate'];
                }
            }
        });

        $("#ads_image_id_" + idImg).css({
            "-webkit-transform": "rotate(" + newQuaternion + "deg)",
            "-moz-transform": "rotate(" + newQuaternion + "deg)",
            "transform": "rotate(" + newQuaternion + "deg)" /* For modern browsers(CSS3)  */
        });

        setdisplayImgAds(displayImgAds);
    }
    const getGeolocation = (event) => {
        setGeolocation([event.data.geo_lat,event.data.geo_lon]);
        const coords = event.data.geo_lat + "," + event.data.geo_lon;
        setAddressName(event['value']);
    }
    const setMapCords = (cords) => {
        setGeolocation([cords[0], cords[1]]);
        const coords = cords[0] + "," + cords[1];
        setdefaultGeolocation(coords);

        var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";
        var token = "6cc4b26d810fcf2537bcd2ba90f37b926acf6558";
        var query = { lat: cords[0], lon: cords[1] };

        var options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify(query)
        }

        fetch(url, options)
            .then(response => response.json())
            .then(result => setAddressName(result['suggestions'][0]['value']))
            .catch(error => alert("error", error));
    }
    const addNewAdsResult = (data) => {
        props.setEditAds(false);
    }
    const SendFormEditAds = (event) => {

        let files = [];
        for (let i = 0; i < displayImgAds.length; i++)
            files.push(displayImgAds[i]['file']);

        sendForm(event, addNewAdsResult, true, false, true, files);
    }

    return (
        <form action="editAds" id="FormEditAds" method="POST" className="needs-validation" noValidate onSubmit={(event) => SendFormEditAds(event)} >
            {
                (checkedFormType !== "Car")
                    ? <div className="pt-3">
                        <input name="name" defaultValue={props.adsData['name']} type="text" className="form-control" placeholder="Введите название" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    : <div>
                        <div className="pt-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select
                                        options={carMarka}
                                        defaultValue = {
                                            carMarka.filter(option =>
                                                option.value === parseInt(props.adsData['avtoMarka']))
                                        }
                                        placeholder="Марка автомобиля"
                                        isSearchable={true}
                                        onChange={(event) => setAdsAvtoMarka(event.value)}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <input name="avtoModel" defaultValue={(props.adsData) ? props.adsData['avtoModel'] : ""} placeholder="Введите модель автомобиля" type="text" className="form-control" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            }
            <div className="pt-5">
                <input name="ads_id" defaultValue={props.adsData['id']} type="text" className="form-control d-none" />
                <input name="typeAds" defaultValue={props.adsData['typeAds']} type="text" className="form-control d-none" />
                <input name="categories" defaultValue={props.adsData['categories']} type="text" className="form-control d-none" />
                <input name={"avtoMarka"} type={"text"} value={adsAvtoMarka} className={"d-none"} />
                <input name="phone" type="text" defaultValue={props.adsData['phone']} className="form-control" placeholder="Введите номер телефона" required />
                <div className="valid-feedback">
                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                </div>
                <div className="invalid-feedback">
                    Поле обязательно к заполнению
                </div>
            </div>
            <div className="pt-5">
                <ul className="nav nav-tabs mb-3" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1"
                           role="tab" aria-controls="simple-tabpanel-0" aria-selected="true"> Ввести адрес </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="simple-tab-2" data-bs-toggle="tab" href="#simple-tabpanel-2"
                           role="tab" aria-controls="simple-tabpanel-1" aria-selected="false"> Указать адрес на
                            карте </a>
                    </li>
                </ul>
                <div className="tab-content" id="tab-content">
                    <div className="tab-pane active" id="simple-tabpanel-1" role="tabpanel"
                             aria-labelledby="simple-tab-1">
                        <div className="mt-1">
                            <small>Начните вводить адрес</small>
                            <AddressSuggestions token="6cc4b26d810fcf2537bcd2ba90f37b926acf6558" value={addressName} defaultQuery={addressName} onChange={(event) => getGeolocation(event)} />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="simple-tabpanel-2" role="tabpanel" aria-labelledby="simple-tab-2">
                        <div className="mt-1">
                            <small>Укажите адрес на карте</small>
                            <YMaps>
                                <Map width="100%"
                                     defaultState={{ center: [geolocation[0], geolocation[1]], zoom: 20 }}
                                     onClick={(event) => setMapCords(event.get("coords"))}>
                                    <Placemark geometry={[geolocation[0], geolocation[1]]} />
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
                <input name="geocontrol" value={geolocation} type="text" className="form-control mt-1 d-none" id="geocontrolInput" />
                <input name="geolocationValue" value={addressName} type="text" className="form-control mt-1 d-none" required/>
            </div>
            <div className="pt-5">
                <div className="">
                    <b>Изображения</b>
                    <hr />
                    <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                        {
                            (currentImages.length)
                                ? currentImages.map((image) => {
                                    if (image !== "")
                                        return <ImgAds rotate_img={false} delete_server={true} ads_id={props.adsData['id']} key={props.adsData['id']} src={GlobalParam.AdsImagePatch + props.adsData['id'] + "/" + image} id_img={image} deleteImage={deleteImgAds} />
                                    else
                                        return false;
                                })
                                : false
                        }
                        {
                            (displayImgAds.length)
                                ? displayImgAds.map((image, key) => {
                                    return (
                                        <ImgAds rotate_img={true} rotateImage={rotateImage} src={image['url']} id_img={image.index} deleteImage={deleteImgAds} key={key} />
                                    )
                                })
                                : false
                        }
                    </div>
                    <label className="btn btn-default d-inline">
                        <div style={styles.imagebutton}>
                            <i className="bi bi-camera"></i>
                        </div>
                        <input type="file" multiple="multiple" onChange={(event) => addImgAds(event)} hidden />
                    </label>
                    <div className="alert alert-primary">
                        <i className="bi bi-exclamation-circle-fill"></i> <b>Внимание: </b> при удалении изображений, они удаляются безвозвратно.
                    </div>
                </div>
                {
                    (checkedFormType === "Car")
                        ? <FormTypeCar adsData={props.adsData} />
                        : false

                }
                {
                    (checkedFormType === "Part")
                        ? <FormTypePart adsData={props.adsData} typePartWheel={typePartWheel} car_marka={carMarka} setAdsMarka={setAdsAvtoMarka}/>
                        : false
                }
                {
                    (checkedFormType === "Service")
                        ? <FormTypeService adsData={props.adsData} />
                        : false
                }
            </div>
            {
                (checkedFormType)
                    ? <div className="pt-3">
                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-3 d-none">

                            </div>
                            <button className="btn btn-success w-100 mt-3" type="submit">
                                Сохранить изменения
                            </button>
                    </div>
                    : false
            }
        </form>
    );
};

const styles = {
    div_category: {
        maxHeight: 400,
        overflow: "hidden",
        overflowY: "scroll",
        backgroundColor: "#F5FDF3FF",
        padding: 3
    },
    imagebutton: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 20,
        paddingBottom: 20,
        fontSize: 40,
        color: "#abd6fc",
        borderColor: "#abd6fc",
        borderStyle: "solid",
        borderWidth: 1,
    }
}

export default FormEditAds;
