import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import TemplateAdminPanel from "../../components/AdminPanel/TemplatePage/TemplateAdminPanel";
import Select from "react-select";
import SendForm from "../../Utility/SendForm";
import $ from 'jquery';
import {toast} from "react-toastify";
import apiRequest from "../../Utility/ApiRequest";

const AdminSpravochnikPage = (props) => {

    const [indexMarkaAvto, setIndexMarkaAvto] = useState(false);
    const [indexMarkaAvto2, setIndexMarkaAvto2] = useState(false);
    const [nameSelectMarkaAvto, setNameSelectMarkaAvto] = useState("");
    const [carMarka, setCarMarka] = useState(false);
    const [currentModelAvto, setCurrentModelAvto] = useState(false);
    const [messageModelAvto, setMessageModelAvto] = useState(false);


    useEffect(() => {
        setCarMarka(props.appData['SPCars']);
    }, [[]]);

    const selectSpravochnikMarkaAvto = (value, name) => {
        setIndexMarkaAvto(value);
        setNameSelectMarkaAvto(name);
        $("#inputEditNameSPMarkaAvto").attr("value", name);
    }
    const selectSpravochnikMarkaAvto2 = (value, name) => {
        setIndexMarkaAvto2(value);
        setMessageModelAvto(false);
        apiRequest('getSPModel?', 'id_cars=' + value).then((data) => {
            if (data['success']){
                setCurrentModelAvto(data['models']);
            } else
                setMessageModelAvto(data['error']);
        });
    }
    const deleteMarkaAvto = (event, spe_id) => {
        event.preventDefault();
        apiRequest("deleteMarkaAvto?", "spe_id=" + spe_id).then((data) => {
           if (data['success']){
               toast.success(data['success']);
               setCarMarka(data['SPCars']);
               setIndexMarkaAvto(false);
               props.reloadSPCars(data['SPCars']);
           } else if (data['error'])
               toast.error(data['error']);
        });
    }
    const deleteModelAvto = (model_id) => {
        apiRequest("deleteNewModelAvto?", "cars_id=" + model_id).then((data) => {
            if (data['success']){
                if(data['models']['error']){
                    setCurrentModelAvto(false);
                    setMessageModelAvto(data['models']['error']);
                } else
                    setCurrentModelAvto(data['models']);
            } else if (data['error']){
                toast.error(data['error']);
            }
        });
    }
    const changePositionModel = (cars_id, model_id, arrow) => {
        apiRequest("chengePositionModelAvto?", "cars_id=" + cars_id + "&model_id=" + model_id + "&arrow=" + arrow).then((data) => {
            if (data['success']){
                if(!data['models']['error'])
                    setCurrentModelAvto(data['models']);
                toast.success(data['success']);
            } else if (data['error']){
                toast.error(data['error']);
            }
        });
    }

    return (
        <div>
            <PageHeader title="Административная панель" />
            <TemplateAdminPanel appData={props.appData} id_activ={4}>
                <PageHeader title="Справочники сайта" noHr={true} />
                <div className="mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                Справочник марки автомобилей
                            </div>
                            <div className="col-md-8">
                                <form action="AddNewMarkaAvto" id="AddNewMarkaAvtoForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, (data) => {
                                    if(data['success']){
                                        setCarMarka(data['SPCars']);
                                        props.reloadSPCars(data['SPCars']);
                                        toast.success(data['success']);
                                    } else if (data['error'])
                                        toast.error(data['error']);
                                }, false, true)}>
                                    <div className="input-group">
                                        <input name="name" type="text" className="form-control" placeholder="Введите название" required />
                                        <button className="btn btn-success" type="submit">Добавить</button>
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                        <div className="alert alert-danger d-none mt-3">

                                        </div>
                                        <div className="alert alert-success mt-2 d-none">

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    <div>
                        <div className="mt-3"></div>
                        <Select
                            options={carMarka}
                            placeholder="Марка автомобиля"
                            isSearchable={true}
                            onChange={(event) => selectSpravochnikMarkaAvto(event.value, event.label)}
                        />
                        {
                            (indexMarkaAvto)
                                ? <div>
                                    <hr />
                                    <form action="editMarkaAvto" id="editMarkaAvtoForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, (data) => {
                                        if(data['success']){
                                            setCarMarka(data['SPCars']);
                                            props.reloadSPCars(data['SPCars']);
                                            toast.success(data['success']);
                                        } else if (data['error'])
                                            toast.error(data['error']);
                                    }, true)}>
                                        <label htmlFor="validationCustom01" className="form-label">Изменить название</label>
                                        <input value={nameSelectMarkaAvto} onChange={(event) => setNameSelectMarkaAvto(event.target.value)} name="name" type="text" className="form-control" placeholder="Введите название" required />
                                        <input value={indexMarkaAvto} name="spe_id" type="text" className="form-control d-none" required />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                        <div className="alert alert-danger d-none mt-3">

                                        </div>
                                        <div className="alert alert-success mt-2 d-none">

                                        </div>
                                        <button className="btn btn-success mt-2" type="submit">Изменить</button>
                                        <button onClick={(event) => deleteMarkaAvto(event, indexMarkaAvto)} className="btn btn-danger mt-2 ml-2">Удалить элемент справочника</button>
                                        <button onClick={(event) => setIndexMarkaAvto(false)} className="btn btn-primary mt-2 ml-2">Отмена</button>
                                    </form>
                                </div>
                                : false
                        }
                    </div>
                </div>
                <hr />
                <div className="mt-3">
                    <div className="row">
                        <div className="col-md-4">
                            Справочник модели автомобилей
                        </div>
                        <div className="col-md-8">

                        </div>
                    </div>
                    <div>
                        <Select
                            options={carMarka}
                            placeholder="Марка автомобиля"
                            isSearchable={true}
                            onChange={(event) => selectSpravochnikMarkaAvto2(event.value, event.label)}
                        />
                        {
                            (indexMarkaAvto2)
                                ? <div className="mt-3 row">
                                    <div className="col-md-6">
                                        <form action="AddNewModelAvto" id="AddNewModelAvtoForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, (data) => {
                                            if(data['success']){
                                                setCurrentModelAvto(data['models']);
                                            } else if (data['error'])
                                                toast.error(data['error']);
                                        }, true, true)}>
                                            <div className="input-group">
                                                <input name="name" type="text" className="form-control" placeholder="Введите название" required />
                                                <input name="cars_id" type="text" className="form-control d-none" value={indexMarkaAvto2} />
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                                <div className="alert alert-danger d-none mt-3 w-100">

                                                </div>
                                                <div className="alert alert-success mt-2 d-none w-100">

                                                </div>
                                                <br />
                                                <button className="btn btn-success w-100 mt-3" type="submit">Добавить</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Добавленные модели</h6>
                                        {
                                            currentModelAvto
                                                ? Object.keys(currentModelAvto).map((keyName, i) => {
                                                    return <div>
                                                        {currentModelAvto[keyName]['name']}
                                                        <span onClick={(event) => deleteModelAvto(currentModelAvto[keyName]['id'])} className="text-danger ml-2" style={{fontSize: 20, cursor: "pointer"}}><i className="bi bi-x-square"></i></span>
                                                        <span onClick={(event) => changePositionModel(currentModelAvto[keyName]['id_cars'], currentModelAvto[keyName]['id'],1)} className="text-primary ml-2" style={{fontSize: 20, cursor: "pointer"}}><i className="bi bi-arrow-up-square"></i></span>
                                                        <span onClick={(event) => changePositionModel(currentModelAvto[keyName]['id_cars'], currentModelAvto[keyName]['id'],2)} className="text-primary ml-2" style={{fontSize: 20, cursor: "pointer"}}><i className="bi bi-arrow-down-square"></i></span>
                                                    </div>
                                                })
                                                : messageModelAvto
                                                     ? <div className="alert alert-danger">{messageModelAvto}</div>
                                                     : false
                                        }
                                    </div>
                                </div>
                                : false
                        }
                    </div>
                </div>
            </TemplateAdminPanel>
        </div>
    );
};

export default AdminSpravochnikPage;
