import React, {useEffect} from 'react';
import $ from "jquery";
import Select from "react-select";

const FormTypePart = (props) => {

    useEffect(() => {
        if (props.adsData) {
            let typePart = props.adsData['typePart'];
            let avtoModel = props.adsData['avtoModel'];
            let price = props.adsData['price'];
            let description = props.adsData['description'];

            let diamWheel = props.adsData['diamWheel'];
            let diamOtverst = props.adsData['diamOtverst'];
            let countOtverst = props.adsData['countOtverst'];
            let posadOtverst = props.adsData['posadOtverst'];
            let viletWheel = props.adsData['viletWheel'];
            let lengthPokrishki = props.adsData['lengthPokrishki'];

            $("select[name='typePart'] option:contains('" + typePart + "')").prop('selected', true);

            $("input[name='avtoModel']").attr("value", avtoModel);
            $("input[name='price']").attr("value", price);
            $("input[name='diamWheel']").attr("value", diamWheel);
            $("input[name='diamOtverst']").attr("value", diamOtverst);
            $("input[name='countOtverst']").attr("value", countOtverst);
            $("input[name='posadOtverst']").attr("value", posadOtverst);
            $("input[name='viletWheel']").attr("value", viletWheel);
            $("input[name='lengthPokrishki']").attr("value", lengthPokrishki);
            $("textarea[name='description']").attr("value", description);
        }
    }, [props.adsData]);


    return (
        <div>
            <div className="mt-3">
                <b>Общая информация</b>
                <hr />
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">Тип запчасти</label>
                        <select name="typePart" className="form-select">
                            <option value="1">Новая</option>
                            <option value="2">БУ</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">Марка автомобиля</label>
                        <Select
                            options={props.car_marka}
                            placeholder=""
                            defaultValue = {
                                (props.adsData)
                                    ? props.car_marka.filter(option =>
                                        option.value === parseInt(props.adsData['avtoMarka']))
                                    : false
                            }
                            isSearchable={true}
                            onChange={(event) => props.setAdsMarka(event.value)}
                        />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label">Модель автомобиля</label>
                        <input name="avtoModel" placeholder="" type="text" className="form-control" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>
            </div>
            {
                (props.typePartWheel)
                ? <div className="mt-3">
                        <b>Дополнительная информация</b>
                        <hr />
                        <div className="row">
                            <div className="col-md-4">
                                <label className="form-label">Диаметр колеса (дюйм)</label>
                                <input name="diamWheel" placeholder="" type="number" className="form-control" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Диаметр крепежных отверстий (мм.)</label>
                                <input name="diamOtverst" placeholder="" type="number" className="form-control" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Количество отверстий (шт.)</label>
                                <input name="countOtverst" placeholder="" type="number" className="form-control" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label className="form-label">Посадочное отверстие (мм.)</label>
                                <input name="posadOtverst" placeholder="" type="number" className="form-control" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Вылет колеса (мм.)</label>
                                <input name="viletWheel" placeholder="" type="number" className="form-control" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Размер покрышки</label>
                                <input name="lengthPokrishki" placeholder="" type="number" className="form-control" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                    : false
            }
            <div className="mt-3">
                <label className="form-label"><b>Цена (руб.)</b></label>
                <input name="price" placeholder="" type="number" size="7" className="form-control" required />
                <div className="valid-feedback">
                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                </div>
                <div className="invalid-feedback">
                    Поле обязательно к заполнению
                </div>
                <div className="custom-control custom-checkbox mr-sm-2 mt-1">
                    <input defaultChecked={(props.adsData && props.adsData['isNotFixedPrice']) ? true : false} value="1" name="isNotFixedPrice" type="checkbox" className="custom-control-input" id="customControlAutosizing" />
                    <label className="custom-control-label" htmlFor="customControlAutosizing"> Цена является плавающей</label>
                </div>
            </div>
            <div className="mt-3">
                <b>Описание</b>
                <hr />
                <div className="form-group">
                    <textarea name="description" className="form-control" rows="6">
                        {
                            (props.adsData)
                                ? props.adsData['description']
                                : ""
                        }
                    </textarea>
                </div>
            </div>
        </div>
    );
};

export default FormTypePart;
