import React from 'react';
import imgClosed from '../../images/app_closed.png'

const AppClosedPage = () => {
    return (
        <div className="text-center">
            <img src={imgClosed} className="w-100" alt="" />
        </div>
    );
};

export default AppClosedPage;
