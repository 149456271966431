import PageHeader from "../../components/PageHeader/PageHeader";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import FormAddAds from "../../components/AddAdsPage/FormAddAds";

const AddAdsPage = (props) => {

    return (
        <div>
            <PageHeader title="Новое обьявление" />
            {
                (props.appData)
                ? <FormAddAds dataForm={props.appData} />
                    : <LoadingIcon/>
            }
        </div>
    );
};

export default AddAdsPage;
