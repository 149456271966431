import React from 'react';

const PageHeader = (props) => {
    return (
        <div>
            <h4><strong>{props.children} {props.title}</strong></h4>
            {
                (!props.noHr)
                    ? <hr />
                    : false
            }

        </div>
    );
};

export default PageHeader;
