import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import $ from 'jquery';

const TemplateAdminPanel = (props) => {

    useEffect(() => {
        if(props.id_activ) {
            $("#" + props.id_activ).addClass("active");
            $("#" + props.id_activ).find("a").addClass("text-white");
        }
    }, [props.id_activ])

    return (
        <div className="row">
            <div className="col-md-3">
                <ul className="list-group shadow">

                    {
                        props.appData['userData']['role'] === "admin"
                            ? <li id="7" className="list-group-item">
                                <Link to="/spoadm" className="link-underline link-underline-opacity-0 text-dark">
                                    <i className="bi bi-sort-numeric-down"> </i>Статистика
                                </Link>
                            </li>
                            : false
                    }

                    {
                        props.appData['userData']['role'] === "admin"
                         ? <li id="1" className="list-group-item">
                                <Link to="/spoadm/users" className="link-underline link-underline-opacity-0 text-dark">
                                    <i className="bi bi-person-vcard-fill"> </i>Пользователи
                                </Link>
                            </li>
                            : false
                    }

                    {
                        props.appData['userData']['role'] === "admin" || props.appData['userData']['moderator']
                            ? <li id="2" className="list-group-item">
                                <Link to="/spoadm/ads" className="link-underline link-underline-opacity-0 text-dark">
                                    <i className="bi bi-file-earmark-spreadsheet-fill"> </i>Модерация объявлений
                                </Link>
                            </li>
                            : false
                    }

                    {
                        props.appData['userData']['role'] === "admin"
                            ? <li id="8" className="list-group-item">
                                <Link to="/spoadm/premiumSearch" className="link-underline link-underline-opacity-0 text-dark">
                                    <i className="bi bi-search"> </i>Премиум поиск
                                </Link>
                            </li>
                            : false
                    }

                    {
                        props.appData['userData']['role'] === "admin"
                            ? <li id="3" className="list-group-item">
                                <Link to="/spoadm/category" className="link-underline link-underline-opacity-0 text-dark">
                                    <i className="bi bi-list-ol"> </i>Категории
                                </Link>
                            </li>
                            : false
                    }

                    {
                        props.appData['userData']['role'] === "admin"
                            ? <li id="4" className="list-group-item">
                                <Link to="/spoadm/spravochnik" className="link-underline link-underline-opacity-0 text-dark">
                                    <i className="bi bi-book-fill"> </i>Справочники
                                </Link>
                            </li>
                            : false
                    }

                    {
                        props.appData['userData']['role'] === "admin"  || props.appData['userData']['moderator']
                            ? <li id="5" className="list-group-item">
                                <Link to="/spoadm/promo" className="link-underline link-underline-opacity-0 text-dark">
                                    <i className="bi bi-upc-scan"> </i>Промокоды
                                </Link>
                            </li>
                            : false
                    }

                    {
                        props.appData['userData']['role'] === "admin"
                            ? <li id="6" className="list-group-item">
                                <Link to="/spoadm/settings" className="link-underline link-underline-opacity-0 text-dark">
                                    <i className="bi bi-gear-fill"> </i>Настройки
                                </Link>
                            </li>
                            : false
                    }
                </ul>
            </div>
            <div className="col-md-9">
                {
                    props.children
                }
            </div>
        </div>
    );
};

export default TemplateAdminPanel;
