import notImgAds from "../images/notImg.jpg";
const GlobalParam = {
    domain: window.location.hostname,
    urlCategoryAds: "http://" + window.location.host + "/catalog?id=",
    urlUserAds: "http://" + window.location.host + "/catalog?user_id=",
    urlViewAds: "http://" + window.location.host + "/ads?id=",
    categoryIconPatch: "https://api.kuban-avto.com/uploads/category/",
    AdsImagePatch: "https://api.kuban-avto.com/uploads/ads/",
    currentUrl: document.URL,
    notImgAdsImage: notImgAds,
    intervalLoadAdsFromAgregator: 10000,
};

export default GlobalParam;