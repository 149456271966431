import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import TemplateAdminPanel from "../../components/AdminPanel/TemplatePage/TemplateAdminPanel";
import CategoryAdminPageItem from "../../components/AdminPanel/CategoryPage/CategoryAdminPageItem";
import $ from 'jquery';
import Modal from "react-bootstrap/Modal";
import sendForm from "../../Utility/SendForm";
import {toast} from "react-toastify";

let indexCategory = 0;
let NameCategory = "";

const AdminCategoryPage = (props) => {

    const [category, setCategory] = useState(false);

    useEffect(() => {
        setCategory(props.appData['category']);
    }, [props.appData]);

    const showAddCategoryForm = (category_id) => {
        if($("#form_add_podcategory_" + category_id).hasClass("d-none"))
            $("#form_add_podcategory_" + category_id).removeClass("d-none");
    }
    const hideAddCategoryForm = (event, category_id) => {
        event.preventDefault();
        if(!$("#form_add_podcategory_" + category_id).hasClass("d-none"))
            $("#form_add_podcategory_" + category_id).addClass("d-none");
    }
    const setNewCategory = (category) => {
        setCategory(category);
    }
    const [deleteCatery, setDeleteCategory] = useState(false);
    const setCategoryDelete = (category_id, name) => {
        indexCategory = category_id;
        NameCategory = name;
        setDeleteCategory(true);
    }
    return (
        <div>
            <PageHeader title="Административная панель" />
            <TemplateAdminPanel appData={props.appData} id_activ={3}>
                <PageHeader title="Категории обьявлений" noHr={true} />
                {
                    (category)
                        ?   Object.keys(category).map((keyName, i) => {
                            return <CategoryAdminPageItem setCategoryDelete={setCategoryDelete} setNewCategory={setNewCategory} reloadCategory={props.reloadCategory} hideAddCategoryForm={hideAddCategoryForm} showAddCategoryForm={showAddCategoryForm} category={category[keyName]} rang={1} key={category[keyName]['id']} />
                        })
                        : false
                }
                {
                    (deleteCatery)
                        ? <Modal show={deleteCatery} onHide={(event) => setDeleteCategory(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Удаление категории
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form action="deleteCategory" id="delCategoryForm" onSubmit={(event) => sendForm(event, (data) => {
                                    if(data['success']) {
                                        toast.success(data['success'])
                                        props.reloadCategory(data['category']);
                                        setNewCategory(data['category']);
                                        hideAddCategoryForm(event, indexCategory);
                                        setDeleteCategory(false);
                                    } else if (data['error'])
                                        toast.error(data['error'])
                                }, true, true)} method="GET">
                                    <div>
                                        Вы действительно хотите удалить категорию <b>{NameCategory}</b>?
                                        <hr/>
                                        <div className="mt-2">
                                            <input name="category_id" type="text" defaultValue={indexCategory} className="form-control d-none" />
                                        </div>
                                        <div className="alert alert-danger d-none mt-3">

                                        </div>
                                        <div className="alert alert-success mt-3 d-none">

                                        </div>
                                        <input type="submit" value="Удалить" className="btn btn-danger mt-3" />
                                        <button onClick={(event) => setDeleteCategory(false)} className="btn btn-primary mt-3 ml-2">Отмена</button>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                        : false
                }
            </TemplateAdminPanel>
        </div>
    );
};

export default AdminCategoryPage;
