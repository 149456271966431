import { useLocation } from 'react-router-dom'

const wordByIndex = (str, index) => {
    return str.split(" ")[index - 1];
}
export default wordByIndex;

export const isNumeric = (num) => {
    return !isNaN(num)
}

export const slicetext = (text, lenght) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(text, 'text/html');
    text = doc.body.innerText
    return text.substring(0, lenght) + "...";
}

export const HeaderView = () => {
    const location = useLocation();
    return location.pathname;
}

export const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, 'text/html');

    return html.body.innerHTML;
}