import React from 'react';
import {Link} from "react-router-dom";
import GlobalParam from "../../../Utility/AppGlobalParam";
import {slicetext} from "../../../Utility/Utility";
import CategoryTag from "../../AdsViewPage/CategoryTag";

const TableAdsRequireAcceptItem = (props) => {
    let img_name = "";

    if (props.Ads['images'])
        img_name = props.Ads['images'].split(".");

    let name = ""

    if (props.Ads['typeAds'] === "Car")
        name = props.Ads['avtoMarka']['name'] + " " + props.Ads['avtoModel'];
    else
        name = props.Ads['name'];

    return (
        <tr>
            <td className="align-middle">
                {props.index}
            </td>
            <td className="align-middle">
                <Link to={GlobalParam.urlViewAds + props.Ads['id']}>
                    {
                        (props.Ads['images'])
                            ? <img src={GlobalParam.AdsImagePatch + props.Ads['id'] + "/" + img_name[0] + "_100x100." + img_name[1]} alt="" />
                            : <img alt="" src={GlobalParam.notImgAdsImage} width="100"/>
                    }
                </Link>
            </td>
            <td className="align-middle">
                <Link to={GlobalParam.urlViewAds + props.Ads['id']}>
                    <h5>{name}</h5>
                </Link>
                <div>
                    {
                        slicetext(props.Ads['description'], 20)
                    }
                </div>
            </td>
            <td className="align-middle">
                {
                    (props.Ads['typeAds'] !== "Service")
                        ?<div>{props.Ads['price']} руб.</div>
                        : <div>
                            Прайс лист
                        </div>
                }
            </td>
            <td className="align-middle">
                {props.Ads['views']}
            </td>
            <td className="align-middle">
                {
                    Object.keys(props.Ads['categories']).map((keyName, i) => {
                        return <CategoryTag key={keyName} title={props.Ads['categories'][keyName]['name']} id={props.Ads['categories'][keyName]['id']} />
                    })
                }
            </td>
            <td className="align-middle">
                {props.Ads['date_add']}
            </td>
            <td className="align-middle">
                <Link to={GlobalParam.urlUserAds + props.Ads['user_add_id']}>
                    {props.Ads['avtorData']['fio']}
                </Link>
            </td>
            {
                (props.Ads['organizationData'])
                    ? <td className="align-middle">
                        {
                            props.Ads['organizationData']['name']
                        }
                    </td>
                    : false
            }

            <td className="align-middle">

                <span className="dropdown-toggle btn btn-primary" role="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                    <span className="p-1">Действия</span>
                </span>

                <ul className="dropdown-menu p-2">
                    <li>
                        <button onClick={(event) => props.adsSuccessAccept(1, props.Ads['id'])} className="btn btn-sm btn-success mt-1"><i className="bi bi-hand-thumbs-up-fill"> </i>Принять</button>
                    </li>
                    <li>
                        <button onClick={(event) => props.adsSuccessAccept(2, props.Ads['id'])} className="btn btn-sm btn-danger mt-1"><i className="bi bi-hand-thumbs-down-fill"> </i>Не принимать</button>
                    </li>
                </ul>
            </td>
        </tr>
    );
};

export default TableAdsRequireAcceptItem;
