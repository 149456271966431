import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import $ from "jquery";
import apiRequest from "../../Utility/ApiRequest";
import {Link} from "react-router-dom";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import AdsListTable from "../../components/MyAdsPage/AdsListTable";

const AdsPage = (props) => {

    const [allAds, setAllAds] = useState(false);
    const [viewAds, setWiewAds] = useState(false);
    const [loadAds, setLoadAds] = useState(false);
    const [activeTabs, setActivTabs] = useState("activAds");

    useEffect(() => {
        setLoadAds(false);
        apiRequest("getMyAds?", "type=" + activeTabs).then((data) => {
            if (!data['error'] && data['success']){
                setAllAds(data['ads']);
                setWiewAds(data['ads']);
                console.log(data['ads']);
            } else {
                $("#ErrorMessage").empty();
                $("#ErrorMessage").append(data['error']);
                $("#ErrorMessage").removeClass("d-none");

                setAllAds(false);
                setWiewAds(false);
            }
            setLoadAds(true);
        });
    }, [activeTabs]);

    const setActiveTabAndLoadAds = (event) => {
        $(".nav-link").removeClass("active");
        $("#" + event.target.id).addClass("active");
        if(!$("#ErrorMessage").hasClass("d-none"))
            $("#ErrorMessage").addClass("d-none")
        setActivTabs(event.target.id);
        setAllAds(false);
        setWiewAds(false);
    }
    const delAds = (ads_id) => {
        Object.keys(allAds).map((keyName, i) => {
           if (parseInt(allAds[keyName]['id']) === parseInt(ads_id)){
               delete allAds[keyName];
               return true;
           }
           return false;
        });
        setAllAds(allAds);

        Object.keys(viewAds).map((keyName, i) => {
            if (parseInt(viewAds[keyName]['id']) === parseInt(ads_id)){
                delete viewAds[keyName];
                return true;
            }
            return false;
        });
        setWiewAds(viewAds);
    }
    const filterAdsCategory = (val) => {
        if (val !== ""){
            let result = []
            allAds.map((ads) => {
                let issetCat = false
                ads['categories'].map((category) => {
                    const cat_name = category['name'].toLowerCase();
                    if(cat_name.includes(val.toLowerCase()))
                        issetCat = true;
                    return true;
                });
                if (issetCat)
                    result.push(ads);
                return true;
            });

            if(!result.length)
                allAds.map((ads) => {
                    let typeAds = ads['typeAds'];
                    let name = "";
                    if (typeAds === "Car")
                        name = ads['avtoMarka']['name'].toLowerCase() + " " + ads['avtoModel'].toLowerCase();
                    else
                        name = ads['name'].toLowerCase();

                    if(name.includes(val.toLowerCase()))
                        result.push(ads);
                    return true;
                });

            setWiewAds(result);
        } else
            setWiewAds(allAds);
    }

    return (
        <div>
            <PageHeader title="Мои обьявления" />
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <Link id="activAds" onClick={(event) => setActiveTabAndLoadAds(event)} className="nav-link active">Активные обьявления</Link>
                </li>
                <li className="nav-item">
                    <Link id="NoActivAds" onClick={(event) => setActiveTabAndLoadAds(event)} className="nav-link">Не активные обьявления</Link>
                </li>

                {
                    (props.appData['userData']['is_organization'] === 1)
                        ? <li className="nav-item">
                            <Link id="OrganizationAds" onClick={(event) => setActiveTabAndLoadAds(event)} className="nav-link">Обьявления организации</Link>
                        </li>
                        : false
                }

            </ul>
            <div className="p-3">
                <div id="ErrorMessage" className="alert alert-danger d-none"></div>
                {
                    (allAds)
                        ? <div>
                            <div className="input-group mt-2">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className="bi bi-search"></i>
                                </span>
                                <input onChange={(event) => filterAdsCategory(event.target.value)} className="form-control" placeholder="Введите парметры поиска. Например: Авто" defaultValue="" />
                            </div>
                            <AdsListTable dataForm={props.appData} delAds={delAds} Ads={viewAds} />
                        </div>
                        : (!loadAds)
                            ? <LoadingIcon />
                            : false
                }
            </div>
        </div>
    );
};

export default AdsPage;
