import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import TemplateAdminPanel from "../../components/AdminPanel/TemplatePage/TemplateAdminPanel";
import apiRequest from "../../Utility/ApiRequest";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import TableAdsRequireAccept from "../../components/AdminPanel/AdsPage/TableAdsRequireAccept";
import { toast } from 'react-toastify';

const AdminAdsPage = (props) => {

    const [allAds, setAllAds] = useState(false);
    const [viewAds, setWievAds] = useState(false);
    const [errorLoadAds, setErrorLoadAds] = useState(false);

    useEffect(() => {
        apiRequest("getAllAdsWhereRequireAccept?", "").then((data) => {
            if(data['success']){
                setAllAds(data['ads']);
                setWievAds(data['ads']);
            } else if (data['error'])
                setErrorLoadAds(data['error']);
        });
    }, [[]]);

    const adsSuccessAccept = (status, ads_id) => {
        apiRequest("moderAds?", "ads_id=" + ads_id + "&status=" + status).then((data) => {
            if (data['success']) {
                Object.keys(allAds).map((keyName, i) => {
                    if (parseInt(allAds[keyName]['id']) === parseInt(ads_id)){
                        delete allAds[keyName];
                        return true;
                    }
                    return false;
                });
                setAllAds(allAds);

                Object.keys(viewAds).map((keyName, i) => {
                    if (parseInt(viewAds[keyName]['id']) === parseInt(ads_id)){
                        delete viewAds[keyName];
                        console.log("Обьявление найдено.");
                        return true;
                    }
                    return false;
                });
                setWievAds(viewAds);

                toast.success(data['success']);

            } else if (data['error'])
                toast.error(data['error']);
        });
    };

    return (
        <div>
            <PageHeader title="Административная панель" />
            <TemplateAdminPanel appData={props.appData} id_activ={2}>
                <PageHeader title="Модерация объявлений" noHr={true} />
                {
                    (viewAds)
                        ? <TableAdsRequireAccept adsSuccessAccept={adsSuccessAccept} Ads={viewAds} />
                        : (errorLoadAds)
                            ? <div className="alert alert-danger">{errorLoadAds}</div>
                            : <LoadingIcon />
                }
            </TemplateAdminPanel>
        </div>
    );
};

export default AdminAdsPage;
