import React from 'react';

const ImgAds = (props) => {
    const delete_server = (props.delete_server) ? true : false;
    const ads_id = (props.ads_id) ? props.ads_id : false;

    return (
        <div className="imgAds d-flex flex-row mr-2 mt-2" id_img={props.id_img}>
            <img src={props.src} id={"ads_image_id_" + props.id_img} title="" alt="" width="100" height="100" className="mr-2 rounded d-inline"  />
            <div className="d-flex flex-column">
                <h5>
                <i onClick={(event) => props.deleteImage(props.id_img, delete_server, ads_id)} style={{cursor: "pointer"}} className="bi bi-trash-fill"></i>
                </h5>
                <h5>
                    {
                        props.rotate_img
                            ? <i onClick={(event) => props.rotateImage(props.id_img)} style={{cursor: "pointer"}} className="bi bi-arrow-clockwise"></i>
                            : false
                    }
                </h5>

                </div>
        </div>
    );
};

export default ImgAds;
