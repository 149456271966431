import React, {useEffect, useState} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import TemplateAdminPanel from "../../components/AdminPanel/TemplatePage/TemplateAdminPanel";
import SendForm from "../../Utility/SendForm";
import {toast} from "react-toastify";
import $ from "jquery";
import apiRequest from "../../Utility/ApiRequest";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";

const AdminPremiumSearch = (props) => {

    const [groupVk, setGroupVk] = useState(false);
    const [notGroupMessage, setNotGroupMessage] = useState("");

    useEffect(() => {
        if (props.appData['settings']['enable_premium_search'] === 1)
            $("#premium_search_work").prop("checked", true);

        if (props.appData['settings']['countDaySaveAds'] !== "")
            $("#countDaySaveAds").val(props.appData['settings']['countDaySaveAds']);

        if (props.appData['settings']['bot_avito_work'] === 1)
            $("#bot_avito_work").prop("checked", true);
        if (props.appData['settings']['bot_drom_work'] === 1)
            $("#bot_drom_work").prop("checked", true);
        if (props.appData['settings']['bot_autoru_work'] === 1)
            $("#bot_autoru_work").prop("checked", true);
        if (props.appData['settings']['bot_yula_work'] === 1)
            $("#bot_yula_work").prop("checked", true);
        // if (props.appData['settings']['bot_carru_work'] === 1)
        //     $("#bot_carru_work").prop("checked", true);
        // if (props.appData['settings']['bot_carprice_work'] === 1)
        //     $("#bot_carprice_work").prop("checked", true);
        if (props.appData['settings']['bot_vk_work'] === 1)
            $("#bot_vk_work").prop("checked", true);

        apiRequest('getGroupVk?', '').then((data) => {
           if (data['success']) {
               setNotGroupMessage("");
               setGroupVk(data['groups']);
           } else {
               setGroupVk(false);
               setNotGroupMessage(data['error'])
           }
        });

    }, [[]]);

    const changeGroupVKStatus = (id_group, active) => {
        apiRequest('changeActiveGroupVK?' , 'id_group=' + id_group + "&active=" + active).then((data) => {
            if (data['success']) {
                setGroupVk(data['groups']);
                toast.success(data['success']);
            } else {
                setGroupVk(false);
                toast.error(data['error']);
            }
        })
    }

    const deleteGroupVKStatus = (id_group) => {
        apiRequest('deleteGroupVK?' , 'id_group=' + id_group).then((data) => {
            if (data['success']) {
                setGroupVk(data['groups']);
                toast.success(data['success']);
            } else {
                setGroupVk(false);
                toast.error(data['error']);
            }
        })
    }

    return (
        <div>
            <PageHeader title="Административная панель" />
            <TemplateAdminPanel appData={props.appData} id_activ={8}>
                <PageHeader title="Настрйоки премиум поиска" noHr={true} />
                <div className="mt-3">
                    <form action="saveSettingsPremiumSearch" id="saveSettingsPremiumSearchForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, (data) => {
                        if(data['success']){
                            toast.success(data['success']);
                            props.reloadSettings(data['settings']);
                        } else if (data['error'])
                            toast.error(data['error']);
                    }, false)}>
                        <div className="mt-3">
                            <h6>Основные настройки</h6>
                            <div className="form-check form-switch">
                                <input name="premium_search_work" id="premium_search_work" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="">Разрешить подписки на премиум поиск</label>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="phone_gruzoperevozka" className="form-label">
                                    <h6>
                                        Количество дней хранения обьявлений в базе данных
                                    </h6>
                                </label>
                                <input name="countDaySaveAds" id="countDaySaveAds" type="number" className="form-control" placeholder="10" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <hr />
                            <h6>Настройка ботов</h6>
                            <div className="form-check form-switch">
                                <input name="bot_avito_work" id="bot_avito_work" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="">Разрешить работать боту на <b>avito.ru</b></label>
                            </div>
                            <div className="form-check form-switch">
                                <input name="bot_drom_work" id="bot_drom_work" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="">Разрешить работать боту на <b>drom.ru</b></label>
                            </div>
                            <div className="form-check form-switch">
                                <input name="bot_autoru_work" id="bot_autoru_work" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="">Разрешить работать боту на <b>auto.ru</b></label>
                            </div>
                            <div className="form-check form-switch">
                                <input name="bot_yula_work" id="bot_yula_work" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="">Разрешить работать боту на <b>youla.ru</b></label>
                            </div>
                            <div className="form-check form-switch">
                                <input name="bot_vk_work" id="bot_vk_work" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="">Разрешить работать боту на <b>vk.com</b></label>
                            </div>
                            {/*<div className="form-check form-switch">*/}
                            {/*    <input name="bot_carru_work" id="bot_carru_work" className="form-check-input" type="checkbox" />*/}
                            {/*    <label className="form-check-label" htmlFor="">Разрешить работать боту на <b>car.ru</b></label>*/}
                            {/*</div>*/}
                            {/*<div className="form-check form-switch">*/}
                            {/*    <input name="bot_carprice_work" id="bot_carprice_work" className="form-check-input" type="checkbox" />*/}
                            {/*    <label className="form-check-label" htmlFor="">Разрешить работать боту на <b>carprice.ru</b></label>*/}
                            {/*</div>*/}
                        </div>

                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <div className="alert alert-success mt-2 d-none">

                        </div>
                        <button className="btn btn-success mt-2" type="submit">Сохранить настройки</button>
                    </form>

                    <hr />

                    <h6>Группы Вконтакте</h6>

                    <div>

                        <form action="addGroupVK" id="addGroupVKForm" method="GET" noValidate onSubmit={(event) => SendForm(event, (data) => {
                            if (data['success']) {
                                setNotGroupMessage("");
                                setGroupVk(data['groups']);
                            } else {
                                setGroupVk(false);
                                setNotGroupMessage(data['error'])
                            }
                        }, true, true)}>

                            <div>
                                <label className="form-label">Url</label>
                                <input name="url_group" placeholder="Введите url (адрес страницы) группы, сообщестра или пользователя Вконтакте" type="text" className="form-control" />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>

                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-2 d-none">

                            </div>
                            <div className="mt-3">
                                <input className="btn btn-success w-100" type="submit" value="Добавить группу" />
                            </div>
                        </form>
                    </div>

                    <div className="mt-3">
                        {
                            !groupVk && notGroupMessage === ""
                                ? <LoadingIcon />
                                : groupVk
                                    ? <div>
                                        <table className="table table-striped table-hover mt-3">
                                            <thead>
                                            <tr>
                                                <th className="text-center" scope="col">Активность</th>
                                                <th className="text-center" scope="col">url</th>
                                                <th className="text-center" scope="col">Действия</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                Object.keys(groupVk).map((keyName, i) => {
                                                    return <tr key={i}>
                                                        <td className="text-center">
                                                            {
                                                                groupVk[keyName]['active']
                                                                    ? <i className="bi bi-bookmark-check-fill text-success"></i>
                                                                    : <i className="bi bi-bookmark-x-fill text-danger"></i>
                                                            }
                                                        </td>
                                                        <td><a href={groupVk[keyName]['url']} target="Blank">{groupVk[keyName]['url']}</a></td>
                                                        <td className="text-center">
                                                            <button className="dropdown-toggle btn btn-primary" role="button" data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                <span className="p-1">Действия</span>
                                                            </button>

                                                            <ul className="dropdown-menu p-2">
                                                                <li>
                                                                    {
                                                                        groupVk[keyName]['active']
                                                                            ? <button onClick={(event) => changeGroupVKStatus(groupVk[keyName]['id'] ,1)} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-bookmark-x-fill"></i> Сделать неактивным</button>
                                                                            : <button onClick={(event) => changeGroupVKStatus(groupVk[keyName]['id'] ,2)} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-bookmark-check-fill"></i> Сделать активным</button>
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <button onClick={(event) => deleteGroupVKStatus(groupVk[keyName]['id'])} className="btn btn-sm btn-light ml-1 mt-1"><i className="bi bi-trash3-fill"></i> Удалить</button>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    : <div className="alert alert-danger">{notGroupMessage}</div>
                        }
                    </div>
                </div>
            </TemplateAdminPanel>
        </div>
    );
};

export default AdminPremiumSearch;
