import React, {useEffect} from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import TemplateAdminPanel from "../../components/AdminPanel/TemplatePage/TemplateAdminPanel";
import SendForm from "../../Utility/SendForm";
import {toast} from "react-toastify";
import $ from 'jquery';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {fontSize} from "suneditor/src/plugins"; // Import Sun Editor's CSS File

const AdminSettingsPage = (props) => {
    const about_text_main = props.appData['settings']['about_text'];

    const setTextareaValue = (content) => {
        $("#about_text").val(content);
    }

    useEffect(() => {
        if (props.appData['settings']['app_work'] === 1)
            $("#app_work").prop("checked", true);
        if (props.appData['settings']['registr_work'] === 1)
            $("#registr_work").prop("checked", true);
        if (props.appData['settings']['add_ads_work'] === 1)
            $("#add_ads_work").prop("checked", true);
        if (props.appData['settings']['bannerVikupShow'] === 1)
            $("#bannerVikupShow").prop("checked", true);
        if (props.appData['settings']['popular_ads_work'] === 1)
            $("#popular_ads_work").prop("checked", true);
        if (props.appData['settings']['promocode_work'] === 1)
            $("#promocode_work").prop("checked", true);

        if (props.appData['settings']['sendNotification'] === 1)
            $("#sendNotification").prop("checked", true);
        if (props.appData['settings']['notification_new_ads'] === 1)
            $("#notification_new_ads").prop("checked", true);
        if (props.appData['settings']['notification_new_user'] === 1)
            $("#notification_new_user").prop("checked", true);
        if (props.appData['settings']['notification_new_aktia'] === 1)
            $("#notification_new_aktia").prop("checked", true);

        if (props.appData['settings']['UserMessage'] !== "")
            $("#UserMessage").val(props.appData['settings']['UserMessage']);
        if (props.appData['settings']['phone_gruzoperevozka'] !== "")
            $("#phone_gruzoperevozka").val(props.appData['settings']['phone_gruzoperevozka']);
        if (props.appData['settings']['phone_evakuator'] !== "")
            $("#phone_evakuator").val(props.appData['settings']['phone_evakuator']);
        if (props.appData['settings']['number_phone_aboutpage'] !== "")
            $("#number_phone_aboutpage").val(props.appData['settings']['number_phone_aboutpage']);
        if (props.appData['settings']['number_phone_baner_vikup_avto'] !== "")
            $("#number_phone_baner_vikup_avto").val(props.appData['settings']['number_phone_baner_vikup_avto']);
        if (props.appData['settings']['email_organization'] !== "")
            $("#email_organization").val(props.appData['settings']['email_organization']);
    }, [[]]);

    return (
        <div>
            <PageHeader title="Административная панель" />
            <TemplateAdminPanel appData={props.appData} id_activ={6}>
                <PageHeader title="Настройки сайта" noHr={true} />
                <div className="mt-3">
                    <h6>Основные настройки</h6>
                    <form action="saveSettingsApp" id="seveSettingsAppForm" method="GET" className="needs-validation" noValidate onSubmit={(event) => SendForm(event, (data) => {
                        if(data['success']){
                            toast.success(data['success']);
                            props.reloadSettings(data['settings']);
                        } else if (data['error'])
                            toast.error(data['error']);
                    }, false)}>
                        <div className="form-check form-switch">
                            <input name="app_work" id="app_work" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Сайт работает</label>
                        </div>
                        <div className="form-check form-switch">
                            <input name="registr_work" id="registr_work" className="form-check-input" type="checkbox" />
                            <label className="form-check-label" htmlFor="">Разрешить регистрацию</label>
                        </div>
                        <div className="form-check form-switch">
                            <input name="add_ads_work" id="add_ads_work" className="form-check-input" type="checkbox" />
                            <label className="form-check-label" htmlFor="">Разрешить публикация обьявлений</label>
                        </div>
                        <div className="form-check form-switch">
                            <input name="bannerVikupShow" id="bannerVikupShow" className="form-check-input" type="checkbox" />
                            <label className="form-check-label" htmlFor="">Показ банера выкуп машин</label>
                        </div>
                        <div className="form-check form-switch">
                            <input name="popular_ads_work" id="popular_ads_work" className="form-check-input" type="checkbox" />
                            <label className="form-check-label" htmlFor="">Показ блока популярные обьявления</label>
                        </div>
                        <div className="form-check form-switch">
                            <input name="promocode_work" id="promocode_work" className="form-check-input" type="checkbox" />
                            <label className="form-check-label" htmlFor="">Использование промокодов</label>
                        </div>
                        <div className="mt-3">
                            <h6>Настройки уведомлений</h6>
                            <div className="form-check form-switch">
                                <input name="sendNotification" id="sendNotification" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Разрешено отправлять уведомления в телеграмм</label>
                            </div>
                            <div className="form-check form-switch">
                                <input name="notification_new_ads" id="notification_new_ads" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="">Уведомлять о новых обьявлениях</label>
                            </div>
                            <div className="form-check form-switch">
                                <input name="notification_new_user" id="notification_new_user" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="">Уведомлять о новых пользователях</label>
                            </div>
                            <div className="form-check form-switch">
                                <input name="notification_new_aktia" id="notification_new_aktia" className="form-check-input" type="checkbox" />
                                <label className="form-check-label" htmlFor="">Уведомлять о новых акциях</label>
                            </div>
                        </div>
                        <hr/>
                        <div className="form-group mt-3">
                            <h6>Информация для пользователей и посетителей сайта</h6>
                            <textarea name="UserMessage" id="UserMessage" className="form-control" rows="6">

                            </textarea>
                        </div>
                        <div className="form-group mt-3">
                            <h6>Основная информация в разделе О нас</h6>
                            <SunEditor setDefaultStyle="font-size: 18px;" setContents={about_text_main} height="300" onChange={setTextareaValue} />

                            <textarea name="about_text" id="about_text" className="form-control d-none" rows="6">

                            </textarea>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="phone_gruzoperevozka" className="form-label">
                                <h6>
                                    Номер телефона в разделе <b>Грузоперевозки</b>
                                </h6>
                            </label>
                            <input name="phone_gruzoperevozka" id="phone_gruzoperevozka" type="text" className="form-control" placeholder="Введите номер телефона: +7 918 1234567" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="phone_gruzoperevozka" className="form-label">
                                <h6>
                                    Номер телефона в разделе <b>Услуги эвакуатора</b>
                                </h6>
                            </label>
                            <input name="phone_evakuator" id="phone_evakuator" type="text" className="form-control" placeholder="Введите номер телефона: +7 918 1234567" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>

                        <div className="form-group mt-3">
                            <label htmlFor="phone_gruzoperevozka" className="form-label">
                                <h6>
                                    Номер телефона в разделе <b>О нас</b>
                                </h6>
                            </label>
                            <input name="number_phone_aboutpage" id="number_phone_aboutpage" type="text" className="form-control" placeholder="Введите номер телефона: +7 918 1234567" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="phone_gruzoperevozka" className="form-label">
                                <h6>
                                    Номер телефона в банере <b>Выкуп авто</b>
                                </h6>
                            </label>
                            <input name="number_phone_baner_vikup_avto" id="number_phone_baner_vikup_avto" type="text" className="form-control" placeholder="Введите номер телефона: +7 918 1234567" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="phone_gruzoperevozka" className="form-label">
                                <h6>
                                    Адрес электронной почты в разделе <b>О Нас</b>
                                </h6>
                            </label>
                            <input name="email_organization" id="email_organization" type="text" className="form-control" placeholder="Введите адрес электронной почты" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>

                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <div className="alert alert-success mt-2 d-none">

                        </div>
                        <button className="btn btn-success mt-2" type="submit">Сохранить настройки</button>
                    </form>
                </div>
            </TemplateAdminPanel>
        </div>
    );
};

export default AdminSettingsPage;
